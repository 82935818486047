import Layout from "../../components/layouts/Layout";
import PageTitle from "../../components/pageTitle/PageTitle";
import Input from "../../components/input/Input";
import {useEffect, useState} from "react";
import {user} from "../../services/api";
import {Modal, Radio} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {autoHypen, checkEmail, timeFormat} from "../../utils";
import {CheckCircleFilled} from "@ant-design/icons";
import {store} from "../../stores";
import {observer} from "mobx-react";

const FindPassword = observer(() => {
    const [userName, setUserName] = useState("");
    const [userId, setUserId] = useState("");
    const [userPhone, setPhone] = useState("")
    const [checkValue, setCheckValue] = useState("email");
    const [authCode, setAuthCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [notEnter, setNotEnter] = useState("");
    const [trueAuth, setTrueAuth] = useState(false);
    const [authSetting, setAuthSetting] = useState(false);
    const [checkSetting, setCheckSetting] = useState(false);
    const [authBtnSetting, setAuthBtnSetting] = useState(false);
    const [btnSetting, setBtnSetting] = useState("이메일 인증");
    const [timer, setTimer] = useState(299);
    const [start, setStart] = useState(false);
    const [timerMessage, setMessage] = useState("");
    const expireAt = store.timeStore.getTime(); // 만료 시간
    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() => {
        if (!state) return;
        setCheckValue("password");
    }, [state])

    useEffect(() => {
        if (!start) return;
        if (timer > 0) {
            const counter = setInterval(() => {
                const gap = Math.floor((new Date(expireAt).getTime() - new Date().getTime()) / 1000)
                setTimer(gap);
            }, 1000)
            return () => clearInterval(counter)
        } else {
            setStart(false);
            setMessage("인증번호가 만료되었습니다.")
        }
    }, [expireAt, timer, start])

    useEffect(() => {
        setNotEnter("");
    }, [checkValue])

    const CheckAuthButton = async () => {
        setLoading(true);
        const tempData = {
            userId,
            userName,
            userPhone: userPhone.replace(/-/g, ''),
            authCode,
        }
        await user.checkAuthCode(tempData)
            .then((res)=>{
                setLoading(false);
                if(res && res.commonRespCode === "1") {
                    setTrueAuth(true);
                    setStart(false);
                    setCheckSetting(true);
                    setAuthSetting(false);
                    Modal.info({
                        title: '알림',
                        content: res.message,
                    });
                }else{
                    Modal.error({
                        title: '알림',
                        content: res.message,
                    });
                }
            })
    }

    const onSend = () => {
        if (checkValue === "password") {
            if(userId === "") {
                setNotEnter("이메일");
                setAuthSetting(false);
                Modal.warning({
                    title: '알림',
                    content: '이메일 주소를 입력해주세요.',
                });
                return;
            }
            if (!checkEmail(userId)) {
                setNotEnter("이메일");
                setAuthSetting(false);
                Modal.warning({
                    title: '알림',
                    content: '올바른 이메일 주소가 아닙니다.',
                });
                return;
            }
            if(!trueAuth) {
                setNotEnter("인증번호");
                Modal.warning({
                    title: '알림',
                    content: '이메일 인증을 받고 다시 시도해 주세요.',
                });
                return;
            }
            if(authCode === "") {
                setNotEnter("인증번호");
                Modal.warning({
                    title: '알림',
                    content: '인증번호를 입력해주세요.',
                });
                return;
            }
        }
        if(userName === "") {
            setNotEnter("이름");
            Modal.warning({
                title: '알림',
                content: '사용자 이름을 입력해주세요.',
            });
            return;
        }
        if(userPhone === "") {
            setNotEnter("휴대폰번호");
            Modal.warning({
                title: '알림',
                content: '휴대폰 번호를 입력해주세요.',
            });
            return;
        }

        if (checkValue === "email") {
            onSendEmail();
        } else {
            onSendPassword();
        }
    }

    const onSendEmail = async () => {
        const data = {
            userName,
            userPhone: userPhone.replace(/-/g, ''),
        }
        await user.findUserId(data)
            .then((res) => {
                if (res && res.commonRespCode === "1") {
                    let hideEmail = '**'+res.data[0].slice(2);
                    navigate("/find/email", { state : {email: hideEmail} })
                } else {
                    setNotEnter("이메일");
                    Modal.warning({
                        title: '알림',
                        content: '이메일이 존재하지 않습니다.',
                    });
                }
            })
    }

    const onSendPassword = async () => {
        navigate("/update/password", { state : {userId: userId} })
    }

    const onSendAuthCode = async () => {
        const data = {
            userId,
            userPhone: userPhone.replace(/-/g, ''),
            userName
        }
        setAuthSetting(true);
        setAuthBtnSetting(false);
        setBtnSetting("재전송");
        setMessage(""); // 타이머 메세지
        setTimer(299);
        setStart(false);
        await user.sendAuthCode(data)
            .then((res) => {
                if (res && res.commonRespCode === "1") {
                    store.timeStore.setTime(new Date(new Date().setMinutes(new Date().getMinutes() + 5)))
                    setAuthBtnSetting(true);
                    setStart(true);
                } else {
                    Modal.error({
                        title: '알림',
                        content: res.message,
                    });
                }
            })
    }

    const onChangeCheckBox = (e) => {
        setCheckValue(e.target.value);
    }

    const AuthButton = () => {
        if(userId === "") {
            setNotEnter("이메일");
            setAuthSetting(false);
            setCheckSetting(false);
            Modal.warning({
                title: '알림',
                content: '이메일 주소를 입력해주세요.',
            });
            return;
        }
        if (!checkEmail(userId)) {
            setNotEnter("이메일");
            setAuthSetting(false);
            setCheckSetting(false);
            Modal.warning({
                title: '알림',
                content: '올바른 이메일 주소가 아닙니다.',
            });
            return;
        }
        onSendAuthCode();
    }

    return (
        <Layout loading={loading}>
            <div className="common-wrapper page-find-password">
                <div className="mobile-wrap">
                    <PageTitle title="이메일 / 비밀번호 찾기"/>
                    <div className="label-form-wrap">
                        <Radio.Group onChange={onChangeCheckBox} value={checkValue} className="form-checkbox">
                            <Radio value={"email"}>이메일 찾기</Radio>
                            <Radio value={"password"}>비밀번호 찾기</Radio>
                        </Radio.Group>
                        { checkValue === "password" &&
                            <>
                                <Input
                                    pageType="J"
                                    id="email"
                                    className={notEnter === "이메일" && "input-full input-red"}
                                    title="E-mail"
                                    value={userId}
                                    placeholder="이메일 주소를 입력하세요."
                                    onChange={e => setUserId(e.target.value)}
                                    authClick={AuthButton}
                                    authName={btnSetting}
                                />
                                {authSetting &&
                                <Input
                                    pageType="J"
                                    id="authCode"
                                    className={notEnter === "인증번호" && "input-full input-red"}
                                    title="인증번호"
                                    value={authCode}
                                    placeholder="이메일로 전송된 인증번호를 입력해주세요."
                                    onChange={e => setAuthCode(e.target.value)}
                                    authCheck={CheckAuthButton}
                                    authBtnToggle={authBtnSetting}
                                    alert={start && timer > 0 ? timeFormat(timer) : timerMessage}
                                />}
                                {checkSetting && <div className="auth-text"><CheckCircleFilled style={{ fontSize: "20px" }}/> 인증이 완료되었습니다.</div>}
                            </>
                        }
                        <Input
                            pageType="J"
                            id="userName"
                            className={notEnter === "이름" && "input-full input-red"}
                            title="사용자 이름"
                            value={userName}
                            placeholder="사용자 이름을 입력하세요."
                            onChange={e => setUserName(e.target.value)}
                        />
                        <Input
                            pageType="J"
                            id="phone"
                            className={notEnter === "휴대폰번호" && "input-full input-red"}
                            title="휴대폰 번호"
                            value={autoHypen(userPhone)}
                            placeholder="휴대폰 번호를 입력하세요."
                            onChange={e => {
                                if(e.target.value.length <= 13) {
                                    setPhone(e.target.value)
                                } else {
                                    setNotEnter("연락처");
                                    Modal.warning({
                                        title: '알림',
                                        content: '11자리가 아닙니다.',
                                    });
                                }
                            }}
                        />
                        <div className="form-btn-wrap" style={{ marginBottom: "180px" }}>
                            <button className="btn btn-orange btn-set-password" onClick={onSend}>이메일 / 비밀번호 찾기</button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
})

export default FindPassword;
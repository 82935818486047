import dayjs from "dayjs";
import {auth, business} from "../services/api";
import {setCode} from "./code";

export const stringSorter = (a, b, key) => {
    if (!a[key]) return 1;
    if (!b[key]) return -1;
    return a[key].localeCompare(b[key]);
};

export const dateSorter = (a, b, key) => {
    let aResult = a[key];
    let bResult = b[key];
    if (a[key] === "-") aResult = 0;
    if (b[key] === "-") bResult = 0;
    return dayjs(aResult).unix() - dayjs(bResult).unix();
};

export const makeEllipsis = (str, num=20) => {
    if(!str) return str;
    let string = "";
    if (str.length > num) {
        string = str.slice(0, num) + "...";
    } else {
        string = str;
    }
    return string;
}

export const makeDday = (a) => {
    const today = new Date();
    const deadline = new Date(a);
    let dif = Math.abs(deadline.getTime() - today.getTime());
    return Math.ceil(dif / (1000 * 60 * 60 * 24));
};

const underTen = (n) => {
    if (n < 10) {
        return `0${n}`;
    }
    return n;
}

export const commonDateFormat = (str, split) => {
    if (!split) split = '.';
    const date = new Date(str);

    return `${date.getFullYear()}${split}${underTen(date.getMonth() + 1)}${split}${underTen(date.getDate())}`
}

export const timeFormat = (time) => {
    const m = Math.floor(time / 60).toString();
    let ss = (time % 60).toString();
    if(ss.length === 1) ss = `0${ss}`;
    return `${m}:${ss}`
}

export const dateRange = (start, end) => {
    const startDt = Number(start.slice(0,10).replaceAll("-", ""));
    const endDt = Number(end.slice(0,10).replaceAll("-", ""));
    return startDt <= endDt;
}

export const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const checkEmail = (str) => {
    return /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i.test(str)
}

export const checkPass = (str) => {
    return /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/.test(str)
}

export const leaveOnlyNumber = (str) => {
    return str.replace(/\D/g,'');
}

export const onlyNumber = (str) => {
    return Number(str.replace(/,/g, ""));
}

export const autoHypen = (str) => {
    if (!str) str = "";
    str = str.replace(/[^0-9]/g, '');
    let tmp = '';
    if (str.length < 4) {
        return str;
    } else if (str.length < 7) {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3);
        return tmp;
    } else if (str.length < 11) {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 3);
        tmp += '-';
        tmp += str.substr(6);
        return tmp;
    } else {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 4);
        tmp += '-';
        tmp += str.substr(7);
        return tmp;
    }
}

export const codeFetch = async () => {
    const code = await auth.fetchCode();
    const codeMap = code.reduce((acc, value) => {
        if (acc) {
            acc[value.codeId] = value.codeName;
        } else {
            acc = {};
            acc[value.codeId] = value.codeName;
        }
        return acc;
    }, {});
    setCode(codeMap);
}

export const companyFetch = async () => {
    const company = await business.searchCompany();
    const companyMap = company.data[0].map((item) => {
        return {
            label: item.companyName,
            value: item.companyName,
        }
    })
    return companyMap;
}
import Cookies from 'js-cookie';
import {store} from "../stores";
import jwtDecode from "jwt-decode";
import dayjs from "dayjs";
import {auth} from "../services/api";
import {Modal} from "antd";

export const setGroupId = (id) => {
    Cookies.set('groupId', id)
}

export const setToken = (token) => {
    Cookies.set('token', token)
}

export const cookieLogout = () => {
    Cookies.remove('token');
    Cookies.remove('groupId');
    store.userStore.setUser({});
}

export const isLogin = async () => {
    try {
        let successLogin = true;
        const token = Cookies.get('token');
        const user = store.userStore.getUser();
        if (token) {
            const decoded = jwtDecode(token);
            if (!user.userId) {
                await auth.getUserData(decoded.userId).then((res) => store.userStore.setUser(res));
                successLogin = true;
            } else if (user && user.userId) {
                if (decoded.exp < dayjs().unix() || user.userId !== decoded.userId) {
                    cookieLogout();
                    Modal.error({
                        title: '알림',
                        content: '토큰이 만료되었습니다. 다시 로그인해주세요.',
                    });
                    successLogin = false;
                }
            }
        }
        return successLogin;
    } catch (e) {
        Modal.error({
            title: '알림',
            content: '오류 발생',
        });
    }
}

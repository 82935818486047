import './adminLayout.scss';
import Input from "../input/Input";
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {cookieLogout} from "../../utils/auth";
import {store} from "../../stores";
import Loading from "../loading/loading";
import {Modal} from "antd";
import {auth} from "../../services/api";
import ModalComponent from "../modal/Modal";
import AdminHeader from "../header/AdminHeader";
import {
    AuditOutlined,
    TeamOutlined,
    SettingOutlined,
    BarChartOutlined,
    StockOutlined,
    InboxOutlined,
    LogoutOutlined,
    RollbackOutlined,
    ArrowLeftOutlined
} from '@ant-design/icons';
import { Menu, ConfigProvider } from 'antd';
const AdminLayout = ({title, children, loading, pop}) => {

    const [selectMenu, setSelectMenu] = useState((localStorage.getItem('selectMenu') !== null && localStorage.getItem('selectMenu').length>0 && localStorage.getItem('selectMenu') !== undefined)?localStorage.getItem('selectMenu'):localStorage.setItem('selectMenu', 'dash'));
    useEffect(()=>{
        localStorage.setItem('selectMenu', selectMenu);
        store.menuStore.setSelectMenu(localStorage.getItem('selectMenu'));
    }, [selectMenu]);
    function getItem(label, key, icon, children, type) {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }
    const items = [
        getItem('대시보드', 'dash', <BarChartOutlined />),
        getItem('회원관리', 'user', <TeamOutlined />, [
            getItem('일반회원관리', 'user_normal'),
            getItem('보험사회원관리', 'user_planner'),
        ]),
        getItem('견적관리', 'estimate', <AuditOutlined />, [
            getItem('입찰관리', 'estimate_estimate'),
            getItem('응찰관리', 'estimate_bidding'),
            getItem('계약관리', 'estimate_contract'),
        ]),
        getItem('통계', 'total', <StockOutlined />, [
            getItem('입찰통계', 'total_estimate')
        ]),
        getItem('상품관리', 'product', <InboxOutlined />, [
            getItem('기업보험상품관리', 'product_insurance'),
            getItem('카카오채널', 'product_kakao')
        ]),
        getItem('일반페이지 이동', 'move', <RollbackOutlined />),
        getItem('로그아웃', 'logout', <LogoutOutlined />)
    ];

// submenu keys of first level
    const rootSubmenuKeys = ['dash', 'user', 'estimate', 'total', 'product', 'logout'];

    const [openKeys, setOpenKeys] = useState([selectMenu]);
    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    const onMenuSelect = (selectObj) => {
        switch (selectObj.key){
            case 'dash':
                navigate("/admin/main");
                break;
            case 'user_normal':
                navigate("/admin/customer");
                break;
            case 'user_planner':
                navigate("/admin/user");
                break;
            case 'estimate_estimate':
                navigate("/admin/bid/manage");
                break;
            case 'estimate_bidding':
                navigate("/admin/bidding/list");
                break;
            case 'estimate_contract':
                navigate("/admin/contract");
                break;
            case 'total_estimate':
                navigate("/admin/bid/statistics");
                break;
            case 'product_insurance':
                navigate("/admin/insurance/products");
                break;
            case 'product_kakao':
                window.open("about:blank").location.href = "https://center-pf.kakao.com";
                break;
            case 'move':
                localStorage.setItem('selectMenu', '');
                store.menuStore.setSelectMenu(localStorage.getItem('selectMenu'));
                navigate("/");
                break;
            case 'logout':
                logout();
                break;
            default :
                break;
        }
        if(selectObj.key !== 'logout') {
            setSelectMenu(selectObj.key);
        }else{
            setSelectMenu("dash");
        }
    }
    //--------
    const navigate = useNavigate();
    const [settingPopup, setSettingPopup] = useState(0);
    const initialData = {
        insuranceScrollSpeed: 0,
        tradeScrollSpeed: 0
    }
    const [setting, setSetting] = useState(initialData);

    const onPopClick = () => {
        navigate(-1);
    }

    const onClose = ()=>{
        setSettingPopup(0);
    }

    const onEditSetting = async ()=>{
        await auth.updateSetting(setting).then((res)=>{
            if(res.commonRespCode === "1"){
                Modal.success({
                    title: "알림",
                    content: "System 설정을 변경하였습니다."
                });
            }else{
                Modal.error({
                    title: "알림",
                    content: "System 설정 변경에 실패하였습니다."
                })
            }
            onClose();
        })
    }
    const logout = () => {
        cookieLogout();
        navigate("/login");
    }
    const selectSetting = async ()=>{
        await auth.selectAdminSetting().then((res)=>{
            if(res.commonRespCode === "1"){
                setSetting(...res.data);
            }

        })
    };
    useEffect(()=>{
        selectSetting();
    }, [])
    return (
        <div className="layout-wrap">
            <Loading loading={loading ? loading : false} />
            <AdminHeader />
            <div className="side-wrap">
                <img src={"/images/logo@3x.png"} className="logo adminLogo" onClick={()=>{
                    navigate("/admin/main");
                    setSelectMenu("dash");
                }} alt="이봐" style={{cursor: "pointer"}}/>
                <ConfigProvider
                    theme={{
                        components: {
                            Menu: {
                                itemSelectedBg: "#fff2e7",
                                itemSelectedColor: "#ff6000"
                            },
                        },
                    }}
                >
                <Menu
                    className={"side-nav-wrap"}
                    mode="inline"
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    onClick={onMenuSelect}
                    style={{
                        width: 160,
                        borderRight: "0px"
                    }}
                    items={items}
                    defaultSelectedKeys={selectMenu}
                />
                </ConfigProvider>
                <div className="live-time">
                    <SettingOutlined
                        className={"settingBtn"}
                        onClick={()=>{
                            setSettingPopup(1);
                        }}
                    />
                </div>
            </div>
            <div className="page-wrap">
                <div className="page-title-wrap">
                {pop &&
                        <ArrowLeftOutlined className={"pop-arrow"} style={{
                            width : "30px",
                            height : "30px"
                        }} onClick={onPopClick}/>
                }
                {title &&
                    <h2 className="page-title">{title}</h2>
                }
                </div>
                {children}
            </div>
            {settingPopup === 1 ?
                <div>
                    <ModalComponent
                        isShow={true}
                        title={"설정"}
                        width={680}
                        className="admin-setting-popup"
                        onClose={onClose}

                        contentComponent={
                            <div className={"admin-setting-popup-wrap"}>
                                <Input
                                    pageType={"N"}
                                    title={"메인 페이지 기업보험상품 슬라이드 속도"}
                                    className={"input-full"}
                                    placeholder="시간(ms)"
                                    value={setting.insuranceScrollSpeed}
                                    min={100}
                                    max={20000}
                                    step="100"
                                    onChange={v => setSetting((prev)=>{
                                        return ({
                                            ...prev,
                                            insuranceScrollSpeed: v,
                                        })
                                    })}
                                >
                                </Input>
                                <Input
                                    pageType={"N"}
                                    title={"메인 페이지 실시간 견적 현황 슬라이드 속도"}
                                    className={"input-full"}
                                    placeholder="시간(ms)"
                                    value={setting.tradeScrollSpeed}
                                    min={100}
                                    max={20000}
                                    step="100"
                                    onChange={v => setSetting((prev)=>{
                                        return ({
                                            ...prev,
                                            tradeScrollSpeed: v,
                                        })
                                    })}
                                >
                                </Input>
                            </div>
                        }
                        footerComponent={
                            <div className="align-center" style={{margin: 20}}>
                                <button className="btn btn-orange" onClick={onEditSetting}>수정</button>
                            </div>
                        }
                    >

                    </ModalComponent>
                </div> : <div></div>
            }
        </div>
    );
};

export default AdminLayout;
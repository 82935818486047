import {makeAutoObservable} from "mobx";

// 로그인시 기본 정보 가지고 페이지 이동할 때 사용
class SelectMenu {
    selectMenu = "";
    constructor() {
        makeAutoObservable(this);
    }
    setSelectMenu = (data) => {
        this.selectMenu = data;
    }
    getSelectMenu = () => {
        return this.selectMenu;
    }
}

const menuStore = new SelectMenu();
export default menuStore;
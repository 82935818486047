import {makeAutoObservable} from "mobx";
class AllowFileList {
    allowFileList = ["jpg", "jpeg", "png", "bmp", "JPG", "JPEG", "PNG", "BMP", "pdf", "PDF", "zip", "ZIP", "egg", "EGG", "alz", "ALZ"];
    constructor() {
        makeAutoObservable(this);
    }
    setAllowFileList = (data) => {
        this.allowFileList = data;
    }
    getAllowFileList = () => {
        return this.allowFileList;
    }
}

const allowFileListStore = new AllowFileList();
export default allowFileListStore;
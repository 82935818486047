import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {store} from "../stores";
import {observer} from "mobx-react";
import {isLogin, setGroupId, setToken} from "../utils/auth";
import {auth, user as userApi} from "../services/api";
import {Modal} from "antd";

const NaverLoginCheck = observer(()=>{
    const navigate = useNavigate();

    useEffect(() => {
        var naver_id_login = new window.naver_id_login(process.env.REACT_APP_NAVER_CLIENT_ID, process.env.REACT_APP_NAVER_CALLBACK_URL);
        if (naver_id_login.oauthParams.access_token) {
            getUserInfo(naver_id_login.oauthParams.access_token);
        } else {
            getAccessToken(naver_id_login);
        }
    }, [])

    const getAccessToken = async (naver_id_login) => {
        try {
            const newData = {
                grantType: 'authorization_code',
                clientId: process.env.REACT_APP_NAVER_CLIENT_ID,
                clientSecret: process.env.REACT_APP_NAVER_CLIENT_SECRET,
                code: naver_id_login.oauthParams.code,
                state: naver_id_login.oauthParams.state
            }
            await userApi.getNaverToken(newData).then((res) => {
                if (res) {
                    if(res.commonRespCode === "1") {
                        getUserInfo(res.data[0].token);
                    } else {
                        Modal.error({
                            title: "알림",
                            content: "로그인 실패, 다시 로그인 해주세요."
                        })
                    }
                } else {
                    Modal.error({
                        title: '알림',
                        content: '오류가 발생하였습니다.',
                    });
                }
            })
        } catch (e) {
            Modal.error({
                title: "알림",
                content: "로그인 실패, 다시 로그인 해주세요."
            })
            navigate("/login")
        }
    }

    const getUserInfo = async (token) => {
        await auth.naverUserInfo(token).then(async (res)=>{
            //0:에러 / 1:성공 / 2:회원가입필요(비밀번호 입력은 불필요)
            switch (res.commonRespCode){
                case "1": //네이버 로그인 성공, 회원등록 완료된 경우
                    store.userStore.setUser(res.data[0]);
                    setToken(res.data[0].token);
                    setGroupId(res.data[0].groupId);
                    navigate("/");
                    break;
                case "2": //네이버 로그인에 성공하였으나 회원등록(연동)을 안한 유저의 경우
                    await isLogin();
                    const user = store.userStore.getUser();
                    if (user?.userId === "" || user?.userId === undefined || user?.userId === null) {
                        navigate("/sns/join", {state: {data: res.data[0], platform: "naver"}});
                    } else {
                        const newData = {
                            userId: user.userId,
                            id: res.data[0].id,
                            platform: res.data[0].platform,
                        }
                        await userApi.AddUserSnsLogin(newData).then((res) => {
                            if (res) {
                                if(res.commonRespCode === "1") {
                                    navigate("/")
                                    Modal.success({
                                        title: '알림',
                                        content: '연동되었습니다.',
                                    });
                                } else {
                                    Modal.error({
                                        title: '알림',
                                        content: res.message,
                                    });
                                }
                            } else {
                                Modal.error({
                                    title: '알림',
                                    content: '오류가 발생하였습니다.',
                                });
                            }
                        })
                    }
                    break;
                default:
                    Modal.error({
                        title: "알림",
                        content: "로그인 실패, 다시 로그인 해주세요."
                    })
                    navigate("/login")
                    break;
            }
        });
    }
    return (
        <>

        </>
    )
})
export default NaverLoginCheck;
import {observer} from "mobx-react";
import AdminLayout from "../../../components/layouts/AdminLayout";
import Input from "../../../components/input/Input";
import React, {useEffect, useRef, useState} from "react";
import {auth} from "../../../services/api";
import {Table, Radio, Select, Modal} from "antd";
import translator from "../../../utils/translator";
import {commonDateFormat, companyFetch, makeEllipsis, stringSorter} from "../../../utils";
import DatePicker, {registerLocale} from "react-datepicker";
import ko from "date-fns/locale/ko";
import 'react-datepicker/dist/react-datepicker.css';
import {useNavigate, useParams} from "react-router-dom";
import {FileInput} from "../../../components/input/FileInput";
import ReadOnlyInput from "../../../components/input/ReadOnlyInput";

registerLocale('ko', ko)
const today = new Date();
const nextDay = new Date(today);
nextDay.setDate(today.getDate() + 1);

const BidManageDetail = observer(() => {
    // const user = store.AdminEstimateStore.getAdminEstimate();
    const initialData = {
        esCode: "",
        esTitle: "",
        esComments: "",
        esAccident: "",
        deadlineDt: "",
        userId: "",
        esName: "",
        esCompany: "",
        esPhone: "",
        businessLicenseAttId: 0,
        policyAttId: 0,
        businessLicenseAttPath: "",
        businessLicenseAttName: "",
        policyAttPath: "",
        policyAttName: "",
        esStatusCode: "",
        insertDt: "",
        updateDt: "",
        esInsuranceTypeCode: "",
        emailSend: false,
    }
    const initialList = {
        items: [],
        limit: 10,
        pageNo: 1,
        totalCount: 0,
    }
    const [data, setData] = useState(initialData);
    const [list, setList] = useState(initialList);
    const [checkValue, setCheckValue] = useState('');
    const [checkUser, setCheckUser] = useState([]);
    const [pureUser, setPure] = useState([])
    const [userList, setUserList] = useState([]);
    const [insuOption, setInsuOption] = useState([]);
    const [checkInsu, setCheckInsu] = useState('');
    const [loading, setLoading] = useState(false);
    const _calendar = useRef(null);
    const navigate = useNavigate();
    const {esCode} = useParams();
    useEffect(() => {
        if (esCode === null || esCode === "") navigate("/admin/bid/manage");
        else fetchData();
    }, [])

    const visibleDatePicker = (bool) => {
        if (_calendar.current) {
            _calendar.current.setOpen(bool);
        }
    }

    const fetchData = async () => {
        const result = await auth.searchAdminEstimateDetail(esCode);
        const result2 = await auth.searchAdminEstimateBids(esCode);
        const result3 = await auth.searchAdminUser(esCode).then((res) => {
            if(res.data[0].preCompanyList.length > 0) {
                if (res.data[0].preCompanyList.length === res.data[0].allCompanyList.length) {
                    setCheckValue("all")
                } else {
                    setCheckUser(res.data[0].preCompanyList);
                    setCheckValue("select");
                }
            } else {
                setCheckValue("none")
            }
            return res.data[0].allCompanyList;
        })
        const companyData = await companyFetch();
        const result4 = await auth.fetchInsuranceMenu().then((res) => {
            const insuranceList = [];
            res.map((item) => insuranceList.push(...item.itemList))
            return insuranceList;
        })
        setData(result);
        setList(result2);
        setPure(result3);
        setUserList(companyData);
        setInsuOption(result4);
        setCheckInsu(result.esInsuranceTypeCode)
    }

    const onEditAndSend = () => {
        if (checkInsu === "" || checkInsu === "미등록") {
            Modal.warning({
                title: '알림',
                content: '보험종목을 선택해주세요.',
            });
            return;
        }
        if (checkValue === "select" && checkUser.length === 0) {
            Modal.warning({
                title: '알림',
                content: '보험사를 선택해주세요.',
            });
            return;
        }
        Modal.confirm({
            title: '확인',
            content: '수정 및 발송 하시겠습니까?',
            onOk: async () => {
                let comList;
                switch (checkValue) {
                    case 'select' : comList = checkUser; break;
                    case 'all' : comList = pureUser; break;
                    default: comList = []; break;
                }
                const tempData = {
                    esCode:data.esCode,
                    userId:data.userId,
                    esInsuranceTypeCode:checkInsu,
                    title:data.esTitle,
                    accident:data.esAccident,
                    deadlineDt:data.deadlineDt,
                    comment:data.esComments,
                    emailSend: checkValue === "none" ? "false" : "true",
                    companyList: comList,
                }
                setLoading(true);
                await auth.updateEstimate(tempData)
                    .then((res) => {
                        setLoading(false);
                        if (res) {
                            if(res.commonRespCode === "success") {
                                Modal.success({
                                    title: '알림',
                                    content: res.message,
                                });
                                navigate("/admin/bid/manage");
                            } else {
                                Modal.error({
                                    title: '알림',
                                    content: res.message,
                                });
                            }
                        } else {
                            Modal.error({
                                title: '알림',
                                content: '오류가 발생하였습니다.',
                            });
                        }
                    })
            }
        });
    }
    
    const onDelete = () => {
        Modal.confirm({
            title: '확인',
            content: '삭제하시겠습니까?',
            onOk: async () => {
                await auth.deleteEstimate(data.esCode)
                    .then((res) => {
                        if (res) {
                            if(res.commonRespCode === "1") {
                                Modal.success({
                                    title: '알림',
                                    content: '삭제되었습니다.',
                                });
                                navigate("/admin/bid/manage");
                            } else {
                                Modal.error({
                                    title: '알림',
                                    content: res.message,
                                });
                            }
                        } else {
                            Modal.error({
                                title: '알림',
                                content: '오류가 발생하였습니다.',
                            });
                        }
                    })
            }
        });
    }

    const onPageChange = (data) => {
        navigate(`/admin/estimate/${data.bidCode}`)
    }

    const onChangeCheckBox = (e) => {
        setCheckValue(e.target.value);
    }

    const onChangeSelectBox = (value) => {
        setCheckUser(value);
    }

    const onChangeSelect = (e) => {
        let value = e;
        if (!e) {
            value = "";
        }
        setCheckInsu(value);
    };

    const header = [
        "bidCode",
        "insertDt",
        "companyName",
        "bidAmount",
        "bidComments",
    ]

    const columns = header.map((key) => {
        if(key === "bidAmount") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
            }
        } else if (key === "bidComments") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                render: (value) => {
                    return makeEllipsis(value);
                }
            }
        }
        else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
            }
        }
    })

    return (
        <AdminLayout title={`${translator["bid"]} 수정`} loading={loading} pop>
            <div className="admin-common-form-wrap">
                <ReadOnlyInput title="고객 ID" value={data.userId} />
                <ReadOnlyInput title="고객명" value={data.esName} />
                <ReadOnlyInput title="연락처" value={data.esPhone} />
                <Input
                    pageType="R"
                    title="제목"
                    placeholder="제목"
                    value={data.esTitle}
                    onChange={e => setData((prev) => {
                        return ({
                            ...prev,
                            esTitle: e.target.value,
                        })
                    })}
                />
                <div className={'form-block'}>
                    <span className="block-title">
                        보험종목
                    </span>
                    <div className="block-content">
                        <Select
                            value={checkInsu}
                            onChange={onChangeSelect}
                            options={insuOption}
                            fieldNames={{ label: "codeName", value: "codeId" }}
                            style={{ width: "170px" }}
                            className="insurance-code-select"
                        />
                    </div>
                </div>
                <FileInput
                    title = "사업자등록증"
                    value = {data.businessLicenseAttName}
                    path = {data.businessLicenseAttPath}
                />
                <FileInput
                    title = "갱신증권"
                    value = {data.policyAttName}
                    path = {data.policyAttPath}
                />
                <div className="form-block">
					<span className="block-title">
						3개년 사고사항
					</span>
                    <div className="block-content">
                        <textarea
                            className="textarea"
                            style={{height: 100}}
                            placeholder="3개년 사고사항을 입력해 주세요."
                            value={data.esAccident}
                            onChange={e => {
                                setData((prev) => {
                                    return ({
                                        ...prev,
                                        esAccident: e.target.value,
                                    })
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="form-block">
					<span className="block-title">
						{translator["bid"]} 마감일
					</span>
                    <div className="block-content">
                        <div className="content-other">
                            {data.deadlineDt
                                ? <p className="date-content">{data.deadlineDt}</p>
                                : <p>* 입력된 날짜가 없습니다.</p>
                            }
                            <label style={{position: 'relative'}} onClick={e => e.preventDefault()}>
                                <button className="btn btn-gray" onClick={() => visibleDatePicker(true)}>날짜보기</button>
                                <DatePicker
                                    locale="ko"
                                    ref={_calendar}
                                    minDate={nextDay}
                                    value={data.deadlineDt}
                                    onChange={e => setData((prev) => {
                                        return ({
                                            ...prev,
                                            deadlineDt: commonDateFormat(e, '-'),
                                        })
                                    })}
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <Input
                    pageType="R"
                    title="요청사항"
                    placeholder="요청사항"
                    value={data.esComments}
                    onChange={e => setData((prev) => {
                        return ({
                            ...prev,
                            esComments: e.target.value,
                        })
                    })}
                />
                <div className="form-block">
					<span className="block-title">
                        보험사에 발송할까요?
					</span>
                    <div className="block-content" style={{ padding: '8px 0' }}>
                        <Radio.Group onChange={onChangeCheckBox} value={checkValue}>
                            <Radio value={"none"}>선택 안함</Radio>
                            <Radio value={"all"}>전체</Radio>
                            <Radio value={"select"} className="mt4">보험사 선택</Radio>
                        </Radio.Group>
                        {checkValue === "select" && userList.length > 0 ?
                            <Select
                                className="mw10"
                                mode="multiple"
                                placeholder="보험사 선택"
                                defaultValue={checkUser}
                                onChange={onChangeSelectBox}
                                options={userList}
                                style={{ width: "170px" }}
                            /> : null
                        }
                    </div>
                </div>
            </div>
            <div className="align-center" style={{margin: '20px 0 20px'}}>
                <button className="btn btn-orange" onClick={onEditAndSend}>수정 및 발송</button>
                <button className="btn btn-line-orange" onClick={onDelete}>삭제</button>
            </div>
            {list.items.length > 0 &&
                <Table
                    dataSource={list.items}
                    columns={columns}
                    style={{ cursor: "pointer" }}
                    pagination={false}
                    onRow={(record) => {
                        return {
                            onClick: () => onPageChange(record)
                        };
                    }}
                    className={"adminTable"}
                    // pagination={{
                    //     current: page,
                    //     onChange: (page) => setPageNum(page),
                    //     pageSize: list.limit,
                    //     total: list.totalCount,
                    //     position: ["bottomCenter"],
                    //     showSizeChanger: false,
                    // }}
                />
            }
        </AdminLayout>
    );
})

export default BidManageDetail
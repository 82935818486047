import {Modal, Table, Tag} from "antd";
import {observer} from "mobx-react";
import {store} from "../../stores";
import Layout from "../../components/layouts/Layout";
import PageTitle from "../../components/pageTitle/PageTitle";
import ReadOnlyInput from "../../components/input/ReadOnlyInput";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {auth, estimate} from "../../services/api";
import translator from "../../utils/translator";
import {autoHypen, commonDateFormat, makeEllipsis, stringSorter} from "../../utils";
import EstimateSubscribeModal from "../../components/modal/EstimateSubscribeModal";
import Report from "../../components/report/Report";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {isLogin} from "../../utils/auth";
import {FileInput} from "../../components/input/FileInput";
import DashBoard from "../../components/dashboard/DashBoard";
import BiddingCard from "../../components/biddingCard/BiddingCard";
import {getCode} from "../../utils/code";
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
const EstimateListDetail = observer(() => {
    const code = getCode();
    const initialData = {
        esCode: "",
        esTitle: "",
        esComments: "",
        esAccident: "",
        deadlineDt: "",
        userId: "",
        esName: "",
        esCompany: "",
        esPhone: "",
        businessLicenseAttId: "",
        policyAttId: "",
        businessLicenseAttPath: "",
        policyAttPath: "",
        esStatusCode: "",
        insertDt: "",
        updateDt: "",
        esInsuranceTypeCode: "",
    }
    const [width, setWidth] = useState(window.innerWidth);
    const [user, setUser] = useState();
    const [data, setData] = useState(initialData);
    const [list, setList] = useState([]);
    const [status, setStatus] = useState("");
    const [selectedEstimate, setSelectedEstimate] = useState(null);
    const [openPdf, setOpenPdf] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [notEnter, setNotEnter] = useState("");
    const navigate = useNavigate();
    const [header, setHeader] = useState([]);
    const [detail, setDetail] = useState(true);
    const {esCode} = useParams();

    useEffect(() => {
        checkPageAuth();
        setNotEnter("");
    }, [])

    useEffect(() => {
        let timeoutId;
        const handleResize = () => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                setWidth(window.innerWidth);
            }, 300)
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.clearTimeout(timeoutId);
        };
    }, []);

    const checkPageAuth = async () => {
        const auth = await isLogin();
        if (auth) {
            fetchData();
        } else {
            Modal.error({
                title: '알림',
                content: '권한이 없습니다. 다시 로그인 해주세요.',
            });
            navigate("/login")
        }
    }

    useEffect(()=>{
        if(width < 1024){
            setHeader(["bidCompany", "bidAmount", "bidStatusCode"]);
            setDetail(false);
        }else{
            setDetail(true);
            setHeader([
                "index",
                "esInsertDt",
                "bidCompany",
                "bidAmount",
                "bidComments",
                "bidStatusCode"
            ]);
        }
    }, [width]);

    useEffect(() => {
        if(openPdf) {
            saveAsPdf();
        }
    }, [openPdf])

    const fetchData = async () => {
        await estimate.searchEstimateDetail(esCode).then(res => {
            if(res.esStatusCode === "ES01" || res.esStatusCode === "ES02") {
                setStatus("요청");
            } else if(res.esStatusCode === "ES04" || res.esStatusCode === "ES05" || res.esStatusCode === "ES06" || res.esStatusCode === "ES07") {
                setStatus("계약");
            }
            const userData = store.userStore.getUser();
            setUser(userData);
            setData(res);
        });
        await estimate.searchEstimateBids(esCode).then((res) => {
            const resultData = res.map((item, index) => {
                return {
                    ...item,
                    key: index,
                    index: index + 1,
                    esInsertDt: item.insertDt,
                    bidCompany: item.companyName,
                    bidStatusCode : code[item.bidStatusCode],
                }
            })
            setList(resultData);
        })
        setReadOnly(false);
    }
    
    const onClickUpdate = async () => {
        setReadOnly(true);
    }

    const onUpdateEs = () => {
        if(data.esTitle === "") {
            setNotEnter("제목");
            Modal.warning({
                title: '알림',
                content: '제목을 입력해주세요.',
            });
            return;
        }
        Modal.confirm({
            title: '확인',
            content: '수정하시겠습니까?',
            onOk: async () => {
                const dataTemp = {
                    userId: data.userId,
                    title: data.esTitle,
                    // biz: data.userId,
                    // policy: data.userId,
                    accident: data.esAccident,
                    deadlineDt: data.deadlineDt,
                    comment: data.esComments,
                    esStatusCode: data.esStatusCode,
                    esCode: data.esCode
                }
                await estimate.updateEstimate(dataTemp)
                    .then((res) => {
                        if (res) {
                            if(res.commonRespCode === "1") {
                                Modal.success({
                                    title: '알림',
                                    content: '수정되었습니다.',
                                });
                                fetchData();
                            } else {
                                Modal.error({
                                    title: '알림',
                                    content: res.message,
                                });
                            }
                        } else {
                            Modal.error({
                                title: '알림',
                                content: '오류가 발생하였습니다.',
                            });
                        }
                    })
                setNotEnter("");
            }
        });
    }

    const onDeleteEs = () => {
        Modal.confirm({
            title: '확인',
            content: '삭제하시겠습니까?',
            onOk: async () => {
                await auth.deleteEstimate(data.esCode)
                    .then((res) => {
                        if (res) {
                            if(res.commonRespCode === "1") {
                                Modal.success({
                                    title: '알림',
                                    content: '삭제되었습니다.',
                                });
                                navigate("/estimate/list");
                            } else {
                                Modal.error({
                                    title: '알림',
                                    content: res.message,
                                });
                            }
                        } else {
                            Modal.error({
                                title: '알림',
                                content: '오류가 발생하였습니다.',
                            });
                        }
                    })
            }
        });
    }

    const onPageChange = (data) => {
        setSelectedEstimate(data);
    }

    const onClose = () => {
        setSelectedEstimate(null);
        fetchData();
    }

    const onMoveReport = () => {
        let _width = '800';
        let _height = '1200';
        let _left = Math.ceil(( window.screen.width - _width )/2);
        let _top = Math.ceil(( window.screen.height - _height )/2);

        window.open(`/estimate/${data.esCode}`, '_blank', `width = ${_width}, height = ${_height}, left = ${_left}, top = ${_top}`)
    }

    const saveAsPdf = () => {
        const pdf = document.getElementById('pdf');
        pdf?.classList.add('saving');
        html2canvas(pdf, {scrollY: -window.scrollY}).then(function(canvas) {
            const imgData = canvas.toDataURL('image/png');
            if(imgData.length <= 6){
                saveAsPdf();
                return;
            }
            pdf?.classList.remove('saving');
            const margin = 10;
            const imgWidth = 210 - (margin * 2);
            const pageHeight = 297;
            const imgHeight = canvas.height * imgWidth / canvas.width;
            const doc = new jsPDF('p', 'mm', 'a4');
            let heightLeft = imgHeight;
            let position = margin;

            doc.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            // 한 페이지 이상일 경우 루프 돌면서 출력
            while (heightLeft >= 20) {
                position = heightLeft - imgHeight - margin;
                doc.addPage();
                doc.addImage(imgData, 'PNG', margin, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            doc.save('report.pdf');
            setOpenPdf(false)
        });
    }



    const columns = header.map((key) => {
        if(key === "bidAmount") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
                width: 110,
                render: (value) => {
                    return value.toLocaleString() + " 원";
                }
            }
        } else if (key === "bidComments") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                width: 400,
                render: (value) => {
                    return makeEllipsis(value);
                }
            }
        } else if (key === "bidStatusCode"){
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                width: 110,
                render: (value) => {
                    let color;
                    let bordered = true;
                    if (value === "계약요청") {
                        color = 'green';
                    } else if (value === "계약확인") {
                        color = 'gold';
                    } else if (value === "계약완료") {
                        color = 'volcano';
                    } else if (value === "입찰요청") {
                        color = 'blue';
                    } else if (value === "입찰확인") {
                        color = 'purple';
                    } else if (value === "입찰완료") {
                        color = 'magenta';
                    } else if (value === "견적요청") {
                        color = 'purple';
                    } else if (value === "견적확인") {
                        color = 'red';
                    } else if (value === "미참여") {
                        color = 'geekblue';
                    } else if (value === "입찰종료") {
                        color = 'default';
                        bordered = false;
                    }
                    return (
                        <Tag color={color} key={value} bordered={bordered}>
                            {value==="견적요청"?"가입요청":value}
                        </Tag>
                    );
                }
            }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                width: 100
            }
        }
    })

    return (
        <Layout>
            <div className="common-wrapper page-estimate-detail">
                <PageTitle title="견적요청 상세보기" pop />
                {data?.esStatusCode &&
                    <DashBoard selectData={data.esStatusCode} notNum={true}/>
                }
                {status === "요청" && !readOnly &&
                    <div className="form-btns-wrap">
                        <button className="btn btn-small btn-orange" onClick={onClickUpdate}>수정</button>
                        <button className="btn btn-small btn-line-orange" onClick={onDeleteEs}>삭제</button>
                    </div>
                }
                {status === "요청" && readOnly &&
                    <div className="form-btns-wrap">
                        <button className="btn btn-small btn-line-orange" onClick={onUpdateEs}>완료</button>
                        <button className="btn btn-small btn-orange" onClick={() => setReadOnly(false)}>취소</button>
                    </div>
                }
                <div className="common-form-wrap">
                    <ReadOnlyInput
                        title = "문서 ID"
                        value = {data.esCode}
                    />
                    <ReadOnlyInput
                        title = "견적 신청인 E-mail"
                        value = {user?.userId}
                    />
                    {detail &&
                        <>
                            <ReadOnlyInput
                                title="견적 신청인 회사명"
                                value={user?.companyName}
                            />
                            <ReadOnlyInput
                                title = "견적 신청인 고객명"
                                value = {user?.userName}
                            />
                            <ReadOnlyInput
                                title = "견적 신청인 휴대전화"
                                value = {user?.userPhone && autoHypen(user?.userPhone)}
                            />
                            <ReadOnlyInput
                                title = "제목"
                                value = {data.esTitle}
                                readOnly = {readOnly}
                                onChange = {e => {
                                setData((prev) => {
                                    return ({
                                        ...prev,
                                        esTitle: e.target.value,
                                    })
                                })}}
                                className={notEnter === "제목" && "input-red"}
                            />
                            <FileInput
                                title = "사업자등록증"
                                value = {data.businessLicenseAttName}
                                path = {data.businessLicenseAttPath}
                            />
                            <FileInput
                                title = "갱신증권"
                                value = {data.policyAttName}
                                path = {data.policyAttPath}
                            />
                            <ReadOnlyInput
                                title = "3개년 사고사항"
                                value = {data.esAccident}
                                readOnly = {readOnly}
                                onChange = {e => {
                                setData((prev) => {
                                    return ({
                                    ...prev,
                                    esAccident: e.target.value,
                                    })
                                })}}
                            />
                            <ReadOnlyInput
                                title = {`${translator["bid"]} 마감일`}
                                value = {data.deadlineDt}
                                readOnly = {readOnly}
                                onChangeDate = {e => setData((prev) => {
                                    return ({
                                    ...prev,
                                    deadlineDt: commonDateFormat(e, '-'),
                                })})}
                            />

                            { data.esStatusCode && data.esStatusCode === "ES06" &&
                                <FileInput
                                    title = "계약서"
                                    value = {data.souscriptionAttName}
                                    path = {data.souscriptionAttPath}
                                />
                            }
                            { data.esStatusCode && data.esStatusCode === "ES06" &&
                                <ReadOnlyInput
                                    title = "계약일"
                                    value = {data.updateDt}
                                    readOnly = {readOnly}
                                />
                            }

                            <ReadOnlyInput
                                title = "요청사항"
                                value = {data.esComments}
                                readOnly = {readOnly}
                                onChange = {e => {
                                    setData((prev) => {
                                        return ({
                                            ...prev,
                                            esComments: e.target.value,
                                        })
                                    })}}
                            />
                        </>}
                    {width < 1024 &&
                        <div className="form-btn-wrap">
                            <button className="btn btn-line-none btn-set-detail" onClick={() => setDetail(!detail)}>{detail ? "접기" : "상세보기"}{detail ? <CaretUpOutlined />:<CaretDownOutlined />}</button>
                        </div>}
                    {width > 1024 && <ReadOnlyInput title = "" />}
                </div>
                <div className="estimates-wrap estimate-bidding-table">
                {user?.groupId === "0" &&
                    <div className="table-blur-wrapper">
                        <div>보험사 입찰 상세 결과는 회원가입 후 이용 가능합니다.</div>
                        <button className="btn btn-small btn-line-orange" onClick={() => navigate("/member/join")}>회원가입</button>
                    </div>
                }
                {user?.groupId !== "0" && list.length > 0 &&
                    <>
                        <div className="estimates-btns-wrap">
                            <button className="btn btn-small btn-line-orange" onClick={onMoveReport}>미리보기</button>
                            <button className="btn btn-small btn-orange" onClick={() => setOpenPdf(true)}>PDF 저장</button>
                        </div>
                        <div className="table-wrapper">
                            {width < 1024 ?
                                <BiddingCard data={list} onPageChange={onPageChange} /> :
                                <Table
                                    className="inner-wrapper"
                                    dataSource={list}
                                    columns={columns}
                                    style={{cursor: "pointer"}}
                                    pagination={false}
                                    onRow={(record) => {
                                        if (record.bidStatusCode === "입찰종료") return;
                                        return {
                                            onClick: () => onPageChange(record)
                                        };
                                    }}
                                    rowClassName={record => record.bidStatusCode === "입찰종료" && "disabled-row"}
                                />
                            }
                        </div>
                    </>
                }
                </div>
                {openPdf &&
                    <div id="pdf" className="pdf">
                        <Report code={data.esCode} />
                    </div>
                }
            </div>
            {selectedEstimate &&
                <EstimateSubscribeModal status={status} selectedData={selectedEstimate} onClose={onClose} />
            }
        </Layout>
    );
})

export default EstimateListDetail;
import Layout from "../../components/layouts/Layout";
import PageTitle from "../../components/pageTitle/PageTitle";
import Input from "../../components/input/Input";
import React, { useState } from "react";
import FileSelector from "../../components/fileSelector/FileSelector";
import {useNavigate} from "react-router-dom";
import {ask} from "../../services/api";
import {store} from "../../stores";
import {Modal} from "antd";
import {autoHypen, checkEmail} from "../../utils";

const AskEmail = () => {
    const initialData = {
        title: "",
        email: "",
        phone: "",
        comment: "",
        att: ""
    }
    const allowFileList = store.allowFileListStore.getAllowFileList();
    const [data, setData] = useState(initialData);
    const [loading, setLoading] = useState(false);
    const [notEnter, setNotEnter] = useState("");
    const navigate = useNavigate();

    const uploadFile = (e) => {
        if(!e.target.files[0]) return;
        //이미지 파일만 받기
        let _lastDot = e.target.files[0].name.lastIndexOf('.');
        let _fileExt = e.target.files[0].name.substring(_lastDot+1, e.target.files[0].name.length);
        if(!allowFileList.includes(_fileExt)){
            Modal.warning({
                title: '알림',
                content: '이미지, PDF, ZIP 파일만 업로드 가능합니다.',
            });
            return;
        }
        //이미지 사이즈 확인
        if((e.target.files[0].size/1024/1024).toFixed(2) > 10){
            Modal.warning({
                title: '알림',
                content: (
                    <div>
                        <p>해당 파일은 {(e.target.files[0].size / 1024 / 1024).toFixed(2)}MB 입니다.</p>
                        <p>10MB 미만의 파일만 업로드 가능합니다.</p>
                    </div>
                )
            });
            return;
        }
        const previewUrl = window.URL.createObjectURL(e.target.files[0])
        setData((prev) => {
            return ({
                ...prev,
                att: e.target.files[0],
                previewUrl,
            })
        })
    }

    const mappingField = {
        "title": "제목",
        "email": "이메일",
        "phone": "연락처",
        "comment": "문의내용",
        "att" : "첨부파일"
    }

    const sendEmail = () => {
        let emptyValue = [];
        Object.keys(mappingField).forEach((key) => {
            if (data[key] === "" || data[key] === null || data[key] === undefined ) {
                emptyValue.push(mappingField[key]);
            }
        })
        if (emptyValue.length > 0) {
            setNotEnter(emptyValue[0]);
            Modal.warning({
                title: '알림',
                content: `[${emptyValue[0]}] 값을 입력해주세요.`,
            });
            return;
        }
        if (!checkEmail(data.email)) {
            setNotEnter("이메일");
            Modal.warning({
                title: '알림',
                content: '올바른 이메일 주소가 아닙니다.',
            });
            return;
        }
        Modal.confirm({
            title: '확인',
            content: '이메일로 전송하시겠습니까?',
            onOk: async () => {
                const tempData = {
                    ...data,
                    phone: data.phone.replace(/-/g, ''),
                }
                delete tempData.previewUrl
                setLoading(true);
                await ask.askEmail(tempData)
                    .then((res) => {
                        setLoading(false);
                        if (res) {
                            if(res.commonRespCode === "success") {
                                Modal.success({
                                    title: '알림',
                                    content: res.message,
                                });
                                navigate("/ask/list");
                            } else {
                                Modal.error({
                                    title: '알림',
                                    content: res.message,
                                });
                            }
                        } else {
                            Modal.error({
                                title: '알림',
                                content: '오류가 발생하였습니다.',
                            });
                        }
                    })
            }
        });
    }

    return(
        <Layout loading={loading}>
            <div className="common-wrapper page-ask-email">
                <PageTitle title="E-mail 문의하기" pop/>
                <div className="common-form-wrap">
                    <Input
                        pageType="R"
                        title="제목"
                        className={notEnter === "제목" && "input-full input-red"}
                        placeholder="제목 입력"
                        value={data.title}
                        onChange={e => {
                            setData((prev) => {
                                return ({
                                    ...prev,
                                    title: e.target.value,
                                })
                            })
                        }}
                    />
                    <Input
                        pageType="R"
                        title="e-mail"
                        className={notEnter === "이메일" && "input-full input-red"}
                        placeholder="e-mail 입력"
                        value={data.email}
                        onChange={e => {
                            setData((prev) => {
                                return ({
                                    ...prev,
                                    email: e.target.value,
                                })
                            })
                        }}
                    />
                    <Input
                        pageType="R"
                        id="phone"
                        type="tel"
                        title="연락처"
                        value={autoHypen(data.phone)}
                        placeholder="휴대폰 번호를 입력하세요."
                        onChange={e => {
                            if(e.target.value.length <= 13) {
                                setData((prev) => {
                                    return ({
                                        ...prev,
                                        phone: e.target.value,
                                    })
                                })
                            } else {
                                Modal.warning({
                                    title: '알림',
                                    content: '11자리가 아닙니다.',
                                });
                            }
                        }}
                    />
                    <div className="form-block">
						<span className="block-title">
							문의 내용
						</span>
                        <div className="block-content">
                            <textarea
                                className={`textarea ${notEnter === "문의내용" && "input-red"}`}
                                style={{height: 180}}
                                placeholder="문의내용을 입력해주세요."
                                value={data.comment}
                                onChange={e => {
                                    setData((prev) => {
                                        return ({
                                            ...prev,
                                            comment: e.target.value,
                                        })
                                    })
                                }} />
                        </div>
                    </div>
                    <div className="form-block">
						<span className="block-title">
							첨부파일 업로드
						</span>
                        <div className="block-content">
                            <div className="content-other">
                                {data.att
                                    ? <p className="file-content">
                                        <a href={data.previewUrl} target="_blank" rel="noopener noreferrer">{data.att.name}</a>
                                    </p>
                                    : <p className="normal-content">* 등록된 파일이 없습니다.</p>
                                }
                                <div className="file-upload-wrap">
                                    <span>* 이미지, PDF, ZIP 파일만 업로드 가능합니다.</span>
                                    <FileSelector id="askFile" onChange={e => uploadFile(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="btn btn-orange btn-post" onClick={sendEmail}>문의하기</button>
            </div>
        </Layout>
    )
}

export default AskEmail;
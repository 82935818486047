const translator = {
    es: "견적",
    estimateCount: "입찰건수",
    esInsertDt: "견적일시",
    esDeadlineDt: "견적마감일",
    deadlineDt: "입찰마감일",
    biddingCount: "입찰건수",
    editEstimate: "입찰수정",
    bidUpdateDt: "계약일시",
    bidCompanyName: "보험사명",
    bidUserName: "담당자명",
    estimateCode: "견적번호",
    biddingCode: "입찰번호",
    userInsertDt: "회원가입일",

    bid: "입찰",
    bidAmount: "입찰보험료",
    bidInsertDt: "입찰제시일",
    insertDt: "견적요청일",

    dealCount: "낙찰건수",

    rownum:"NO",
    userId: "이메일",
    companyName: "회사명",
    userName: "고객명",
    esName: "고객명",
    userPhone: "연락처",
    esCompanyName: "회사명",
    esUserName: "이름",
    esInsuranceTypeCode: "보험종목",
    esTitle: "제목",
    bidStatusCode: "진행경과",
    statusSmall: "진행경과",
    statusBig: "분류",
    index: "NO",
    esCode: "일련번호",
    bidCode: "NO",
    esStatusCode: "상태",
    esComments: "요청사항",
    bidComments: "보험사 코멘트",
    dday: "D-Day",
    rank: "순위",
    bidCompany: "보험사명",
    userNm: "담당자명",
    editUser: "회원정보수정",
    insuranceTypeCode: "대분류",
    key: "보험번호",
    insuranceName: "보험명",
    updateDt: "수정날짜",
    useYn: "사용여부"
}

export default translator;

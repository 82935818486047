import Layout from "../components/layouts/Layout";
import Input from "../components/input/Input";
import PageTitle from "../components/pageTitle/PageTitle";
import {auth, user} from "../services/api";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ModalComponent from "../components/modal/Modal";
import {privacyTermsData, serviceTermsData} from "../components/modal/modalContentData";
import {autoHypen, checkEmail, checkPass, timeFormat} from "../utils";
import {Modal} from "antd";
import { CheckCircleFilled } from '@ant-design/icons';
import {store} from "../stores";
import {observer} from "mobx-react";


const SnsJoin = observer(() => {
    const initialData = {
        userId: "",
        userPwd: "",
        companyName: "",
        userName: "",
        userPhone: "",
        id: "",
        platform: ""
    }
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(initialData);
    const [userPassCheck, setUserPassCheck] = useState("");
    const [emailCheck, setEmailCheck] = useState(false);
    const [passCheck, setPassCheck] = useState(false);
    const [handleService, setService] = useState(false);
    const [handlePrivacy, setPrivacy] = useState(false);
    const [serviceTerms, setAgreeService] = useState(false);
    const [privacyTerms, setAgreePrivacy] = useState(false);
    const [notEnter, setNotEnter] = useState("");
    const [authCode, setAuthCode] = useState("");
    const [trueAuth, setTrueAuth] = useState(false);
    const [authSetting, setAuthSetting] = useState(false);
    const [checkSetting, setCheckSetting] = useState(false);
    const [authBtnSetting, setAuthBtnSetting] = useState(false);
    const [btnSetting, setBtnSetting] = useState("이메일 인증");
    const [timer, setTimer] = useState(299);
    const expireAt = store.timeStore.getTime(); // 만료 시간
    const [timerMessage, setMessage] = useState("");
    const [start, setStart] = useState(false);
    const navigate = useNavigate();
    const { state } = useLocation();

    const mappingField = {
        "userId": "이메일",
        "authCode": "인증번호",
        "userPwd": "비밀번호",
        "userPassCheck": "비밀번호",
        "companyName": "회사명",
        "userName": "이름",
        "userPhone": "연락처"
    }

    useEffect(() => {
        if (!state?.data) return;
        setData((prev) => {
            return ({
                ...prev,
                userId: state.data.email ? state.data.email : "",
                userName: state.data.name ? state.data.name : "",
                userPhone: state.data.mobile ? state.data.mobile : "",
                id: state.data.id ? state.data.id : "",
                platform: state.platform ? state.platform : ""
            })
        })
    }, [])

    useEffect(() => {
        if (!start) return;
        if (timer > 0) {
            const counter = setInterval(() => {
                const gap = Math.floor((new Date(expireAt).getTime() - new Date().getTime()) / 1000)
                setTimer(gap);
            }, 1000)
            return () => clearInterval(counter)
        } else {
            setStart(false);
            setMessage("인증번호가 만료되었습니다.")
        }
    }, [start, timer, start])

    const joinUser = () => {
        let emptyValue = [];
        Object.keys(mappingField).forEach((key) => {
            if (key === "authCode") {
                if (authCode === "") {
                    emptyValue.push(mappingField[key]);
                }
            } else if (key === "userPassCheck") {
                if(data.platform === "naver") return;
                if (userPassCheck === "") {
                    emptyValue.push(mappingField[key]);
                }
            } else if (key === "userPwd") {
                if(data.platform === "naver") return;
            } else {
                if (data[key] === "" || data[key] === null || data[key] === undefined ) {
                    emptyValue.push(mappingField[key]);
                }
            }
        })
        if (emptyValue.length > 0) {
            setNotEnter(emptyValue[0]);
            Modal.warning({
                title: '알림',
                content: `${emptyValue[0]} 값을 입력해주세요.`,
            });
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }
        if (emailCheck) {
            setNotEnter("이메일");
            window.scrollTo({ top: 0, behavior: 'smooth' });
            Modal.warning({
                title: '알림',
                content: '이미 회원 가입된 이메일입니다. 로그인 후 입력해주세요.',
            });
            return;
        }
        if (data.userId !== "" && !checkEmail(data.userId)) {
            setNotEnter("이메일");
            window.scrollTo({ top: 0, behavior: 'smooth' });
            Modal.warning({
                title: '알림',
                content: '올바른 이메일 주소가 아닙니다.',
            });
            return;
        }
        if (data.platform !== "naver") {
            if (data.userPwd !== userPassCheck) {
                setNotEnter("비밀번호");
                window.scrollTo({top: 0, behavior: 'smooth'});
                Modal.warning({
                    title: '알림',
                    content: '비밀번호를 확인해주세요.',
                });
                return;
            }
            if (data.userPwd && !checkPass(data.userPwd)) {
                setNotEnter("비밀번호");
                window.scrollTo({top: 0, behavior: 'smooth'});
                Modal.warning({
                    title: '알림',
                    content: '비밀번호는 영문, 숫자, 특수문자 조합 8자 이상으로 입력해야 합니다.',
                });
                return;
            }
        }
        if (!serviceTerms || !privacyTerms) {
            Modal.warning({
                title: '알림',
                content: '약관에 동의해주세요.',
            });
            return;
        }
        if (!trueAuth){
            Modal.warning({
                title: '알림',
                content: '이메일 인증을 완료해주세요.',
            });
            return;
        }
        Modal.confirm({
            title: '확인',
            content: '가입하시겠습니까?',
            onOk: async () => {
                const tempData = {
                    ...data,
                    userPhone: data.userPhone.replace(/-/g, ''),
                }
                setLoading(true);
                auth.snsJoin(tempData)
                    .then((res) => {
                        setLoading(false);
                        if (res) {
                            if(res.commonRespCode === "1") {
                                if(data.platform === "naver") {
                                    Modal.success({
                                        title: '알림',
                                        content: "회원가입이 정상적으로 완료되었습니다. 비밀번호 설정을 위해 마이페이지로 이동합니다.",
                                    })
                                    navigate("/user/my-page", {state: {data: res.data[0]}});
                                } else {
                                    Modal.success({
                                        title: '알림',
                                        content: res.message,
                                    });
                                    navigate("/login");
                                }
                            } else {
                                Modal.error({
                                    title: '알림',
                                    content: res.message,
                                });
                            }
                        } else {
                            Modal.error({
                                title: '알림',
                                content: '오류가 발생하였습니다.',
                            });
                        }
                    });
            }
        });
    }
    const AuthButton = () => {
        if(data.userId === "") {
            setNotEnter("이메일");
            setAuthSetting(false);
            setCheckSetting(false);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            Modal.warning({
                title: '알림',
                content: '이메일 주소를 입력해주세요.',
            });
            return;
        }
        if (!checkEmail(data.userId)) {
            setNotEnter("이메일");
            setAuthSetting(false);
            setCheckSetting(false);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            Modal.warning({
                title: '알림',
                content: '올바른 이메일 주소가 아닙니다.',
            });
            return;
        }
        onSendAuthCode();
    }
    const CheckAuthButton = async () => {
        setLoading(true);
        const tempData = {
            ...data,
            userPhone: data.userPhone.replace(/-/g, ''),
            groupId: "1",
            userStatusCode: "US02",
            authCode: authCode
        }
        await user.checkAuthCode(tempData)
            .then((res)=>{
                setLoading(false);
                if(res && res.commonRespCode === "1") {
                    setTrueAuth(true);
                    setStart(false);
                    setCheckSetting(true);
                    setAuthSetting(false);
                    Modal.info({
                        title: '알림',
                        content: res.message,
                    });
                }else{
                    Modal.error({
                        title: '알림',
                        content: res.message,
                    });
                }
            })
    }
    const onSendAuthCode = async () => {
        const check = await onCheckEmail();
        if (check) return;
        setAuthSetting(true);
        setAuthBtnSetting(false);
        setBtnSetting("재전송");
        setMessage(""); // 타이머 메세지
        setTimer(299);
        setStart(false);
        await user.sendAuthCodeJoin(data)
            .then((res) => {
                if (res && res.commonRespCode === "1") {
                    store.timeStore.setTime(new Date(new Date().setMinutes(new Date().getMinutes() + 5)))
                    setAuthBtnSetting(true);
                    setStart(true); // 타이머 시작
                } else {
                    Modal.error({
                        title: '알림',
                        content: res.message,
                    });
                }
            })
    }

    const onCheckEmail = async () => {
        setCheckSetting(false);
        setAuthSetting(false);
        if (data.userId === "") return;
        let check = false;
        await user.searchUserIdCheck(data.userId).then((res) => {
            if (res) {
                if(res.commonRespCode === "0") {
                    setEmailCheck(true);
                    Modal.confirm({
                        title: '확인',
                        content: '이미 가입된 회원입니다. 기존 아이디와 연동하시겠습니까?',
                        onOk: async () => {
                            navigate("/user/link", {
                                state: {
                                    userId: data.userId,
                                    platform: data.platform,
                                    id: data.id
                                }
                            });
                        }
                    });
                    check = true;
                } else {
                    setEmailCheck(false);
                    check = false;
                }
            } else {
                Modal.error({
                    title: '알림',
                    content: '오류가 발생하였습니다.',
                });
            }
        })
        return check;
    }

    const onCheckPassword = async () => {
        if (data.userPwd.length === 0 || userPassCheck.length === 0) return;
        if (data.userPwd !== userPassCheck) {
            setPassCheck(true)
        } else {
            setPassCheck(false)
        }
    }

    const onCheckService = () => {
        if (serviceTerms) {
            setAgreeService(false)
        } else {
            setService(true);
        }
    }

    const onCheckPrivacy = () => {
        if (privacyTerms) {
            setAgreePrivacy(false)
        } else {
            setPrivacy(true);
        }
    }

    return(
        <Layout loading={loading}>
            <div className="common-wrapper page-join">
                <PageTitle title="간편 회원가입"/>
                <div className="label-form-wrap">
                    <Input
                        pageType="J"
                        id="email"
                        type="email"
                        className={notEnter === "이메일" && "input-full input-red"}
                        title="E-mail 입력"
                        alert={emailCheck && "* 이미 회원 가입된 이메일입니다. 로그인 후 입력해주세요."}
                        placeholder="메일 주소를 입력하세요."
                        value={data.userId}
                        onChange={e => {
                            if(e.target.value.length > 80) {
                                setNotEnter("이메일");
                                Modal.warning({
                                    title: '알림',
                                    content: '이메일은 80자 이하만 가능합니다.',
                                });
                            }
                            setData((prev) => {
                                return ({
                                    ...prev,
                                    userId: e.target.value.length > 80 ? "" : e.target.value,
                                })
                            })}
                        }
                        onBlur={onCheckEmail}
                        authName={btnSetting}
                        authClick={AuthButton}
                    />
                    {authSetting && <Input
                        pageType="J"
                        id="authCode"
                        title="인증번호"
                        type="text"
                        className={notEnter === "인증번호" && "input-full input-red"}
                        value={authCode}
                        placeholder="이메일로 전송된 인증번호를 입력해주세요."
                        onChange={e => setAuthCode(e.target.value)}
                        authCheck={CheckAuthButton}
                        authBtnToggle={authBtnSetting}
                        alert={start && timer > 0 ? timeFormat(timer) : timerMessage}
                    />}
                    {checkSetting && <div className="auth-text"><CheckCircleFilled style={{ fontSize: "20px" }}/> 인증이 완료되었습니다.</div>}
                    {data.platform !== "naver" &&
                        <>
                            <Input
                                pageType="J"
                                id="password"
                                title="비밀번호"
                                type="password"
                                className={notEnter === "비밀번호" && "input-red"}
                                placeholder="영문/숫자/특수문자 조합 8자 이상"
                                value={data.userPwd}
                                onChange={e => {
                                    if (e.target.value.length > 25) {
                                        setNotEnter("비밀번호");
                                        Modal.warning({
                                            title: '알림',
                                            content: '비밀번호는 25자 이하만 가능합니다.',
                                        });
                                    }
                                    setData((prev) => {
                                        return ({
                                            ...prev,
                                            userPwd: e.target.value.length > 25 ? "" : e.target.value,
                                        })
                                    })
                                }}
                            />
                            <Input
                                pageType="J"
                                id="confirmPassword"
                                title="비밀번호 확인"
                                className={notEnter === "비밀번호" && "input-red"}
                                alert={passCheck && "* 비밀번호가 일치하지 않습니다."}
                                type="password"
                                placeholder="비밀번호를 한번 더 입력하세요."
                                value={userPassCheck}
                                onChange={e => setUserPassCheck(e.target.value)}
                                onBlur={onCheckPassword}
                            />
                        </>}
                    <Input
                        pageType="J"
                        id="companyName"
                        title="회사명"
                        className={notEnter === "회사명" && "input-full input-red"}
                        placeholder="회사명을 입력하세요."
                        value={data.companyName}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                companyName: e.target.value,
                            })
                        })}
                    />
                    <Input
                        pageType="J"
                        id="customerName"
                        title="이름"
                        className={notEnter === "이름" && "input-full input-red"}
                        placeholder="이름을 입력하세요."
                        value={data.userName}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                userName: e.target.value,
                            })
                        })}
                    />
                    <Input
                        pageType="J"
                        id="phone"
                        type="tel"
                        title="연락처"
                        className={notEnter === "연락처" && "input-full input-red"}
                        value={autoHypen(data.userPhone)}
                        placeholder="휴대폰 번호를 입력하세요."
                        onChange={e => {
                            if(e.target.value.length <= 13) {
                                setData((prev) => {
                                    return ({
                                        ...prev,
                                        userPhone: e.target.value,
                                    })
                                })
                            } else {
                                setNotEnter("연락처");
                                Modal.warning({
                                    title: '알림',
                                    content: '11자리가 아닙니다.',
                                });
                            }
                        }}
                    />
                    <div className="form-block">
                        <label className="block-label">약관동의 <span style={{color: "red"}}>(필수)</span></label>
                        <div className="block-content">
                            <div>
                                <div className="input-checkbox-wrap">
                                    <input id="agree1" type="checkbox" className="input-checkbox only-sr" checked={serviceTerms} onClick={onCheckService} />
                                    <label htmlFor="agree1"/>
                                </div>
                                <label className="c-label" onClick={() => setService(true)} style={{cursor: 'pointer'}}>서비스 이용약관</label>
                            </div>
                            <div style={{marginTop: 16}}>
                                <div className="input-checkbox-wrap">
                                    <input id="agree2" type="checkbox" className="input-checkbox only-sr" checked={privacyTerms} onClick={onCheckPrivacy} />
                                    <label htmlFor="agree2"/>
                                </div>
                                <label className="c-label" onClick={() => setPrivacy(true)} style={{cursor: 'pointer'}}>개인정보 활용</label>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="btn btn-orange btn-join" onClick={joinUser}>가입하기</button>
            </div>
            <ModalComponent
                isShow={handleService}
                title="서비스 이용약관"
                onClose={() => setService(false)}
                contentComponent={serviceTermsData()}
                setChecked={setAgreeService}
            />
            <ModalComponent
                isShow={handlePrivacy}
                title="개인정보 활용 약관"
                onClose={() => setPrivacy(false)}
                contentComponent={privacyTermsData()}
                setChecked={setAgreePrivacy}
            />
        </Layout>
    )
})

export default SnsJoin;

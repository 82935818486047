import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react';
import {store} from "../../stores";
import {useParams} from "react-router-dom";
import {estimate} from "../../services/api";
import {addComma} from "../../utils";
import {isLogin} from "../../utils/auth";

const Report = observer(({ code }) => {
    const { id } = useParams();
    const [estimates, setEstimates] = useState([]);
    const user = store.userStore.getUser();

    useEffect( () => {
        checkPageAuth();
    }, []);

    const checkPageAuth = async () => {
        const auth = await isLogin();
        if (!auth) return;
        if(!id){
            await fetchData(code);
        } else {
            await fetchData(id);
        }
    }

    const fetchData = async (esCode) => {
        await estimate.searchEstimateBids(esCode).then(res => {
            const estimates = res;
            setEstimates(estimates.sort((a, b) => a.bidAmount - b.bidAmount));
        });
    }

    if (!user) return null;
    if (estimates.length === 0) return null;

    return (
        <section className="common-wrapper report-detail">
            <div className={"logoArea"}>
                <img src="/images/logo@3x.png" style={{height:"30px"}} alt={"이봐코리아 로고"} />
            </div>
            <h1 className={"report-title"}>입찰결과 보고서</h1>
            <h3 className={"tableTitle1"}>기본 정보</h3>
            <table className="tg">
                <thead>
                <tr>
                    <td className="tg-0pky lh">수신</td>
                    <td className="tg-0lax lc">{user?.userName}</td>
                    <td className="tg-baqh" rowSpan="5">공급자</td>
                    <td className="tg-0lax rh">사업자등록번호</td>
                    <td className="tg-0lax" colSpan="3">118-48-00851</td>
                </tr>
                <tr>
                    <td className="tg-0lax lh">회사명</td>
                    <td className="tg-0lax lc">{user?.companyName}</td>
                    <td className="tg-0lax rh">상호명</td>
                    <td className="tg-0lax rc3">이봐코리아</td>
                    <td className="tg-0lax rh rc3">대표자</td>
                    <td className="tg-0lax rc3">류영식</td>
                </tr>
                <tr>
                    <td className="tg-0lax lh">문서번호</td>
                    <td className="tg-0lax lc">{estimates[0].esCode}</td>
                    <td className="tg-0lax rh">담당자 이메일</td>
                    <td className="tg-0lax" colSpan="3">info@evakorea.co.kr</td>
                </tr>
                <tr>
                    <td className="tg-0lax lh">입찰마감일</td>
                    <td className="tg-0lax lc">{estimates[0].deadlineDt.slice(0, 10)}</td>
                    <td className="tg-0lax rh">담당자 연락처</td>
                    <td className="tg-0lax" colSpan="3">02-230-1234</td>
                </tr>
                </thead>
            </table>
            <h3 className={"tableTitle2"}>보험사 입찰 현황</h3>
            <table className="tg2">
                <thead>
                <tr>
                    <th className="tg2-c3ow tg2c1">번호</th>
                    <th className="tg2-c3ow tg2c2">보험사명</th>
                    <th className="tg2-0pky tg2c3">입찰 보험료</th>
                    <th className="tg2-0lax tg2c4">보험사 코멘트</th>
                </tr>
                </thead>
                <tbody>
                {estimates.map((estimate, idx) => (
                    <tr key={`report-result-${idx}`}>
                        <td className="tg2-c3ow tg2c1">{idx + 1}</td>
                        <td className="tg2-c3ow">{estimate.companyName}</td>
                        <td className="tg2-0pky">{addComma(estimate.bidAmount) + " 원"}</td>
                        <td className="tg2-0lax" style={{textAlign: 'left'}}>{estimate.bidComments}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <h3 className={"tableTitle3"}>비고 및 특이사항</h3>
            <div className={"lastComment"}>
                <ol>
                    <li>
                        귀사에서 요청하신 견적과 관련하여, <span className={"colorPoint"}>{estimates[0].companyName}</span> 이(가) <span className={"boldPoint"}>가장 저렴한 보험료를 제시</span>하였습니다. <br />
                        보험사에서 첨부 주신 보험조건과 동일하게 산출하였을 것으로 예상되나, 조건에 이상이 있는지 확인 부탁드립니다.
                    </li>
                    <li>
                        저희 <span>이봐코리아</span> 플랫폼에서는 보험계약 중에 발생할 수 있는 변경사항,
                        사고 등과 관련한 <span className={"boldPoint"}>자문서비스를 제공</span>해드리고 있으며, <br />
                        플랫폼에 <span className={"boldPoint"}>청약서류 날인본을 업로드</span> 하심으로서 편리하게 보험가입요청을 하실 수 있습니다.
                    </li>
                    <li>
                        <span className={"colorPoint"}>{user?.companyName}</span>의 발전을 기원드리며, 배전의 관심과 성원을 부탁 드립니다.
                    </li>
                </ol>
            </div>
        </section>
    );
});

export default Report;
import {Table, Radio, Select, Modal} from "antd";
import {useNavigate} from "react-router-dom";
import translator from "../../../utils/translator";
import {dateSorter, stringSorter} from "../../../utils";
import AdminLayout from "../../../components/layouts/AdminLayout";
import {auth} from "../../../services/api";
import {useEffect, useState} from "react";
import {store} from "../../../stores";
import {observer} from "mobx-react";
import CustomerEditModal from "./editModal";

const AdminCustomer = observer(() => {
    const initialData = {
        userId: "",
        pageNo: 1,
        limit: 9999,
        userStatusCode: "",
        start: "",
        searchKey: "",
        searchValue: ""
    }
    const initialList = {
        items: [],
        limit: 10,
        pageNo: 1,
        totalCount: 0,
    }
    const options = [
        {
            label: '전체',
            value: '',
        },
        {
            label: '정회원',
            value: 'US02',
        },
        {
            label: '비회원',
            value: 'US01',
        },
    ];
    const selectOption = [
        { label: '이메일', value: 'user_id' },
        { label: '회사명', value: 'company_name' },
        { label: '고객명', value: 'user_name' },
        { label: '연락처', value: 'user_phone' },
    ];
    const [iniData, setIniData] = useState(initialData);
    const [list, setList] = useState(initialList);
    const [onlyList,setOnlyList] = useState([])
    const [page, setPageNum] = useState(1);
    const [selected, setSelected] = useState(null);
    const [state, setState] = useState("");
    const [selectType, setSelectType] = useState("");
    const navigate = useNavigate();

    const fetchData = async (data) => {
        const result = await auth.searchCustomer(data).then((res) => {
            const resultData = res.items.map((item) => {
                return {
                    ...item,
                    key: item.userId,
                    userInsertDt: item.insertDt
                }
            });
            setOnlyList(resultData);
            return res;
        });
        setList(result);
    }

    useEffect(() => {
        searchList(page);
    }, [])

    useEffect(() => {
        searchList(1);
    }, [state])

    const searchList = (pageNo) => {
        const data = {
            ...iniData,
            userStatusCode: state,
            searchKey: selectType && selectType !== "" ? selectType : "",
            pageNo,
        }
        setIniData(data);
        if (data.userStatusCode === "") delete data.userStatusCode
        setPageNum(pageNo);
        fetchData(data);
    }

    const onChangeOption = ({ target: { value } }) => {
        if(!value){
            setState("");
            return;
        }
        setState(value);
    }

    const onPageChange = (data) => {
        localStorage.setItem('selectMenu', 'estimate_estimate');
        store.menuStore.setSelectMenu(localStorage.getItem('selectMenu'));
        navigate("/admin/bid/manage", { state : {email: data.userId} });
    }

    const onOpenPopup = (data) => {
        setSelected(data)
    }

    const onChangeSelect = (e) => {
        let value = e
        if (!e) {
            value = "";
        }
        setSelectType(value);
    };

    const onClickSearch =() => {
        if (iniData.searchValue !== "" && selectType === "") {
            Modal.warning({
                title: '알림',
                content: '검색하실 키워드를 선택해주세요.',
            });
            return;
        }
        searchList(1);
    }

    const header = [
        "userId",
        "companyName",
        "userName",
        "userPhone",
        "estimateCount",
        "userInsertDt"
    ]

    const columns = header.map((key) => {
        if(key === "estimateCount") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
                width: 100,
                render: (v, d) => {
                    return (
                        <div onClick={() => onPageChange(d)}>{v}</div>
                    );
                }
            }
        } else if(key === "userPhone" || key === "userName" || key === "companyName"){
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                width: 200,
                render: (v, d) => {
                    return (
                        <div onClick={() => onOpenPopup(d)}>{v}</div>
                    );
                }
            }
        } else if(key === "userInsertDt") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => dateSorter(a, b, key),
                align: 'center',
                width: 120,
                render: (v, d) => {
                    return (
                        <div onClick={() => onOpenPopup(d)}>{v}</div>
                    );
                }
            }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                render: (v, d) => {
                    return (
                        <div onClick={() => onOpenPopup(d)}>{v}</div>
                    );
                }
            }
        }
    })

    return(
        <AdminLayout title="일반 회원 관리">
            <div className="filter-wrap">
                <Radio.Group
                    options={options}
                    onChange={onChangeOption}
                    value={state}
                    optionType="button"
                    buttonStyle="solid"
                />
                <div className="filter-item">
                    <Select
                        allowClear
                        style={{ width: 120 }}
                        defaultValue={selectType}
                        onChange={onChangeSelect}
                        options={selectOption}
                    />
                    <div className="filter-content">
                        <input type="text" className="input-text input-small"
                               onChange={(e) => setIniData({
                                   ...iniData,
                                   searchValue: e.target.value
                               })}
                               onKeyPress={e => {
                                   if(e.key.toLowerCase() === 'enter') {
                                       onClickSearch();
                                   }
                        }} />
                        <button className="btn btn-small btn-orange btn-filter" onClick={onClickSearch}>검색</button>
                    </div>
                </div>
            </div>
            <Table
                dataSource={onlyList}
                columns={columns}
                style={{ cursor: "pointer" }}
                pagination={{
                    current: page,
                    onChange: (page) => setPageNum(page),
                    pageSize: 10,
                    total: list.totalCount,
                    position: ["bottomCenter"],
                    showSizeChanger: false,
                }}
                className={"adminTable"}
            />
            {selected &&
                <CustomerEditModal
                    selectData={selected}
                    statusId="customer"
                    onClose={() => {
                        setSelected(null);
                        searchList(1);
                    }}
                />
            }
        </AdminLayout>
    )
})

export default AdminCustomer;
import React, {useState} from "react";
import ModalComponent from "./Modal";
import FileSelector from "../fileSelector/FileSelector";
import {estimate} from "../../services/api";
import {store} from "../../stores";
import {Modal} from "antd";
import Loading from "../loading/loading";

const NextModal = ({isShow, esCode, bidCode, onClose}) => {
    const [loading, setLoading] = useState(false);
    const [signCount ,setSignCount] = useState(0);
    const [signList, setSignList] = useState([]);
    const allowFileList = store.allowFileListStore.getAllowFileList();
    const makeArray = (n) => {
        const arr = [];
        for(let i = 0; i < n; i++) {
            arr.push(i);
        }
        return arr;
    }

    const uploadFile = async (e) => {
        if(!e.target.files[0]) return;
        //이미지 파일만 받기
        let _lastDot = e.target.files[0].name.lastIndexOf('.');
        let _fileExt = e.target.files[0].name.substring(_lastDot+1, e.target.files[0].name.length);
        if(!allowFileList.includes(_fileExt)){
            Modal.warning({
                title: '알림',
                content: '이미지, PDF, ZIP 파일만 업로드 가능합니다.',
            });
            return;
        }
        //이미지 사이즈 확인
        if((e.target.files[0].size/1024/1024).toFixed(2) > 10){
            Modal.warning({
                title: '알림',
                content: (
                    <div>
                        <p>해당 파일은 {(e.target.files[0].size / 1024 / 1024).toFixed(2)}MB 입니다.</p>
                        <p>10MB 미만의 파일만 업로드 가능합니다.</p>
                    </div>
                ),
            });
            return;
        }
        const previewUrl = window.URL.createObjectURL(e.target.files[0]);
        const data = {
            att : e.target.files[0],
            url : previewUrl
        }
        setSignList([...signList, data]);
        setSignCount(signCount + 1);
    }

    const subscribe = async () => {
        if (signList.length === 0) {
            Modal.warning({
                title: '알림',
                content: '청약 날인 서류를 업로드해주세요.',
            });
            return;
        };
        setLoading(true);
        await estimate.dealEstimate({
            bidCode,
            esCode,
            // TODO: api 수정되면 signList[0] => signList로 보내기
            souscription: signList[0].att
        }).then(res => {
            setLoading(false);
            if(res.commonRespCode === "success") {
                Modal.success({
                    title: '알림',
                    content: (
                        <div>
                            <p>보험 가입신청이 완료되었습니다.</p>
                            <p>1~3일 이내에 담당자가 연락 드리도록 하겠습니다.</p>
                        </div>
                    )
                });
                onClose();
            } else {
                Modal.warning({
                    title: '알림',
                    content: res.message,
                });
            }
        }).catch(err => {
            Modal.error({
                title: '알림',
                content: '오류가 발생했습니다.',
            });
        });
    }

    const onClickImg = (path) => {
        let _width = '700';
        let _height = '1000';
        let _left = Math.ceil(( window.screen.width - _width )/2);
        let _top = Math.ceil(( window.screen.height - _height )/2);

       window.open(path, '_blank', `width = ${_width}, height = ${_height}, left = ${_left}, top = ${_top}`)
    }

    return (
        <>

        <ModalComponent
            isShow={isShow}
            title="보험가입 서류"
            className="next-modal-wrap"
            onClose={onClose}
            contentComponent={<div>
                <div className="next-modal-top">
                    <h3>신청인 청약 날인 서류 업로드</h3>
                    <div className="sign-file-wrapper">
                        <p>* 보험사에서 제공한 청약날인 서류를 업로드 해주세요.</p>
                        <FileSelector
                            id={`sign-file-btn-wrap`}
                            theme="orange"
                            buttonClassName="btn-small btn-orange"
                            buttonText="첨부파일 추가"
                            onChange={e => {
                                uploadFile(e);
                                // (document.getElementById('sign-file-btn-wrap') as HTMLInputElement).value = '';
                            }}
                        />
                    </div>

                </div>
                <ul className="next-modal-sign-list">
                    {makeArray(signCount).map(c => (
                        <li key={`sign-file-${c}`} onClick={() => onClickImg(signList[c].url)}>
                            <span className="sign-info">{signList[c]?.att?.name ?? signList[c].att.name}</span>
                        </li>
                    ))}
                </ul>
            </div>}
            footerComponent={<>
                <button className="btn btn-orange" onClick={subscribe}>가입요청 완료</button>
            </>}
        />
            <Loading loading={loading ? loading : false} />
        </>
    )
}

export default NextModal;
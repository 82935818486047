import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import {estimate} from "../../services/api";
import {store} from "../../stores";
import {addComma, autoHypen} from "../../utils";
import ModalComponent from "./Modal";
import NextModal from "./nextModal";
import {FileInput} from "../input/FileInput";
import {Modal} from "antd";
import translator from "../../utils/translator";

const EstimateSubscribeModal = observer(({ status, selectedData, onClose, statusCode }) => {
    const initialData = {
        esCode: "",
        esUserId: "",
        esCompany: "",
        esName: "",
        esPhone: "",
        esTitle: "",
        businessLicenseAttPath: "",
        businessLicenseAttName: "",
        businessLicenseAttId: "",
        policyAttPath: "",
        policyAttName: "",
        policyAttId: "",
        esAccident: "",
        deadlineDt: "",
        esComments: "",
        bidCode: "",
        bidInsertDt: "",
        bidCompany: "",
        bidAmount: "",
        bidPhone: "",
        bidComments: "",
        applicationAttPath: "",
        applicationAttName: "",
        applicationAttId: "",
        productAttPath: "",
        productAttName: "",
        productAttId: "",
        insuranceAttPath: "",
        insuranceAttName: "",
        insuranceAttId: "",
    }
    const user = store.userStore.getUser();
    const [biddingData, setBidding] = useState(initialData);
    const [isShowNextModal, setIsShowNextModal] = useState(false);
    const [agreement, setAgreement] = useState(false);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        await estimate.searchEstimateBidsDetail(selectedData.bidCode).then(async (res) => {
            setBidding(res);
        })
    }

    const apply = () => {
        if (!agreement) {
            Modal.warning({
                title: '알림',
                content: '상품 약관에 동의가 필요합니다.',
                zIndex: 10000,
            });
            return;
        }
        setIsShowNextModal(true);
    }

    return (
        <div>
            <ModalComponent
                isShow={isShowNextModal ? false : true}
                title="보험가입"
                width={1080}
                className="estimate-modal-wrap"
                onClose={onClose}
                contentComponent={<div>
                    <div className="modal-form-wrap">
                        <div className="common-form-wrap small">
                            <div className="form-block only-string">
								<span className="block-title">
									문서 ID
								</span>
                                <div className="block-content">
                                    <p className="content-string">{biddingData.esCode}</p>
                                </div>
                            </div>
                            <div className="form-block only-string">
								<span className="block-title">
									견적 신청인 E-mail
								</span>
                                <div className="block-content">
                                    <p className="content-string">{user?.userId}</p>
                                </div>
                            </div>
                            <div className="form-block only-string">
								<span className="block-title">
									견적 신청인 회사명
								</span>
                                <div className="block-content">
                                    <p className="content-string">{user?.companyName}</p>
                                </div>
                            </div>
                            <div className="form-block only-string">
								<span className="block-title">
									견적 신청인 고객명
								</span>
                                <div className="block-content">
                                    <p className="content-string">{user?.userName}</p>
                                </div>
                            </div>
                            <div className="form-block only-string">
								<span className="block-title">
									견적 신청인 휴대전화
								</span>
                                <div className="block-content">
                                    <p className="content-string">{user?.userPhone ? autoHypen(user.userPhone) : ''}</p>
                                </div>
                            </div>
                            <div className="form-block only-string">
								<span className="block-title">
									제목
								</span>
                                <div className="block-content">
                                    <p className="content-string">{biddingData.esTitle}</p>
                                </div>
                            </div>
                            <FileInput
                                title = "사업자등록증"
                                value = {biddingData.businessLicenseAttName}
                                path = {biddingData.businessLicenseAttPath}
                            />
                            <FileInput
                                title = "갱신증권"
                                value = {biddingData.policyAttName}
                                path = {biddingData.policyAttPath}
                            />
                            <div className="form-block only-string">
								<span className="block-title">
									3개년 사고사항
								</span>
                                <div className="block-content">
                                    <p className="content-string">{biddingData.esAccident}</p>
                                </div>
                            </div>
                            <div className="form-block only-string">
								<span className="block-title">
									{translator["bid"]} 마감일
								</span>
                                <div className="block-content">
                                    <p className="content-string">{biddingData.deadlineDt}</p>
                                </div>
                            </div>
                            <div className="form-block only-string">
								<span className="block-title">
									요청사항
								</span>
                                <div className="block-content">
                                    <p className="content-string">{biddingData.esComments}</p>
                                </div>
                            </div>
                        </div>
                        <div className="common-form-wrap small">
                            <div className="form-block only-string">
								<span className="block-title">
									견적 ID
								</span>
                                <div className="block-content">
                                    <p className="content-string">{biddingData.bidCode}</p>
                                </div>
                            </div>
                            <div className="form-block only-string">
								<span className="block-title">
									{translator["bid"]}일시
								</span>
                                <div className="block-content">
                                    <p className="content-string">{biddingData.bidInsertDt}</p>
                                </div>
                            </div>
                            <div className="form-block only-string">
								<span className="block-title">
									보험사
								</span>
                                <div className="block-content">
                                    <p className="content-string">{biddingData.bidCompany}</p>
                                </div>
                            </div>
                            <div className="form-block only-string">
								<span className="block-title">
									보험료
								</span>
                                <div className="block-content">
                                    <p className="content-string">{addComma(biddingData.bidAmount)} 원</p>
                                </div>
                            </div>
                            <div className="form-block only-string">
								<span className="block-title">
									보험 담당자 휴대전화
								</span>
                                <div className="block-content">
                                    <p className="content-string">{autoHypen(biddingData.bidPhone)}</p>
                                </div>
                            </div>
                            <div className="form-block only-string">
								<span className="block-title">
									보험사 코멘트
								</span>
                                <div className="block-content">
                                    <p className="content-string">{biddingData.bidComments}</p>
                                </div>
                            </div>
                            <FileInput
                                title = "청약서"
                                value = {biddingData.applicationAttName}
                                path = {biddingData.applicationAttPath}
                            />
                            <FileInput
                                title = "상품설명서"
                                value = {biddingData.productAttName}
                                path = {biddingData.productAttPath}
                            />
                            <FileInput
                                title = "약관"
                                value = {biddingData.insuranceAttName}
                                path = {biddingData.insuranceAttPath}
                            />
                            { biddingData.bidStatusCode === "BS08" &&
                                <FileInput
                                    title = "계약서"
                                    value = {biddingData.souscriptionAttName}
                                    path = {biddingData.souscriptionAttPath}
                                />
                            }
                            { biddingData.bidStatusCode === "BS08" &&
                                <div className="form-block only-string">
								<span className="block-title">
									계약일
								</span>
                                    <div className="block-content">
                                        <p className="content-string">{biddingData.updateDt}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {status !== "계약" && selectedData.bidStatusCode !== "BS08" &&
                        <div className="terms-agree-wrap">
                            <div className="input-checkbox-wrap">
                                <input id="agree1" type="checkbox" className="input-checkbox only-sr" onChange={e => setAgreement(e.target.checked)} />
                                <label htmlFor="agree1"/>
                            </div>
                            <label htmlFor="agree1" className="c-label">보험사의 <span>청약서</span>, <span>상품설명서</span>, <span>약관</span>의 내용을 이해하였으며 동의합니다.</label>
                        </div>
                    }
                </div>}
                footerComponent={<>
                    {status !== "계약" && selectedData.bidStatusCode !== "BS08" &&
                        <button className="btn btn-orange" onClick={apply}>가입요청</button>
                    }
                </>}
            />
            <NextModal
                isShow={isShowNextModal}
                esCode={biddingData.esCode}
                bidCode={biddingData.bidCode}
                onClose={() => {
                    setIsShowNextModal(false);
                    onClose();
                }} />
        </div>)
})

export default EstimateSubscribeModal
import './AdminHeader.scss';
import React, {useEffect, useState} from 'react';

const AdminHeader = ()=>{
    const [width, setWidth] = useState(window.innerWidth);
    const [menu, setMenu] = useState(true);

    useEffect(() => {
        let timeoutId;
        const handleResize = () => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                setWidth(window.innerWidth);
            }, 300)
        };

        const handleClick = (e)=>{
            if(window.innerWidth >= 1024){return false;}
            if(document.querySelector(".side-wrap") !== null && !e.target.classList.contains("side-wrap") && !e.target.classList.contains("menuBtn") && !e.target.classList.contains("ant-menu-title-content") && !e.target.classList.contains("ant-menu-submenu-title") && !e.target.classList.contains("ant-menu-submenu-arrow")){
                hideMenu();
            }
        }

        window.addEventListener("resize", handleResize);
        window.addEventListener("click", handleClick);

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("click", handleClick);
            window.clearTimeout(timeoutId);
        };
    }, []);

    useEffect(()=>{
        if(width >= 1024){
            document.querySelector(".side-wrap").style.left = "0px";
        }else{
            hideMenu();
        }
    }, [width]);

    const hideMenu = ()=>{
        const headerMenu = document.querySelectorAll(".adminHeaderMenu");
        headerMenu.forEach((v,i,a)=>{
            v.classList.remove('hide');
        });
        headerMenu[1].classList.add('hide');

        document.querySelector(".side-wrap").style.left = "-160px";

        setMenu(true);
    }
    const toggleMenu = ()=>{
        const headerMenu = document.querySelectorAll(".adminHeaderMenu");
        headerMenu[0].classList.toggle('hide');
        headerMenu[1].classList.toggle('hide');

        const sideMenu = document.querySelector('.side-wrap');
        if(menu){
            sideMenu.style.left = "0px";
        }else{
            sideMenu.style.left = "-160px";
        }

        setMenu(!menu);


    }
    useEffect(() => {
    }, []);
    return (
        <div className={"adminHeader"}>
            <header>
                <div className={"menuArea"}>
                    <div className="header-menu-controller" onClick={toggleMenu}>
					<span className="adminHeaderMenu open">
						<img src="/images/header/menu@3x.png" className={"menuBtn"} alt="메뉴 열기" width={24} height={24} />
					</span>
                        <span className="adminHeaderMenu close hide">
						<img src="/images/close@3x.png" className={"menuBtn"} alt="메뉴 닫기" width={24} height={24} />
					</span>
                    </div>
                </div>
                <div className={"logoArea"}>
                    <img src={"/images/logo@3x.png"} width={"60px"} alt={"이봐코리아 로고"}/>
                </div>
                <div className={"logInfoArea"}></div>
            </header>
        </div>
    )
}

export default AdminHeader;
import {Tag} from "antd";
import {addComma} from "../../utils";
import {useEffect, useState} from "react";

const BiddingCard = (({data, onPageChange})=> {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        let timeoutId;
        const handleResize = () => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                setWidth(window.innerWidth);
            }, 300)
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.clearTimeout(timeoutId);
        };
    }, []);

    const truncate = text => {
        if (width > 410) return text;
        if (width <= 410 && width > 360) {
            if (text.length <= 9) return text;
            return text.slice(0, 8).concat('...');
        } else {
            if (text.length <= 6) return text;
            return text.slice(0, 5).concat('...');
        }
    };

    const makeTag = (value) => {
            let color;
            let bordered = true;
            if (value === "계약요청") {
                color = 'green';
            } else if (value === "계약확인") {
                color = 'gold';
            } else if (value === "계약완료") {
                color = 'volcano';
            } else if (value === "입찰요청") {
                color = 'blue';
            } else if (value === "입찰확인") {
                color = 'purple';
            } else if (value === "입찰완료") {
                color = 'magenta';
            } else if (value === "견적요청") {
                color = 'purple';
            } else if (value === "견적확인") {
                color = 'red';
            } else if (value === "미참여") {
                color = 'geekblue';
            } else if (value === "입찰종료") {
                color = 'default';
                bordered = false;
            }
            return (
                <Tag color={color} key={value} bordered={bordered}>
                    {value}
                </Tag>
            );
    }
        return (
            <div>
                {data?.map((item) => {
                    return (
                        <div className={"biddingCard"} onClick={() => onPageChange(item)}>
                            <div className={"infoBox"}>
                                <div className={"top"}>
                                    {makeTag(item.bidStatusCode)}
                                    <h1>{truncate(item.bidCompany)}</h1>
                                </div>
                                <div className={"bottom"}>
                                    <p>담당자 : {item.bidUserName}</p>
                                    <p>입찰일 : {item.insertDt}</p>
                                </div>
                            </div>
                            <div className={"amountBox"}>
                                <h3>{addComma(item.bidAmount)}원</h3>
                            </div>
                        </div>
                    )
                })}
            </div>
        );
})

export default BiddingCard;
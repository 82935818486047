import Layout from "../../components/layouts/Layout";
import PageTitle from "../../components/pageTitle/PageTitle";
import {useLocation, useNavigate} from "react-router-dom";

const FindEmail = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    return (
        <Layout>
            <div className="common-wrapper page-find-email">
                <PageTitle title="이메일 찾기"/>
                <div className="page-email-list">
                    <p>고객님의 정보와 일치하는 이메일입니다.</p>
                    <p className="find-email">{state.email}</p>
                </div>
                <div className="btn-set-email">
                    <button className="btn btn-orange" onClick={() => navigate("/login")}>로그인하기</button>
                    <button className="btn btn-line-orange" onClick={() => navigate("/find/password", { state : {email: state.email} })}>비밀번호 찾기</button>
                </div>
            </div>
        </Layout>
    );
}

export default FindEmail;
import Layout from "../../components/layouts/Layout";
import PageTitle from "../../components/pageTitle/PageTitle";
import {useNavigate} from "react-router-dom";

const AskList = () => {
    const navigate = useNavigate();
    const kakaoLink = ()=>{
        // User-Agent 문자열 가져오기
        var userAgent = navigator.userAgent;
        // 모바일 여부 확인
        var isMobile = userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i);
        if(isMobile){
            window.location.href = "http://pf.kakao.com/_xbdwHxj/chat";
        }else{
            window.open("http://pf.kakao.com/_xbdwHxj/chat", "_blank", "noopener, noreferrer");
        }
    }
    return(
        <Layout>
            <div className="common-wrapper page-ask">
                <PageTitle title="문의하기"/>
                <ul className="ask-method-list-wrap">
                    <li>
                        <div>
                            <i className="icon icon-ask-email" />
                            <div className="ask-text-wrap">
                                <h3 className="ask-title">E-mail로 문의하기</h3>
                                <p>{`
								문의사항을 전달해 주시면 1~3일 이내에
								담당자가 연락드리도록 하겠습니다.
								`.trim()}</p>
                            </div>
                        </div>
                        <button className="btn btn-full btn-line-orange" onClick={() => navigate("/ask/email")}>문의하기</button>
                    </li>
                    <li>
                        <div>
                            <i className="icon icon-ask-kakao" />
                            <div className="ask-text-wrap">
                                <h3 className="ask-title">카카오톡으로 문의하기</h3>
                                <p>{`1:1채팅 상담을 통해 문의하기`.trim()}</p>
                            </div>
                        </div>
                        <a href="#" className="btn btn-full btn-line-orange" target="_blank" rel="noreferrer" onClick={()=>{kakaoLink()}}>문의하기</a>
                    </li>
                </ul>
            </div>
        </Layout>
    )
}

export default AskList;
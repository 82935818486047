import AdminLayout from "../../../components/layouts/AdminLayout";
import Input from "../../../components/input/Input";
import {useEffect, useState} from "react";
import {auth, user} from "../../../services/api";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import {Modal, Select} from "antd";
import {autoHypen, checkEmail, checkPass, companyFetch} from "../../../utils";

const CustomerEdit = observer(() => {
    const navigate = useNavigate();
    const initialData = {
        userId: "",
        companyName: "",
        userName: "",
        userPhone: "",
        estimateCount: 0
    }

    const [list, setList] = useState(initialData);
    const [companyList, setCompanyList] = useState();
    const [emailCheck, setEmailCheck] = useState(false);
    const [password, setPassword] = useState("");
    const [notEnter, setNotEnter] = useState("");

    const mappingField = {
        "companyName": "회사명",
        "userName": "이름",
        "userPhone": "연락처"
    }

    useEffect(() => {
        fetchCompany()
    }, [])

    const fetchCompany = async () => {
        const comList = await companyFetch();
        setCompanyList(comList);
    }

    const onAddInsurance = async () => {
        let emptyValue = [];
        if (emailCheck) {
            setNotEnter("이메일");
            Modal.warning({
                title: '알림',
                content: '이미 회원 가입된 이메일입니다. 로그인 후 입력해주세요.',
            });
            return;
        }
        if (list.userId !== "" && !checkEmail(list.userId)) {
            setNotEnter("이메일");
            Modal.warning({
                title: '알림',
                content: '올바른 이메일 주소가 아닙니다.',
            });
            return;
        }
        if (password === "") {
            setNotEnter("비밀번호");
            Modal.warning({
                title: '알림',
                content: '비밀번호를 확인해주세요.',
            });
            return;
        }
        if (password && !checkPass(password)) {
            setNotEnter("비밀번호");
            Modal.warning({
                title: '알림',
                content: '비밀번호는 영문, 숫자, 특수문자 조합 8자 이상으로 입력해야 합니다.',
            });
            return;
        }
        Object.keys(mappingField).forEach((key) => {
            if (list[key] === "" || list[key] === null || list[key] === undefined ) {
                emptyValue.push(mappingField[key]);
            }
        })
        if (emptyValue.length > 0) {
            setNotEnter(emptyValue[0]);
            Modal.warning({
                title: '알림',
                content: `${emptyValue[0]} 값을 입력해주세요.`,
            });
            return;
        }
        Modal.confirm({
            title: '확인',
            content: '추가하시겠습니까?',
            onOk: async () => {
                const data = {
                    userId: list.userId,
                    userName: list.userName,
                    companyName: list.companyName,
                    userPhone: list.userPhone.replace(/-/g, ''),
                    userPwd: password,
                    groupId: "2",
                    userStatusCode: "US05",
                }
                await auth.join(data)
                    .then((res) => {
                        if (res) {
                            if(res.commonRespCode === "SUCCESS") {
                                Modal.success({
                                    title: '알림',
                                    content: res.message,
                                });
                                navigate("/admin/user");
                            } else {
                                Modal.error({
                                    title: '알림',
                                    content: res.message,
                                });
                            }
                        } else {
                            Modal.error({
                                title: '알림',
                                content: '오류가 발생하였습니다.',
                            });
                        }
                    })
            }
        });
    }

    const onCheckEmail = async () => {
        if (list.userId === "") return;
        await user.searchUserIdCheck(list.userId).then((res) => {
            if (res) {
                if(res.commonRespCode === "0") {
                    setEmailCheck(true);
                    return
                }
                setEmailCheck(false);
            } else {
                Modal.error({
                    title: '알림',
                    content: '오류가 발생하였습니다.',
                });
            }
        })
    }

    return (
        <AdminLayout title="보험사 회원 추가" pop>
            <div className="admin-common-form-wrap">
                <Input
                    pageType="R"
                    title="이메일"
                    className={notEnter === "이메일" && "input-full input-red"}
                    alert={emailCheck && "* 이미 회원 가입된 이메일입니다. 로그인 후 입력해주세요."}
                    placeholder="email"
                    value={list.userId}
                    onBlur={onCheckEmail}
                    onChange={e => {
                        if(e.target.value.length > 80) {
                            setNotEnter("이메일");
                            Modal.warning({
                                title: '알림',
                                content: '이메일은 80자 이하만 가능합니다.',
                            });
                        }
                        setList((prev) => {
                            return ({
                                ...prev,
                                userId: e.target.value.length > 80 ? "" : e.target.value,
                            })
                        })}
                    }
                />
                <Input
                    pageType="R"
                    title="비밀번호"
                    className={notEnter === "비밀번호" && "input-full input-red"}
                    type="password"
                    placeholder="비밀번호"
                    value={password}
                    onChange={e => {
                        if(e.target.value.length > 25) {
                            setNotEnter("비밀번호");
                            Modal.warning({
                                title: '알림',
                                content: '비밀번호는 25자 이하만 가능합니다.',
                            });
                            setPassword("");
                            return;
                        }
                        setPassword(e.target.value)
                    }}
                />
                <div className="form-block">
					<span className="block-title">
                        회사명
					</span>
                    <div className="block-content">
                        <Select
                            className="mw10"
                            placeholder="회사 선택"
                            onChange={value => setList((prev) => {
                                return ({
                                    ...prev,
                                    companyName: value,
                                })
                            })}
                            options={companyList}
                            status={notEnter === "회사명" && "error"}
                            style={{ width: "140px", marginTop: "0px" }}
                        />
                    </div>
                </div>
                <Input
                    pageType="R"
                    title="이름"
                    className={notEnter === "이름" && "input-full input-red"}
                    placeholder="이름"
                    value={list.userName}
                    onChange={e => setList((prev) => {
                        return ({
                            ...prev,
                            userName: e.target.value,
                        })
                    })}
                />
                <Input
                    pageType="R"
                    id="phone"
                    type="tel"
                    title="연락처"
                    className={notEnter === "연락처" && "input-full input-red"}
                    value={autoHypen(list.userPhone)}
                    placeholder="휴대폰 번호를 입력하세요."
                    onChange={e => {
                        if(e.target.value.length <= 13) {
                            setList((prev) => {
                                return ({
                                    ...prev,
                                    userPhone: e.target.value,
                                })
                            })
                        } else {
                            setNotEnter("연락처");
                            Modal.warning({
                                title: '알림',
                                content: '11자리가 아닙니다.',
                            });
                        }
                    }}
                />
            </div>
            <div className="align-center" style={{margin: 20}}>
                <button className="btn btn-orange" onClick={onAddInsurance}>추가하기</button>
            </div>
        </AdminLayout>
    )
})

export default CustomerEdit;
import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {store} from "../stores";
import {observer} from "mobx-react";
import {setGroupId, setToken} from "../utils/auth";
import {auth} from "../services/api";
import {Input as inputPass, Modal} from "antd";
import {checkEmail, codeFetch} from "../utils";
import Layout from "../components/layouts/Layout";

const Login = observer(() => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [notEnter, setNotEnter] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        codeFetch();
        window.scrollTo({ top: 0 });
        var naver_id_login = new window.naver_id_login(process.env.REACT_APP_NAVER_CLIENT_ID, process.env.REACT_APP_NAVER_CALLBACK_URL);
        var state = naver_id_login.getUniqState();
        naver_id_login.setButton("white", 1,30);
        naver_id_login.setDomain(process.env.REACT_APP_DOMAIN_URL);
        naver_id_login.setState(state);
        naver_id_login.init_naver_id_login();
    }, [])

    const kakaoLogin = async ()=>{
        const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
        const KAKAO_REDIRECT_URI = process.env.REACT_APP_KAKAO_REDIRECT_URI;
        const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${KAKAO_REDIRECT_URI}&response_type=code`
        window.location.href = KAKAO_AUTH_URL;
    }

    const login = async () => {
        if(email === "") {
            setNotEnter("이메일");
            Modal.warning({
                title: '알림',
                content: '이메일 주소를 입력해주세요.',
            });
            return;
        }
        if (!checkEmail(email)) {
            setNotEnter("이메일");
            Modal.warning({
                title: '알림',
                content: '올바른 이메일 주소가 아닙니다.',
            });
            return;
        }
        const data = {
            userId: email,
            userPass: password
        }
        await auth.login(data)
            .then(async (res) => {
                if (res) {
                    if(res.commonRespCode === "success") {
                        store.userStore.setUser(res.data[0]);
                        setToken(res.data[0].token);
                        setGroupId(res.data[0].groupId);
                        navigate("/");
                    } else {
                        Modal.error({
                            title: '알림',
                            content: res.message,
                        });
                    }
                } else {
                    Modal.error({
                        title: '알림',
                        content: '오류가 발생하였습니다.',
                    });
                }
            });
    }

    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            login();
        }
    }

    return(
        <Layout>
        <div className="common-wrapper page-login">
            <div className="page-login-inner">
                <div className="mobile-wrap">
                    <div className="login-form-wrap">
                        {/*<div className="login-logo only-mobile">*/}
                        {/*    <img src="/images/logo@3x.png" width={88} height= {44} onClick={() => navigate("/")}/>*/}
                        {/*</div>*/}
                        <h2>로그인</h2>
                        <div className="login-information-wrap">
                            <div className="info-field">
                                <span>E-mail 입력</span>
                                <input
                                    type="text"
                                    className={`input-text input-full ${notEnter === "이메일" && "input-red"}`}
                                    placeholder="이메일주소를 입력하세요."
                                    onChange={e => setEmail(e.target.value)}
                                    onKeyPress={e => onEnter(e)}
                                />
                            </div>
                            <div className="info-field">
                                <span>비밀번호</span>
                                <inputPass.Password
                                    type="password"
                                    className="input-text input-full"
                                    placeholder="비밀번호를 입력하세요."
                                    onChange={e => setPassword(e.target.value)}
                                    onKeyPress={e => onEnter(e)}
                                />
                            </div>
                        </div>
                        <div className="login-helper-wrap">
                            <Link to="/find/password">e-mail / 비밀번호 찾기</Link>
                            <i className="helper-split"></i>
                            <Link to="/join" onClick={() => {
                                localStorage.setItem('selectMenu', 'join');
                                store.menuStore.setSelectMenu(localStorage.getItem('selectMenu'));
                            }}>가입하기</Link>
                        </div>

                        <button className="btn btn-full btn-orange" onClick={login}>로그인</button>
                        <div className={"sns-login-wrap"}>
                            <span><span /><h3>간편 로그인</h3></span>
                            <div>
                                <div  id="naver_id_login" className={"sns-logo"}>
                                    <img src={"/images/login/naverlogo.png"} alt={"네이버 로그인"} width={"30px"} height={"30px"}/>
                                </div>
                                <div className={"sns-logo"} onClick={kakaoLogin}>
                                    <img src={"/images/login/kakaoLogin.png"} alt={"카카오 로그인"} width={"30px"} height={"30px"}/>
                                </div>
                                {/*<div className={"sns-logo"} onClick={()=>{snsLogin("google")}}>*/}
                                {/*    <img src={"/images/login/googlelogo.png"} width={"30px"} height={"30px"}/>*/}
                                {/*</div>*/}
                                {/*<div className={"sns-logo"} onClick={()=>{snsLogin("apple")}}>*/}
                                {/*    <img src={"/images/login/applelogo.png"} width={"30px"} height={"30px"}/>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="login-bg-wrap">
                        <Link to="/">
                            <span className="only-sr">이봐 홈으로</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        </Layout>
    )
})

export default Login;
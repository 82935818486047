import {axiosInstance} from "../index";

export const estimate = {
    searchEstimate : async (data) => {
        const form = new FormData();
        form.append("userId", data.userId);
        form.append("pageNo", data.pageNo);
        form.append("limit", data.limit);
        form.append("searchKey", data.searchKey);
        form.append("searchValue", data.searchValue);
        const result = await axiosInstance.post("/estimate/list", form);
        return result.data;
    },
    searchEstimateStep : async (userId) => {
        const result = await axiosInstance.post(`/estimate/list/step/${userId}`);
        return result.data;
    },
    searchEstimateDetail : async (code) => {
        const result = await axiosInstance.get(`/estimate/${code}`);
        return result.data;
    },
    searchEstimateBids : async (code) => {
        const result = await axiosInstance.get(`/estimate/bids/${code}`);
        return result.data;
    },
    searchEstimateBidsDetail : async (code) => {
        const result = await axiosInstance.get(`/estimate/bids/detail/${code}`);
        return result.data;
    },
    dealEstimate : async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            if (key === "souscription") {
                formData.append(key, data[key], data[key].name)
            } else {
                formData.append(key, data[key]);
            }
        })
        const result = await axiosInstance.post("/estimate/deal", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return result.data;
    },
    updateEstimate : async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        })
        const result = await axiosInstance.post(`/estimate/list/edit`, formData);
        return result.data;
    },
}

export const user = {
    sendCheckAuthCode: async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key)=>{
            formData.append(key, data[key]);
        });
        const result = await axiosInstance.post("/user/authCodeCheck", formData);
        return result.data;
    },
    updateUser: async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        })
        const result = await axiosInstance.post("/user/edit",formData);
        return result.data;
    },
    requestEstimate: async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            if (key === "biz" || key === "policy") {
                formData.append(key, data[key], data[key].name)
            } else {
                formData.append(key, data[key]);
            }
        })
        const result = await axiosInstance.post("/estimate/request", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return result.data;
    },
    findUserId: async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        })
        const result = await axiosInstance.post(`/user/find/id`, formData);
        return result.data;
    },
    sendAuthCode: async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        })
        const result = await axiosInstance.post(`/user/find/pwd`, formData);
        return result.data;
    },
    sendAuthCodeJoin: async (data) => {
        const result = await axiosInstance.post(`/user/send/authCode/${data.userId}`);
        return result.data;
    },
    checkAuthCode: async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        })
        const result = await axiosInstance.post(`/user/check/authCode`, formData);
        return result.data;
    },

    updatePassword: async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        })
        const result = await axiosInstance.post(`/user/update/password`, formData);
        return result.data;
    },
    resetPassword: async (userId) => {
        const result = await axiosInstance.post(`/user/reset/password?userId=${userId}`);
        return result.data;
    },
    searchUserIdCheck: async (userId) => {
        const result = await axiosInstance.post(`/user/check/${userId}`);
        return result.data;
    },
    searchUserSnsLogin: async (userId) => {
        const result = await axiosInstance.post(`/user/sns/use/${userId}`);
        return result.data;
    },
    deleteUserSnsLogin: async (data) => {
        const form = new FormData();
        form.append("userId", data.userId);
        form.append("platform", data.platform);
        const result = await axiosInstance.post("/user/sns/unlink", form);
        return result.data;
    },
    AddUserSnsLogin: async (data) => {
        const form = new FormData();
        form.append("userId", data.userId);
        form.append("platform", data.platform);
        form.append("id", data.id);
        const result = await axiosInstance.post("/user/sns/link/loginUser", form);
        return result.data;
    },
    getNaverToken: async (data) => {
        const form = new FormData();
        Object.keys(data).forEach((key) => {
            form.append(key, data[key]);
        });
        const result = await axiosInstance.post("/user/naver/token", form);
        return result.data;
    }
}

export const business = {
    searchBusiness : async (data) => {
        const form = new FormData();
        form.append("userId", data.userId);
        form.append("pageNo", data.pageNo);
        form.append("limit", data.limit);
        form.append("searchKey", data.searchKey);
        form.append("searchValue", data.searchValue);
        form.append("dateType", data.dateType);
        form.append("fromDate", data.fromDate);
        form.append("toDate", data.toDate);
        const result = await axiosInstance.post("/business/estimate/list", form);
        return result.data;
    },
    searchBusinessStep : async (userId) => {
        const result = await axiosInstance.post(`/business/list/step/${userId}`);
        return result.data;
    },
    searchBusinessEstimate : async (code) => {
        const result = await axiosInstance.get(`/business/estimate/${code}`);
        return result.data;
    },
    searchBusinessBids : async (code) => {
        const result = await axiosInstance.get(`/business/bid/${code}`);
        return result.data;
    },
    searchCompany : async () => {
        const result = await axiosInstance.post("/business/list");
        return result.data;
    },
    biddingEstimate : async (data) => {
        const formData = new FormData();

        if(data.insuranceAtt === null){
            delete data.insuranceAtt;
        }
        if(data.productAtt === null){
            delete data.productAtt;
        }
        
        Object.keys(data).forEach((key) => {
            if (key === "applicationAtt" && key === "productAtt" && key === "insuranceAtt" && data[key] !== "") {
                formData.append(key, data[key], data[key].name)
            } else {
                formData.append(key, data[key]);
            }
        })

        const result = await axiosInstance.post(`/business/bidding`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return result.data;
    },
    updateBidding : async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        })
        const result = await axiosInstance.post(`/business/bidding/update`, formData);
        return result.data;
    },
    deleteBidding : async (bidCode) => {
        const result = await axiosInstance.post(`/business/bidding/delete/${bidCode}`);
        return result.data;
    },
}

export const ask = {
    askEmail: async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            if (key === "att" && data[key] !== "") {
                formData.append(key, data[key], data[key].name)
            } else {
                formData.append(key, data[key]);
            }
        })
        const result = await axiosInstance.post("/ask/email", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return result.data;
    },
}

export const auth = {
    login: async (data) => {
        const form = new FormData();
        form.append("userId", data.userId);
        form.append("userPass", data.userPass);
        const result = await axiosInstance.post("/user/login", form);
        return result.data;
    },
    join: async (data) => {
        const form = new FormData();
        form.append("userId", data.userId);
        form.append("userPwd", data.userPwd);
        form.append("companyName", data.companyName);
        form.append("userName", data.userName);
        form.append("userPhone", data.userPhone);
        form.append("groupId", data.groupId);
        form.append("userStatusCode", data.userStatusCode);
        const result = await axiosInstance.post("/user/join", form, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return result.data;
    },
    snsJoin : async (data) => {
        const form = new FormData();
        form.append("userId", data.userId);
        form.append("userPwd", data.userPwd);
        form.append("companyName", data.companyName);
        form.append("userName", data.userName);
        form.append("userPhone", data.userPhone);
        form.append("platform", data.platform);
        form.append("id", data.id);
        const result = await axiosInstance.post("/user/sns/join", form);
        return result.data;
    },
    snsLink: async (data) => {
        const form = new FormData();
        form.append("userId", data.userId);
        form.append("userPwd", data.userPwd);
        form.append("id", data.id);
        form.append("platform", data.platform);
        const result = await axiosInstance.post("/user/sns/link", form);
        return result.data;
    },
    getUserData: async (userId) => {
        const result = await axiosInstance.post(`/user/data?userId=${userId}`);
        return result.data.data[0];
    },
    fetchCode : async () => {
        const result = await axiosInstance.post("/code");
        return result.data;
    },
    fetchInsuranceMenu : async () => {
        const result = await axiosInstance.get("/menu");
        return result.data;
    },
    searchUser: async (userId) => {
        const result = await axiosInstance.post(`/admin/info/${userId}`);
        return result.data;
    },
    updateSetting : async (setting) => {
        const form = new FormData();
        Object.keys(setting).forEach((key) => {
            form.append(key, setting[key]);
        });
        const result = await axiosInstance.post("/admin/setting", form);
        return result.data;
    },
    searchCustomer : async (data) => {
        const form = new FormData();
        form.append("pageNo", data.pageNo);
        form.append("limit", data.limit);
        if(data.userStatusCode) {
            form.append("userStatusCode", data.userStatusCode);
        }
        form.append("searchKey", data.searchKey);
        form.append("searchValue", data.searchValue);
        const result = await axiosInstance.post("/admin/customer/list", form);
        return result.data;
    },
    searchInsurance : async (data) => {
        const form = new FormData();
        form.append("pageNo", data.pageNo);
        form.append("limit", data.limit);
        form.append("searchKey", data.searchKey);
        form.append("searchValue", data.searchValue);
        const result = await axiosInstance.post("/admin/insurancer/list", form);
        return result.data;
    },
    searchBidding: async (data) => {
        const form = new FormData();
        form.append("userId", data.userId);
        form.append("pageNo", data.pageNo);
        form.append("limit", data.limit);
        form.append("searchKey", data.searchKey);
        form.append("searchValue", data.searchValue);
        form.append("dateType", data.dateType);
        form.append("fromDate", data.fromDate);
        form.append("toDate", data.toDate);
        const result = await axiosInstance.post("/admin/bidding/list", form);
        return result.data;
    },
    searchEstimate: async (data) => {
        const form = new FormData();
        form.append("userId", data.userId);
        form.append("pageNo", data.pageNo);
        form.append("limit", data.limit);
        form.append("searchKey", data.searchKey);
        form.append("searchValue", data.searchValue);
        form.append("dateType", data.dateType);
        form.append("fromDate", data.fromDate);
        form.append("toDate", data.toDate);
        form.append("esStatus", data.esStatus);
        const result = await axiosInstance.post("/admin/estimate/list", form);
        return result.data;
    },
    searchAdminEstimateDetail: async (esCode) => {
        const result = await axiosInstance.get(`/admin/estimate/detail?esCode=${esCode}`);
        return result.data;
    },
    searchAdminEstimateBids: async (esCode) => {
        const result = await axiosInstance.get(`/admin/estimate/bids?esCode=${esCode}`);
        return result.data;
    },
    searchAdminUser: async (esCode) => {
        const result = await axiosInstance.post(`/admin/estimate/auth/${esCode}`);
        return result.data;
    },
    searchInsuranceDetail: async (bidCode) => {
        const result = await axiosInstance.get(`/admin/bids/detail/${bidCode}`);
        return result.data;
    },
    searchAdminContract: async (data) => {
        const form = new FormData();
        form.append("userId", data.userId);
        form.append("pageNo", data.pageNo);
        form.append("limit", data.limit);
        form.append("searchKey", data.searchKey);
        form.append("searchValue", data.searchValue);
        form.append("dateType", data.dateType);
        form.append("fromDate", data.fromDate);
        form.append("toDate", data.toDate);
        form.append("esStatus", data.esStatus);
        const result = await axiosInstance.post("/admin/contract/list", form);
        return result.data;
    },
    searchContractDetail: async (esCode) => {
        const result = await axiosInstance.get(`/admin/contract/detail?esCode=${esCode}`);
        return result.data;
    },
    searchInsuranceStatistics: async (data) => {
        const form = new FormData();
        form.append("userId", data.userId);
        form.append("pageNo", data.pageNo);
        form.append("limit", data.limit);
        form.append("fromDate", data.fromDate);
        form.append("toDate", data.toDate);
        form.append("dateType", data.dateType);
        form.append("searchKey", data.searchKey);
        form.append("searchValue", data.searchValue);
        const result = await axiosInstance.post("/admin/insurancer/statistics/list", form);
        return result.data;
    },
    searchTotalStatistics: async (data) => {
        const form = new FormData();
        form.append("userId", data.userId);
        form.append("pageNo", data.pageNo);
        form.append("limit", data.limit);
        form.append("fromDate", data.fromDate);
        form.append("toDate", data.toDate);
        form.append("dateType", data.dateType);
        form.append("searchKey", data.searchKey);
        form.append("searchValue", data.searchValue);
        const result = await axiosInstance.post("/admin/statistics/total", form);
        return result.data;
    },
    searchGraphStatistics: async (data) => {
        const form = new FormData();
        form.append("userId", data.userId);
        form.append("pageNo", data.pageNo);
        form.append("limit", data.limit);
        form.append("fromDate", data.fromDate);
        form.append("toDate", data.toDate);
        form.append("dateType", data.dateType);
        form.append("searchKey", data.searchKey);
        form.append("searchValue", data.searchValue);
        const result = await axiosInstance.post("/admin/statistics/graph", form);
        return result.data;
    },
    searchDashboard: async () => {
        const result = await axiosInstance.post("/admin/dashboard", );
        return result.data;
    },
    updateEstimate : async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        })
        const result = await axiosInstance.post(`/admin/update/estimate`, formData);
        return result.data;
    },
    updateUser : async (data) => {
        const result = await axiosInstance.post(`/admin/customer/edit`, data);
        return result.data;
    },
    completeContract : async (data) => {
        const result = await axiosInstance.get(`/admin/contract/deal?esCode=${data.esCode}&bidCode=${data.bidCode}`);
        return result.data;
    },
    changeUser : async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        })
        const result = await axiosInstance.post('/admin/user/change', formData);
        return result.data;
    },
    deleteUser : async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        })
        const result = await axiosInstance.post('/admin/delete/user', formData);
        return result.data;
    },
    deleteEstimate : async (esCode) => {
        const result = await axiosInstance.post(`/admin/estimate/cancel/${esCode}`);
        return result.data;
    },

    fetchTrade : async () => {
        const result = await axiosInstance.post(`/trade`);
        return result.data;
    },
    selectAdminSetting : async ()=>{
        const result = await axiosInstance.post(`/admin/check/setting`);
        return result.data;
    },
    naverUserInfo : async (token)=>{
        const form = new FormData();
        form.append("token", token);
        const result = await axiosInstance.post(`/user/naver/info`, form);
        return result.data;
    },
    kakaoUserInfo : async (data)=>{
        const form = new FormData();
        form.append("id", data.id);
        const result = await axiosInstance.post(`/user/kakao/info`, form);
        return result.data;
    }
}
export const insurance = {
    searchInsuranceNmAndUrl : async () => {
        const result = await axiosInstance.post('/admin/insurance');
        return result.data;
    },
    searchInsuranceProducts : async (data) => {
        const form = new FormData();
        form.append("pageNo", data.pageNo);
        form.append("limit", data.limit);
        form.append("searchKey", data.searchKey);
        form.append("searchValue", data.searchValue);
        const result = await axiosInstance.post(`/admin/insurance/products`, form);
        return result.data;
    },
    searchInsuranceDetail : async (insuranceCode) => {
        const result = await axiosInstance.post(`/admin/insurance/detail/${insuranceCode}`);
        return result.data;
    },
    deleteInsuranceProducts : async (list) => {
        const form = new FormData();
        form.append("insuranceCodeList", list);
        const result = await axiosInstance.post("/admin/insurance/delete", form);
        return result.data;
    },
    addInsuranceProduct : async (data) => {
        const form = new FormData();
        Object.keys(data).forEach((key) => {
            form.append(key, data[key]);
        });
        const result = await axiosInstance.post(`/admin/insurance/add`, form);
        return result.data;
    },
    searchInsuranceTypeCode : async ()=>{
        const result = await axiosInstance.post(`/admin/insurance/type`);
        return result.data;
    },
    updateInsurance : async  (data)=>{
        const form = new FormData();
        Object.keys(data).forEach((key) => {
            form.append(key, data[key]);
        })
        const result = await axiosInstance.post(`/admin/insurance/update`, form);
        return result.data;
    },
    changeInsuranceProductsUse : async (data) => {
        const form = new FormData();
        Object.keys(data).forEach((key) => {
            form.append(key, data[key]);
        })
        const result = await axiosInstance.post(`/admin/insurance/use`, form);
        return result.data;
    },
}

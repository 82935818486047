import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import AdminLayout from "../../../components/layouts/AdminLayout";
import {insurance} from "../../../services/api";
import {getCode} from "../../../utils/code";
import {Modal} from "antd";

const InsuranceDetail = () => {
    const initialData = {
        insuranceCode:"",
        insuranceTypeCode:"",
        insuranceName:"",
        subTitle:"",
        backgroundAttPath:"",
        introduction:"",
        signTarget:"",
        compensation:"",
        effect:"",
        notCompensation:"",
        basicDoc:"",
        etcDocAttPath:"",
    }
    const code = getCode();
    const { id } = useParams();
    const navigate = useNavigate();
    const [list, setList] = useState(initialData);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        await insurance.searchInsuranceDetail(id).then((res) => {
            const resultData ={
                    ...res,
                    insuranceName: code[res.insuranceCode]
            }
            setList(resultData);
        })
    }
    const onEditInsurance = () => {
        navigate(`/admin/insurance/edit`, {state : {insuranceCode : id, preInsuranceName : list.insuranceName}})
    }

    const onChangeUse = () => {
        Modal.confirm({
            title: '확인',
            content: '상태를 변경하시겠습니까?',
            onOk: async () => {
                const newData = {
                    insuranceCode: list.insuranceCode,
                    useYn: list?.useYn === "Y" ? "N" : "Y",
                }
                await insurance.changeInsuranceProductsUse(newData).then((res)=>{
                    if(res && res.commonRespCode === "1"){
                        Modal.success({
                            title: '알림',
                            content: '성공적으로 변경되었습니다.',
                        });
                        fetchData();
                    } else {
                        Modal.error({
                            title: '알림',
                            content: `다시 시도해주세요.`,
                        });
                    }
                });
            }
        });
    }

    return (
        <AdminLayout title="기업 보험 상품 상세" pop>
            <div className="align-right" style={{ marginBottom: "8px", marginRight: "60px" }}>
                <button className="btn btn-small btn-orange" onClick={onEditInsurance}>수정</button>
                <button className="btn btn-small btn-line-orange" onClick={onChangeUse}>{list?.useYn === "Y" ? "미사용" : "사용"}</button>
            </div>
            <div className="common-wrapper">
                {list?.backgroundAttPath && <img src={list?.backgroundAttPath} width="100%" alt="배경이미지" />}
                <section className="page-insurance-summary pl-40">
                    <h2>{list?.insuranceName}</h2>
                    <p dangerouslySetInnerHTML={{__html: list?.subTitle}}></p>
                </section>
                <section className="page-insurance-intro pl-40" >
                    <div>상품소개</div>
                    <p dangerouslySetInnerHTML={{__html: list?.introduction}}></p>
                    <div>가입이 필요한 곳</div>
                    <p dangerouslySetInnerHTML={{__html: list?.signTarget}}></p>
                    <div>보상하는 손해</div>
                    <p dangerouslySetInnerHTML={{__html: list?.compensation}}></p>
                    <div>개요/필요성/효과</div>
                    <p dangerouslySetInnerHTML={{__html: list?.effect}}></p>
                    <div>주요 보상하지 아니하는 손해</div>
                    <p dangerouslySetInnerHTML={{__html: list?.notCompensation}}></p>
                    <div>기초서류</div>
                    <p dangerouslySetInnerHTML={{__html: list?.basicDoc}}></p>
                    <div>설문서, 제안서 및 약관</div>
                    <a href={list.etcDocAttPath} className="btn btn-small btn-line-orange" target="_blank" rel="noopener noreferrer">보기</a>
                </section>
            </div>
        </AdminLayout>
    )
}

export default InsuranceDetail;
import AdminLayout from "../../../components/layouts/AdminLayout";
import {auth} from "../../../services/api";
import React, { useState, useEffect } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, ArcElement, Tooltip, Legend } from 'chart.js';
import translator from "../../../utils/translator";
import {Line} from "react-chartjs-2";
import {store} from "../../../stores";
import {DatePicker, Modal, Select, Table, Tag} from "antd";
import {dateRange, dateSorter, stringSorter} from "../../../utils";
import dayjs from "dayjs";
import {getCode} from "../../../utils/code";

const BidStatistics = () => {
    const user = store.userStore.getUser();
    const code = getCode();
    const today = new Date();
    const formatToday = dayjs(today).format("YYYY-MM-DD");
    const preDay = dayjs(today).format("YYYY-01-01");
    const initialData = {
        userId: user.userId,
        pageNo: 1,
        limit: 9999,
        start: 0,
        searchKey: "",
        searchValue: "",
        dateType: "es_insert_dt",
        fromDate: preDay,
        toDate: formatToday
    }
    const selectOption = [
        { label: '견적번호', value: 'es_code' },
        { label: '입찰번호', value: 'bid_code' },
        { label: '견적일시', value: 'es_insert_dt' },
        { label: '고객명', value: 'es_name' },
        { label: '입찰제시일', value: 'bid_insert_dt' },
        { label: '보험사명', value: 'bid_company_name' },
        { label: '담당자명', value: 'bid_name' },
        { label: '진행경과', value: 'status_small' },
        { label: '분류', value: 'status_big' },
    ];
    const selectSmallOption = [
        { label: '입찰요청', value: 'ES01' },
        { label: '입찰완료', value: 'ES03' },
        { label: '견적요청', value: 'BS01' },
        { label: '계약요청', value: 'BS06' },
        { label: '계약완료', value: 'BS08' },
        { label: '입찰종료', value: 'ES07' },
        { label: '입찰종료', value: 'BS09' },
    ];
    const selectBigOption = [
        { label: '입찰', value: '입찰' },
        { label: '응찰', value: '응찰' },
        { label: '계약', value: '계약' },
    ];

    const [data, setData] = useState(initialData);
    const [tableData, setTableData] = useState([]);
    const [graphLabel, setGraphLabel] = useState([]);
    const [bidList, setBidList] = useState([]);
    const [contractList, setContractList] = useState([]);
    const [estimateList, setEstimateList] = useState([]);
    const [list, setList] = useState([]);
    const [page, setPageNum] = useState(1);
    const [selectType, setSelectType] = useState("");
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        let timeoutId;
        const handleResize = () => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                setWidth(window.innerWidth);
            }, 300)
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.clearTimeout(timeoutId);
        };
    }, []);

    useEffect(() => {
        fetchData(data);
    }, [])

    const fetchData = async (data) => {
        await auth.searchTotalStatistics(data).then((res) => {
            setTableData(res.data[0]);
        });
        await auth.searchGraphStatistics(data).then((res) => {
            makeLineGraph(res.data[0]);
        });
        await auth.searchInsuranceStatistics(data).then((res) => {
            const newData = res?.items?.map((item) => {
                return {
                    ...item,
                    estimateCode: item.esCode,
                    biddingCode: item.bidCode,
                    bidUserName: item.bidName,
                    statusSmall: code[item.statusSmall],
                    key: item.rownum,
                }
            });
            setList({
                ...res,
                items: newData,
            })
        });
    }

    const makeLineGraph = (data) => {
        const label = [];
        const list1 = [];
        const list2 = [];
        const list3 = [];
        data.biddingCountList.forEach((item) => {
            label.push(item.date);
            list1.push(item.count);
        });
        data.contractCountList.forEach((item) => {
            list2.push(item.count);
        });
        data.estimateCountList.forEach((item) => {
            list3.push(item.count);
        });
        setGraphLabel(label);
        setBidList(list1);
        setContractList(list2);
        setEstimateList(list3);
    }

    const onClickSearch =() => {
        if (data.searchValue !== "" && selectType === "") {
            Modal.warning({
                title: '알림',
                content: '검색하실 키워드를 선택해주세요.',
            });
            return;
        }
        if (selectType === "es_insert_dt" || selectType === "bid_insert_dt") {
            if (data.fromDate === "" || data.toDate === "") {
                Modal.warning({
                    title: '알림',
                    content: '시작 날짜과 끝 날짜를 입력해주세요.',
                });
                return;
            }
            if (!dateRange(data.fromDate, data.toDate)) {
                Modal.warning({
                    title: '알림',
                    content: '시작 날짜가 끝 날짜보다 클 수 없습니다.',
                });
                return;
            }
        }
        searchList(data);
    }

    const searchList = () => {
        let searchType;
        if (selectType === "es_insert_dt" || selectType === "bid_insert_dt") {
            searchType = "dateType";
        } else {
            searchType = "searchKey";
        }
        const tempData = {
            ...data,
            fromDate: searchType === "searchKey" ? preDay : data.fromDate,
            toDate: searchType === "searchKey" ? formatToday : data.toDate,
            dateType: searchType === "searchKey" ? "es_insert_dt" : selectType,
            searchKey: searchType === "searchKey" ? selectType : "",
            searchValue: searchType === "searchKey" ? data.searchValue : ""
        }
        setData(tempData);
        fetchData(tempData);
    }

    const onChangeSelect = (e) => {
        let value = e
        if (!e) {
            value = "";
        }
        setSelectType(value);
    };

    const onChangeSelectValue = (e) => {
        let value = e
        if (!e) {
            value = "";
        }
        setData({
            ...data,
            searchValue: value
        })
    }

    const onChangeFromDate = (date, dateString) => {
        setData({
            ...data,
            fromDate: dateString !== "" ? `${dateString} 00:00:00` : "",
        })
    };

    const onChangeToDate = (date, dateString) => {
        setData({
            ...data,
            toDate: dateString !== "" ? `${dateString} 23:59:59` : "",
        })
    };

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        ArcElement,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
            title: {
                display: false,
            },
        },
    };

    const graphData = {
        labels: graphLabel,
        datasets: [
            {
                label: '입찰 건수',
                data: estimateList,
                borderColor: 'rgb(255, 206, 86)',
                borderWidth: 1,
                backgroundColor: 'rgba(255, 206, 86, 0.5)',
            },
            {
                label: '응찰 건수',
                data: bidList,
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 1,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: '계약 건수',
                data: contractList,
                borderColor: 'rgb(53, 162, 235)',
                borderWidth: 1,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },

        ],
    };

    const header = [
        "rownum",
        "estimateCode",
        "biddingCode",
        "esInsertDt",
        "esName",
        "bidInsertDt",
        "bidCompanyName",
        "bidUserName",
        "statusSmall",
        "statusBig"
    ];

    const columns = header.map((key) => {
        if(key === "esInsertDt" || key === "bidInsertDt") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => dateSorter(a, b, key),
                align: 'center',
            }
        } else if (key === "statusBig") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                width: "100px",
                render: (value) => {
                    let color;
                    if (value === "입찰") {
                        color = 'gold';
                    } else if (value === "응찰") {
                        color = 'magenta';
                    } else if (value === "계약") {
                        color = 'blue';
                    }
                    return (
                        <Tag color={color} key={value}>
                            {value}
                        </Tag>
                    );
                }
            }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
            }
        }
    });

    return(
        <AdminLayout title={`${translator["bid"]} 통계`}>
            <div className="filter-wrap" style={{ justifyContent: "end" }}>
                <div className="filter-item">
                    <Select
                        allowClear
                        style={{ width: 120 }}
                        defaultValue={selectType}
                        onChange={onChangeSelect}
                        options={selectOption}
                    />
                    <div className="filter-content">
                        {selectType !== "es_insert_dt" && selectType !== "bid_insert_dt" && selectType !== "status_small" && selectType !== "status_big" &&
                            <input
                            type="text"
                            className="input-text input-small"
                            onChange={(e) => setData({
                                ...data,
                                searchValue: e.target.value
                            })}
                            onKeyPress={e => {
                                if(e.key.toLowerCase() === 'enter') {
                                    onClickSearch();
                                }
                            }}
                        />
                        }
                        {selectType === "es_insert_dt" || selectType === "bid_insert_dt" ?
                            <>
                                <DatePicker
                                    onChange={onChangeFromDate}
                                    placeholder=""
                                    style={{width: "120px"}}
                                /> ~<DatePicker
                                onChange={onChangeToDate}
                                placeholder=""
                                style={{width: "120px"}}
                            />
                            </>
                            : null
                        }
                        {selectType === "status_small" ?
                            <Select
                                allowClear
                                style={{ width: 196 }}
                                onChange={onChangeSelectValue}
                                options={selectSmallOption}
                            />
                            : null
                        }
                        {selectType === "status_big" ?
                            <Select
                                allowClear
                                style={{ width: 196 }}
                                onChange={onChangeSelectValue}
                                options={selectBigOption}
                            />
                            : null
                        }
                        <button className="btn btn-small btn-orange btn-filter" onClick={onClickSearch}>검색</button>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: "10px" }}>
                <table className="statistics-table">
                    <thead>
                    <tr>
                        {tableData?.map((item) => {
                            return (
                                <th key={item.status}>{item.status}</th>
                            )
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        {tableData?.map((item) => {
                            return (
                                <td key={item.status}>{item.count}</td>
                            )
                        })}
                    </tr>
                    </tbody>
                </table>
            </div>
            <div style={{
                marginTop: "10px",
            }}>
                <Line options={options} data={graphData} height={80} redraw />
            </div>
            <div className="table-wrapper" style={{ marginTop: "40px" }}>
                <Table
                    dataSource={list.items}
                    columns={columns}
                    pagination={{
                        current: page,
                        onChange: (page) => setPageNum(page),
                        pageSize: 10,
                        total: list.totalCount,
                        position: ["bottomCenter"],
                        showSizeChanger: false,
                    }}
                    className={"adminTable"}
                />
            </div>
        </AdminLayout>
    )
}

export default BidStatistics;
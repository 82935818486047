import {Input as inputPass, InputNumber} from 'antd';

const Input = (props) => {
    return(
        <>
            {props.pageType === "J" &&
                <div className="form-block">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <label htmlFor={props.id ? props.id : ""} className="block-label">{props.title}</label>
                        {props.alert && <p>{props.alert}</p>}
                    </div>
                    <div className="block-content" style={{ display: "flex" }}>
                        {props.type === "password" ?
                            <inputPass.Password
                                id = {props.id ? props.id : ""}
                                type={props.type ? props.type : "text"}
                                className={props.className? `input-text ${props.className}` : "input-text input-full"}
                                placeholder={props.placeholder ? props.placeholder : ""}
                                value={props.value ? props.value : ""}
                                readOnly={props.readOnly ? true : false}
                                onChange={props.onChange && props.onChange}
                                onKeyPress={props.onKeyPress && props.onKeyPress}
                                onBlur={props.onBlur && props.onBlur}
                            /> :
                            <input
                                id = {props.id ? props.id : ""}
                                type={props.type ? props.type : "text"}
                                className={props.className? `input-text ${props.className} input-full` : "input-text input-full"}
                                placeholder={props.placeholder ? props.placeholder : ""}
                                value={props.value ? props.value : ""}
                                readOnly={props.readOnly ? true : false}
                                onChange={props.onChange && props.onChange}
                                onKeyPress={props.onKeyPress && props.onKeyPress}
                                onBlur={props.onBlur && props.onBlur}
                            />
                        }
                        {props.authClick && <button className="btn btn-line-orange btn-email" onClick={props.authClick} style={{ width : "140px", marginLeft: "8px" }}>{props.authName ? props.authName : "이메일 인증"}</button>}
                        {props.authCheck && <button className={`btn ${props.authBtnToggle ? "btn-full-orange" : "btn-gray"} btn-email`} onClick={props.authCheck} style={{ width : "140px", marginLeft: "8px" }}>인증</button>}
                    </div>
                </div>
            }
            {props.pageType === "R" &&
                <div className={'form-block'}>
                    <span className="block-title">
                        {props.title}
                        {props.alert && <p>{props.alert}</p>}
                    </span>
                    <div className="block-content" style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex" }}>
                            {props.type === "password" ?
                                <inputPass.Password
                                    type={props.type ? props.type : "text"}
                                    className={props.className? `input-text ${props.className}` : "input-text input-full"}
                                    placeholder={props.placeholder ? props.placeholder : ""}
                                    value={props.value ? props.value : ""}
                                    readOnly={props.readOnly ? true : false}
                                    onChange={props.onChange && props.onChange}
                                    onBlur={props.onBlur && props.onBlur}
                                    style={{ paddingTop :"9px", paddingBottom :"9px" }}
                                /> :
                                <input
                                    type={props.type ? props.type : "text"}
                                    className={props.className? `input-text ${props.className}` : "input-text input-full"}
                                    placeholder={props.placeholder ? props.placeholder : ""}
                                    value={props.value ? props.value : ""}
                                    readOnly={props.readOnly ? true : false}
                                    onChange={props.onChange && props.onChange}
                                    onBlur={props.onBlur && props.onBlur}
                                />}
                            {props.authClick && <button className="btn btn-line-orange btn-email" onClick={props.authClick} style={{ width : "140px", marginLeft : "10px"}}>{props.authName ? props.authName : "이메일 인증"}</button>}
                            {props.authCheck && <button className={`btn ${props.authBtnToggle ? "btn-full-orange" : "btn-gray"} btn-email`} onClick={props.authCheck} style={{ width : "140px",marginLeft : "10px" }}>인증</button>}
                        </div>
                        {props.children && props.children}
                    </div>
                </div>
            }
            {
                props.pageType === "N" &&
                <div className={'form-block'}>
                    <span className="block-title">
                        {props.title}
                        {props.alert && <p>{props.alert}</p>}
                    </span>
                    <div className="block-content">
                        {props.type === "password" ?
                            <inputPass.Password
                                type={props.type ? props.type : "text"}
                                className={props.className? `input-text ${props.className}` : "input-text input-full"}
                                placeholder={props.placeholder ? props.placeholder : ""}
                                value={props.value ? props.value : ""}
                                readOnly={props.readOnly ? true : false}
                                onChange={props.onChange && props.onChange}
                                onBlur={props.onBlur && props.onBlur}
                                style={{ paddingTop :"9px", paddingBottom :"9px" }}
                            /> :
                            <InputNumber
                                className={props.className? `input-text ${props.className}` : "input-text input-full"}
                                placeholder={props.placeholder ? props.placeholder : ""}
                                value={props.value ? props.value : ""}
                                readOnly={props.readOnly ? true : false}
                                onChange={props.onChange && props.onChange}
                                step={props.step ? props.step : 1000}
                                onBlur={props.onBlur && props.onBlur}
                            />
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default Input;
import Layout from "../../components/layouts/Layout";
import {addComma, dateRange, dateSorter, onlyNumber, stringSorter} from "../../utils";
import PageTitle from "../../components/pageTitle/PageTitle";
import {DatePicker, Modal, Select, Table, Tag} from "antd";
import translator from "../../utils/translator";
import {business} from "../../services/api";
import {useState, useEffect} from "react";
import {store} from "../../stores";
import EstimateBidModal from "../../components/modal/estimateBidModal";
import {isLogin} from "../../utils/auth";
import {useParams} from "react-router-dom";
import {getCode} from "../../utils/code";

const BusinessEstimateList = () => {
    const { id } = useParams();
    const initialData = {
        userId: "",
        pageNo: 1,
        limit: 9999,
        start: 0,
        searchKey: "",
        searchValue: "",
        dateType: "",
        fromDate: "",
        toDate: "",
    }
    const initialList = {
        items: [],
        limit: 10,
        pageNo: 1,
        totalCount: 0,
    }
    const selectOption = [
        { label: '견적일시', value: 'es_insert_dt' },
        { label: '견적마감일', value: 'es_deadline_dt' },
        { label: '회사명', value: 'es_company_name' },
        { label: '이름', value: 'es_user_name' },
        { label: '보험종목', value: 'es_insurance_type_code' },
        { label: '제목', value: 'es_title' },
        { label: '입찰보험료', value: 'bid_amount' },
        { label: '입찰제시일', value: 'bid_insert_dt' }
    ];
    const [width, setWidth] = useState(window.innerWidth);
    const [header, setHeader] = useState([]);
    const [data, setData] = useState(initialData);
    const [list, setList] = useState(initialList);
    const [onlyList,setOnlyList] = useState([])
    const [page, setPageNum] = useState(1);
    const [selected, setSelected] = useState(null);
    const [selectType, setSelectType] = useState("");
    const [user, setUser] = useState();

    useEffect(() => {
        let timeoutId;
        const handleResize = () => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                setWidth(window.innerWidth);
            }, 300)
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.clearTimeout(timeoutId);
        };
    }, []);

    const fetchData = async (data) => {
        const code = getCode();
        const result = await business.searchBusiness(data).then((res) => {
            const resultData = res.items.map((item) => {
                return {
                    ...item,
                    key: item.esCode,
                    esInsuranceTypeCode: code[item.esInsuranceTypeCode] || item.esInsuranceTypeCode,
                    bidStatusCode: code[item.bidStatusCode],
                    bidAmount: addComma(item.bidAmount),
                }
            })
            setOnlyList(resultData);
            return res;
        });
        setList(result);
    }

    useEffect(()=>{
        if(width < 1024){
            setHeader(["esDeadlineDt", "esTitle", "esInsuranceTypeCode", "bidStatusCode"]);
        }else{
            setHeader([
                "esInsertDt",
                "esDeadlineDt",
                "esCompanyName",
                "esUserName",
                "esInsuranceTypeCode",
                "esTitle",
                "bidAmount",
                "bidInsertDt",
                "bidStatusCode",
            ]);
        }
    }, [width]);

    useEffect(() => {
        checkPageAuth();
    }, [id]);

    const checkPageAuth = async () => {
        const auth = await isLogin();
        if (!auth) return;
        const userData = store.userStore.getUser();
        setUser(userData)
        searchList(page, userData.userId);
    }

    const searchList = (pageNo, userId) => {
        if (id === undefined) {
            let searchType;
            if (selectType === "es_insert_dt" || selectType === "es_deadline_dt" || selectType === "bid_insert_dt") {
                searchType = "dateType";
            } else {
                searchType = "searchKey";
            }
            const tempData = {
                ...data,
                userId,
                pageNo,
                fromDate: searchType === "searchKey" ? "" : data.fromDate,
                toDate: searchType === "searchKey" ? "" : data.toDate,
                dateType: searchType === "dateType" ? selectType : "",
                searchKey: searchType === "searchKey" ? selectType : "",
                searchValue: searchType === "searchKey" ? data.searchValue : "",
            }
            setData(tempData);
            setPageNum(pageNo);
            fetchData(tempData);
        } else {
            const tempData = {
                ...data,
                userId,
                pageNo: 1,
                searchKey : `bid_status_code`,
                searchValue : id,
            }
            setData(tempData);
            setPageNum(1);
            fetchData(tempData);
        }
    }

    const onClickSearch =() => {
        if (data.searchValue !== "" && selectType === "") {
            Modal.warning({
                title: '알림',
                content: '검색하실 키워드를 선택해주세요.',
            });
            return;
        }
        if (selectType === "es_insert_dt" || selectType === "es_deadline_dt" || selectType === "bid_insert_dt") {
            if (data.fromDate === "" || data.toDate === "") {
                Modal.warning({
                    title: '알림',
                    content: '시작 날짜과 끝 날짜를 입력해주세요.',
                });
                return;
            }
            if (!dateRange(data.fromDate, data.toDate)) {
                Modal.warning({
                    title: '알림',
                    content: '시작 날짜가 끝 날짜보다 클 수 없습니다.',
                });
                return;
            }
        }
        searchList(1, user.userId);
    }

    const onChangeSelect = (e) => {
        let value = e
        if (!e) {
            value = "";
        }
        setSelectType(value);
    };

    const onChangeFromDate = (date, dateString) => {
        setData({
            ...data,
            fromDate: dateString !== "" ? `${dateString} 00:00:00` : "",
        })
    };

    const onChangeToDate = (date, dateString) => {
        setData({
            ...data,
            toDate: dateString !== "" ? `${dateString} 23:59:59` : "",
        })
    };

    const columns = header.map((key) => {
        if(key === "esInsertDt" || key === "esDeadlineDt" || key === "bidInsertDt") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => dateSorter(a, b, key),
                align: 'center',
                width: "126px",
            }
        } else if (key === "esTitle"){
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
            }
        }
        else if (key === "bidAmount"){
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => {
                    return onlyNumber(a[key]) - onlyNumber(b[key])
                },
                align: 'center',
                width: "126px",
            }
        } else if (key === "bidStatusCode"){
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                width: "116px",
                render: (value) => {
                    let color;
                    let bordered = true;
                    if (value === "계약요청") {
                        color = 'green';
                    } else if (value === "계약확인") {
                        color = 'gold';
                    } else if (value === "계약완료") {
                        color = 'volcano';
                    } else if (value === "입찰요청") {
                        color = 'blue';
                    } else if (value === "입찰확인") {
                        color = 'purple';
                    } else if (value === "입찰완료") {
                        color = 'magenta';
                    } else if (value === "견적요청") {
                        color = 'purple';
                    } else if (value === "견적확인") {
                        color = 'red';
                    } else if (value === "미참여") {
                        color = 'geekblue';
                    } else if (value === "입찰종료") {
                        color = 'default';
                        bordered = false;
                    }
                    return (
                        <Tag color={color} key={value} bordered={bordered}>
                            {value}
                        </Tag>
                    );
                }
            }
        } else {
            return {
                title: translator[key],
                width: "112px",
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
            }
        }
    })

    return(
        <Layout>
            <div className="common-wrapper page-request-list">
                <PageTitle title="견적요청 리스트" style={id === undefined ? { marginBottom: "16px" } : null} pop={id === undefined ? false : true} />
                { id === undefined && <div className="filter-wrap-one">
                    <div className="filter-item">
                        <Select
                            allowClear
                            style={{width: 120}}
                            defaultValue={selectType}
                            onChange={onChangeSelect}
                            options={selectOption}
                        />
                        <div className="filter-content">
                            {selectType !== "es_insert_dt" && selectType !== "es_deadline_dt" && selectType !== "bid_insert_dt" &&
                                <input
                                    type="text"
                                    className="input-text input-small"
                                    onChange={(e) => setData({
                                        ...data,
                                        searchValue: e.target.value
                                    })}
                                    onKeyPress={e => {
                                        if (e.key.toLowerCase() === 'enter') {
                                            onClickSearch();
                                        }
                                    }}
                                />
                            }
                            {selectType === "es_insert_dt" || selectType === "es_deadline_dt" || selectType === "bid_insert_dt" ?
                                <span>
                                    <DatePicker
                                        onChange={onChangeFromDate}
                                        placeholder=""
                                        style={{width: "120px"}}
                                    /> ~ <DatePicker
                                    onChange={onChangeToDate}
                                    placeholder=""
                                    style={{width: "120px"}}
                                    />
                                </span>
                                : <></>
                            }
                            <button className="btn btn-small btn-orange btn-filter" onClick={onClickSearch}>검색</button>
                        </div>
                    </div>
                </div>}
                <div className="table-wrapper estimate-bidding-table">
                    <Table
                        className="inner-wrapper"
                        dataSource={onlyList}
                        columns={columns}
                        style={{ cursor: "pointer" }}
                        showSorterTooltip={false}
                        onRow={(record) => {
                            if (record.bidStatusCode === "입찰종료") return;
                            return {
                                onClick: () => {
                                    setSelected(record);
                                },
                            };
                        }}
                        rowClassName={record => record.bidStatusCode === "입찰종료" && "disabled-row"}
                        pagination={{
                            current: page,
                            onChange: (page) => setPageNum(page),
                            pageSize: 10,
                            total: list.totalCount,
                            position: ["bottomCenter"],
                            showSizeChanger: false,
                        }}
                    />
                </div>
            </div>
            {selected &&
                <EstimateBidModal
                    selectData={selected}
                    bidId={selected.bidCode}
                    onClose={() => {
                        setSelected(null);
                        searchList(1, user.userId)
                    }}
                />
            }
        </Layout>
    )
}

export default BusinessEstimateList;
import React, { useRef } from "react";

const FileSelector = ({id, theme, className, buttonClassName, buttonStyle, onChange, buttonText}) => {
    const input = useRef(null);
    
    return (
        <div className={`file-selector ${className}`}>
            <input id={id} type="file" ref={input} className="only-sr" onChange={e => {
                if (typeof onChange === 'function') {
                    onChange(e);
                }
            }} />
            <label htmlFor={id} className={`btn btn-${theme ? theme : "gray"} ${buttonClassName}`} style={buttonStyle}>{buttonText ? buttonText : "찾아보기"}</label>
        </div>
    );
};

export default FileSelector;
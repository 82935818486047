import Loading from "../components/loading/loading";
import {useEffect} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {Modal} from "antd";
import {auth, user as userApi} from "../services/api";
import {store} from "../stores";
import {isLogin, setGroupId, setToken} from "../utils/auth";
import {observer} from "mobx-react";

const KakaoTest = observer(() => {
    const navigate = useNavigate();
    let code = new URL(window.location.href).searchParams.get('code');

    useEffect(() => {
        kakaoLogin();
    }, [])

    const kakaoLogin = async () => {
        // 1. 인가 코드 받아서 엑세스 토큰 받기
        // 2. 엑세스 토큰 사용하여 카카오 유저 정보 받아오기
        // 3. 유저 정보를 api로 받아서 이봐코리아에서 사용하는 토큰 생성해주기 (받아야할 정보: 그룹아이디, 유저이메일, 토큰)
        // 4. store에 유저 정보 저장, 토큰 저장, 페이지 이동
        try {
            const res = await axios({
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                },
                url: 'https://kauth.kakao.com/oauth/token',
                data: makeFormData({
                    grant_type: 'authorization_code',
                    client_id: process.env.REACT_APP_KAKAO_CLIENT_ID,
                    redirect_uri: process.env.REACT_APP_KAKAO_REDIRECT_URI,
                    code // 인가 코드
                })
            })
            getUserInfo(res.data.access_token);
        } catch (e) {
            Modal.error({
                title: "알림",
                content: "로그인 실패, 다시 로그인 해주세요."
            })
            navigate("/login")
        }
    }

    const makeFormData = (params) => {
        const searchParams = new URLSearchParams()
        Object.keys(params).forEach(key => {
            searchParams.append(key, params[key])
        })
        return searchParams
    }

    const getUserInfo = async (accessToken) => {
        try {
            const res = await axios({
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${accessToken}` // 카카오 토큰 api로 얻은 accesstoken 보내기
                },
                url: "https://kapi.kakao.com/v2/user/me",
            })
            await auth.kakaoUserInfo(res.data).then(async (res)=>{
                switch (res.commonRespCode) {
                    case "1": // 정상 로그인 및 kakao_user_info 데이터 있음
                        store.userStore.setUser(res.data[0]);
                        setToken(res.data[0].token);
                        setGroupId(res.data[0].groupId);
                        navigate("/");
                        break;
                    case "2": //회원가입 진행
                        await isLogin();
                        const user = store.userStore.getUser();
                        if (user?.userId === "" || user?.userId === undefined || user?.userId === null) {
                            navigate("/sns/join", {state: {data: res.data[0], platform: "kakao"}});
                        } else {
                            const newData = {
                                userId: user.userId,
                                id: res.data[0].id,
                                platform: res.data[0].platform,
                            }
                            await userApi.AddUserSnsLogin(newData).then((res) => {
                                if (res) {
                                    if(res.commonRespCode === "1") {
                                        navigate("/")
                                        Modal.success({
                                            title: '알림',
                                            content: '연동되었습니다.',
                                        });
                                    } else {
                                        Modal.error({
                                            title: '알림',
                                            content: res.message,
                                        });
                                    }
                                } else {
                                    Modal.error({
                                        title: '알림',
                                        content: '오류가 발생하였습니다.',
                                    });
                                }
                            })
                        }
                        break;
                    default: // 0 혹은 다른값
                        Modal.error({
                            title: "알림",
                            content: "로그인 실패, 다시 로그인 해주세요."
                        })
                        navigate("/login")
                        break;
                }
            })
        } catch (e) {
            Modal.error({
                title: "알림",
                content: "로그인 실패, 다시 로그인 해주세요."
            })
            navigate("/login")
        }
    }

    return (
        <Loading loading={true} />
    )
})

export default KakaoTest;
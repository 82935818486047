import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react';
import FileSelector from '../fileSelector/FileSelector';
import userStore from '../../stores/userStore';
import ModalComponent from "./Modal";
import {addComma, autoHypen, leaveOnlyNumber} from "../../utils";
import {business} from "../../services/api";
import ReadOnlyInput from "../input/ReadOnlyInput";
import {FileInput} from "../input/FileInput";
import {store} from "../../stores";
import {Modal} from "antd";
import translator from "../../utils/translator";
import Loading from "../loading/loading";

const EstimateBidModal = observer(({selectData, bidId, onClose}) => {
    const initialBidding = {
        bidCode: "",
        esCode: "",
        bidAmount: "",
        userId: "",
        companyName: "",
        userPhone: "",
        bidStatusCode: "",
        insertDt: "",
        updateDt: "",
        bidComments: "",
        applicationAttName: "",
        applicationAttId: 0,
        productAttName: "",
        productAttId: 0,
        insuranceAttName: "",
        insuranceAttId: 0,
        souscriptionAttName: "",
        souscriptionAttId: 0,
        applicationAttPath: "",
        productAttPath: "",
        insuranceAttPath: "",
        souscriptionAttPath: "",
        dealDt: "",
        souscriptionDt: "",
    }
    const [loading, setLoading] = useState(false);
    const allowFileList = store.allowFileListStore.getAllowFileList();
    const user = userStore.getUser();
    const [bidding, setBidding] = useState(initialBidding);
    const [estimateData, setEstimate] = useState(null);
    const [price, setPrice] = useState('');
    const [comment, setComment] = useState('');
    const [state, setState] = useState('');
    const [notEnter, setNotEnter] = useState('');
    const [file, setFile] = useState({
        applicationAtt: null,
        productAtt: null,
        insuranceAtt: null
    });

    useEffect(() => {
        if (selectData?.bidStatusCode) {
            fetchState();
        }
        fetchData();
    }, []);

    const fetchState = () => {
        if (selectData.bidStatusCode === "미참여") {
            setState("미참여");
        } else if (selectData.bidStatusCode === "계약요청" || selectData.bidStatusCode === "계약확인" || selectData.bidStatusCode === "계약완료" ) {
            setState("계약");
        } else {
            setState("진행");
        }
    }

    const fetchData = async () => {
        await business.searchBusinessEstimate(selectData?.esCode).then(async (res) => {
            setEstimate(res);
        })
        if (!bidId) return;
        await business.searchBusinessBids(bidId).then(async (res) => {
            setPrice(res.bidAmount);
            setComment(res.bidComments);
            setBidding(res);
        })
    }

    const uploadFile = async (e, target) => {
        if(!e.target.files[0]) return;
        //이미지 파일만 받기
        let _lastDot = e.target.files[0].name.lastIndexOf('.');
        let _fileExt = e.target.files[0].name.substring(_lastDot+1, e.target.files[0].name.length);
        if(!allowFileList.includes(_fileExt)){
            Modal.warning({
                title: '알림',
                content: '이미지, PDF, ZIP 파일만 업로드 가능합니다.',
                zIndex: 10000,
            });
            return;
        }
        //이미지 사이즈 확인
        if((e.target.files[0].size/1024/1024).toFixed(2) > 10){
            Modal.warning({
                title: '알림',
                zIndex: 10000,
                content: (
                    <div>
                        <p>해당 파일은 {(e.target.files[0].size / 1024 / 1024).toFixed(2)}MB 입니다.</p>
                        <p>10MB 미만의 파일만 업로드 가능합니다.</p>
                    </div>
                )
            });
            return;
        }
        const previewUrl = window.URL.createObjectURL(e.target.files[0])
        switch (target) {
            case 'application':
                setFile((prev) => {
                    return ({
                        ...prev,
                        applicationAtt: e.target.files[0],
                        appPreviewUrl: previewUrl
                    })
                })
                break;
            case 'product':
                setFile((prev) => {
                    return ({
                        ...prev,
                        productAtt: e.target.files[0],
                        proPreviewUrl: previewUrl
                    })
                })
                break;
            case 'insurance':
                setFile((prev) => {
                    return ({
                        ...prev,
                        insuranceAtt: e.target.files[0],
                        insPreviewUrl: previewUrl
                    })
                })
                break;
            default:
                break;
        }
    }

    const onPostUpdateBid = () => {
        if (price === "") {
            setNotEnter("보험료");
            Modal.warning({
                title: '알림',
                content: '보험료를 입력해주세요.',
                zIndex: 10000,
            });
            setLoading(false);
            return;
        }
        if (!bidding?.bidCode) {
            onPostBid();
        } else {
            onUpdateBid();
        }
    }

    const onPostBid = () => {
        const data = {
            esCode: estimateData.esCode,
            bidCode: "",
            userId: user.userId,
            insertDt: "",
            companyName: user.companyName,
            amount: price,
            userPhone: user.userPhone,
            bidComments: comment,
            applicationAtt: file.applicationAtt,
            productAtt: file.productAtt,
            insuranceAtt: file.insuranceAtt,
        }
        if (data.applicationAtt === null) {
            Modal.warning({
                title: '알림',
                content: '청약서를 첨부해주세요.',
                zIndex: 10000,
            });
            return;
        }
        Modal.confirm({
            title: '확인',
            content: `${translator["bid"]}하시겠습니까?`,
            zIndex: 10000,
            onOk: async () => {
                setLoading(true);
                business.biddingEstimate(data).then(res => {
                    if (res) {
                        setLoading(false);
                        if(res.commonRespCode === "success") {
                            Modal.success({
                                title: '알림',
                                zIndex: 10000,
                                content: res.message,
                            });
                            onClose();
                        } else {
                            Modal.error({
                                title: '알림',
                                zIndex: 10000,
                                content: res.message,
                            });
                        }
                    } else {
                        Modal.error({
                            title: '알림',
                            content: '오류가 발생하였습니다.',
                        });
                    }
                })
            }
        });
    }

    const onUpdateBid = () => {
        const data = {
            esCode: estimateData.esCode,
            bidCode: bidding.bidCode,
            amount: price,
            bidComments: comment,
        }
        Modal.confirm({
            title: '확인',
            content: '수정하시겠습니까?',
            zIndex: 10000,
            onOk: async () => {
                business.updateBidding(data).then(res => {
                    if (res) {
                        if(res.commonRespCode === "1") {
                            Modal.success({
                                title: '알림',
                                zIndex: 10000,
                                content: '수정되었습니다.',
                            });
                            onClose();
                        } else {
                            Modal.error({
                                title: '알림',
                                zIndex: 10000,
                                content: '수정 실패',
                            });
                        }
                    } else {
                        Modal.error({
                            title: '알림',
                            content: '오류가 발생하였습니다.',
                        });
                    }
                })
            }
        });
    }

    const onDeleteBid = () => {
        Modal.confirm({
            title: '확인',
            content: '삭제하시겠습니까?',
            zIndex: 10000,
            onOk: async () => {
                await business.deleteBidding(bidding.bidCode).then(res => {
                    if (res) {
                        if(res.commonRespCode === "1") {
                            Modal.success({
                                title: '알림',
                                content: '삭제되었습니다.',
                                zIndex: 10000,
                            });
                            onClose();
                        } else {
                            Modal.error({
                                title: '알림',
                                content: '삭제 실패',
                                zIndex: 10000,
                            });
                        }
                    } else {
                        Modal.error({
                            title: '알림',
                            content: '오류가 발생하였습니다.',
                        });
                    }
                })
            }
        });
    }

    const onClickImg = (type) => {
        let _width = '700';
        let _height = '1000';
        let _left = Math.ceil(( window.screen.width - _width )/2);
        let _top = Math.ceil(( window.screen.height - _height )/2);
        let path = ""
        switch (type) {
            case 'application':
                if(bidding?.applicationAttPath) {
                    path = bidding?.applicationAttPath
                } else {
                    path = file.appPreviewUrl
                }
                break;
            case 'product':
                if(bidding?.productAttPath) {
                    path = bidding?.productAttPath
                } else {
                    path = file.proPreviewUrl
                }
                break;
            case 'insurance':
                if(bidding?.insuranceAttPath) {
                    path = bidding?.applicationAttPath
                } else {
                    path = file.insPreviewUrl
                }
                break;
            default:
                break;
        }
        window.open(path, '_blank', `width = ${_width}, height = ${_height}, left = ${_left}, top = ${_top}`)
    }

    return (
        <div>
            <Loading loading={loading ? loading : false} />
            <ModalComponent
                isShow={true}
                title={state === "계약" ? "계약 내용" : `${translator["bid"]} 내용 입력`}
                width={1080}
                className="estimate-modal-wrap"
                onClose={onClose}
                contentComponent={<div>
                    {(estimateData && user) &&
                        <div className="modal-form-wrap">
                            <div className="common-form-wrap small">
                                <ReadOnlyInput title="문서 ID" value={estimateData.esCode} />
                                <ReadOnlyInput title="견적 신청인 회사명" value={estimateData.esCompany} />
                                <ReadOnlyInput title="제목" value={estimateData.esTitle} />
                                <FileInput
                                    title = "사업자등록증"
                                    value = {estimateData.businessLicenseAttName}
                                    path = {estimateData.businessLicenseAttPath}
                                />
                                <FileInput
                                    title = "갱신증권"
                                    value = {estimateData.policyAttName}
                                    path = {estimateData.policyAttPath}
                                />
                                <ReadOnlyInput title="3개년 사고사항" value={estimateData.esAccident} />
                                <ReadOnlyInput title={`${translator["bid"]} 마감일`} value={estimateData.deadlineDt} />
                                <ReadOnlyInput title="요청사항" value={estimateData.esComments} />
                            </div>
                            <div className="common-form-wrap small">
                                <ReadOnlyInput title="견적 ID" value={bidding?.bidCode} />
                                <div className="form-block only-string">
                                    <span className="block-title">
                                        {translator["bid"]}일시
                                    </span>
                                    <div className="block-content">
                                        {bidding?.bidCode
                                            ? <p className="content-string">{bidding?.insertDt}</p>
                                            : <p className="content-string">-</p>
                                        }
                                    </div>
                                </div>
                                <ReadOnlyInput title="보험사" value={user.companyName} />
                                {state === "계약" ? <ReadOnlyInput title="보험료" value={addComma(price)}/> :
                                    <div className="form-block only-string">
                                    <span className="block-title" style={{display: "flex"}}>
                                        보험료
                                        {state !== "계약" && <p style={{marginLeft: "4px"}}>*</p>}
                                    </span>
                                        <div className="block-content">
                                            <input
                                                type="text"
                                                className={`input-text input-small input-full ${notEnter === "보험료" && "input-red"}`}
                                                value={addComma(price)}
                                                onChange={e => {
                                                    setPrice(leaveOnlyNumber(e.target.value))
                                                }}
                                                placeholder="제시할 보험료 입력"
                                            />
                                        </div>
                                    </div>
                                }
                                <ReadOnlyInput title="보험 담당자 휴대전화" value={autoHypen(user.userPhone)} />
                                {state === "계약" ? <ReadOnlyInput title="보험사 코멘트" value={comment}/> :
                                    <div className="form-block only-string">
                                        <span className="block-title">
                                            보험사 코멘트
                                        </span>
                                        <div className="block-content">
                                            <textarea
                                                className="textarea"
                                                style={{padding: 8, height: 50, fontSize: 14}}
                                                value={comment}
                                                onChange={e => setComment(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="form-block">
                                    <span className="block-title" style={{ display: "flex" }}>
                                        청약서
                                        {state !== "계약" && <p style={{marginLeft: "4px"}}>*</p>}
                                    </span>
                                    <div className="block-content">
                                        <div className="content-other">
                                            {bidding?.applicationAttPath || file?.appPreviewUrl
                                                ? <p className="file-content" onClick={() =>onClickImg('application')}>{bidding?.applicationAttName || file.applicationAtt?.name}</p>
                                                : <p className="normal-content">* 등록된 문서가 없습니다.</p>
                                            }
                                            <div className="file-controll-wrap">
                                                {state === "미참여" &&
                                                    <FileSelector id="f1" buttonText="업로드" buttonClassName="btn-small"  onChange={e => uploadFile(e, 'application')}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-block">
                                    <span className="block-title" style={{ display: "flex" }}>
                                        상품설명서
                                    </span>
                                    <div className="block-content">
                                        <div className="content-other">
                                            {bidding?.productAttPath || file?.proPreviewUrl
                                                ? <p className="file-content" onClick={() =>onClickImg('product')}>{bidding?.productAttName || file.productAtt?.name}</p>
                                                : <p className="normal-content">* 등록된 상품설명서가 없습니다.</p>
                                            }
                                            <div className="file-controll-wrap">
                                                {state === "미참여" &&
                                                    <FileSelector id="f2" buttonText="업로드" buttonClassName="btn-small"  onChange={e => uploadFile(e, 'product')}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-block">
                                    <span className="block-title" style={{ display: "flex" }}>
                                        약관
                                    </span>
                                    <div className="block-content">
                                        <div className="content-other">
                                            {bidding?.insuranceAttPath || file?.insPreviewUrl
                                                ? <p className="file-content" onClick={() =>onClickImg('insurance')}>{bidding?.insuranceAttName || file.insuranceAtt?.name}</p>
                                                : <p className="normal-content">* 등록된 약관이 없습니다.</p>
                                            }
                                            <div className="file-controll-wrap">
                                                {state === "미참여" &&
                                                    <FileSelector id="f3" buttonText="업로드" buttonClassName="btn-small"  onChange={e => uploadFile(e, 'insurance')}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { selectData.bidStatusCode === "계약완료" &&
                                <div className="form-block">
                                    <span className="block-title" style={{ display: "flex" }}>
                                        계약서
                                        {state !== "계약" && <p style={{marginLeft: "4px"}}>*</p>}
                                    </span>
                                    <div className="block-content">
                                        <div className="content-other">
                                            {bidding?.souscriptionAttPath || file?.insPreviewUrl
                                                ? <p className="file-content" onClick={() =>onClickImg('insurance')}>{bidding?.souscriptionAttName || file.insuranceAtt?.name}</p>
                                                : <p className="normal-content">* 등록된 계약서가 없습니다.</p>
                                            }

                                        </div>
                                    </div>
                                </div>
                                }
                                {   selectData.bidStatusCode === "계약완료" &&
                                    <div className="form-block only-string">
                                    <span className="block-title">
                                        계약일
                                    </span>
                                        <div className="block-content">
                                            {bidding?.bidCode
                                                ? <p className="content-string">{bidding?.updateDt}</p>
                                                : <p className="content-string">-</p>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    {state !== "계약" &&
                        <div className="terms-agree-wrap" style={{ marginTop: "20px" }}>
                            <div className="c-label"><span>보험료</span>, <span>청약서</span>은 필수 입력 항목입니다.</div>
                        </div>
                    }
                </div>}
                footerComponent={
                    <>
                        {state !== "계약" &&
                            <button className="btn btn-orange" onClick={onPostUpdateBid}>
                                {state === "미참여" ? `${translator["bid"]}하기` : "수정하기"}
                            </button>
                        }
                        {state === "진행" && <button className="btn btn-line-orange" onClick={onDeleteBid}>삭제</button>}
                    </>
                }
            />
        </div>
    );
});

export default EstimateBidModal;
import useScreen from "../hooks/useScreen";
import { tailspin } from 'ldrs'

tailspin.register()

const Loading = ({ loading }) => {
    const { screenSize } = useScreen();
    let _left = Math.ceil(( screenSize.width - 80 )/2);
    let _top = Math.ceil(( screenSize.height - 80 )/2);

    return (
        <>
        { loading &&
            <>
                <div className="modal-overlay" />
                <div style={{ width: "100px", position: "fixed", zIndex: 10002, top: `${_top}px`, left: `${_left}px` }}>
                    <l-tailspin
                        size="60"
                        stroke="8"
                        speed="0.9"
                        color="#ff6000"
                    />
                </div>
            </>
        }
        </>
    )
}

export default Loading;
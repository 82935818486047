import AdminLayout from "../../../components/layouts/AdminLayout";
import {insurance} from "../../../services/api";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import translator from "../../../utils/translator";
import {stringSorter} from "../../../utils";
import {DatePicker, Modal, Select, Table} from "antd";
import {store} from "../../../stores";
import {getCode} from "../../../utils/code";

const InsuranceProducts = () => {
    const user = store.userStore.getUser();
    const initialData = {
        userId: user?.userId || "",
        pageNo: 1,
        limit: 10,
        start: 0,
        searchKey: "",
        searchValue: ""
    }
    const selectOption = [
        { label: '대분류', value: 'insurance_type_code' },
        { label: '보험번호', value: 'insurance_code' },
        { label: '보험명', value: 'code_name' },
        { label: '수정날짜', value: 'update_dt' },
    ];
    const [width, setWidth] = useState(window.innerWidth);
    const [header, setHeader] = useState([]);
    const [data, setData] = useState(initialData);
    const [list, setList] = useState([]);
    const [page, setPageNum] = useState(1);
    const [selectType, setSelectType] = useState("");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const navigate = useNavigate();
    const [onlyList,setOnlyList] = useState([]);

    useEffect(() => {
        let timeoutId;
        const handleResize = () => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                setWidth(window.innerWidth);
            }, 300)
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.clearTimeout(timeoutId);
        };
    }, []);

    useEffect(()=>{
        if(width < 1024){
            setHeader(["key", "insuranceName", "useYn"]);
        }else{
            setHeader(["insuranceTypeCode", "key", "insuranceName", "updateDt", "useYn"]);
        }
    }, [width]);

    useEffect(() => {
        setSelectedRowKeys([]);
        searchList(page);
    }, [page])

    const searchList = (pageNo) => {
        const tempData = {
            ...data,
            pageNo,
            searchKey: selectType && selectType !== "" ? selectType : "",
        }
        setData(tempData);
        setPageNum(pageNo);
        fetchData(tempData);
    }

    const fetchData = async (data) => {
        const code = getCode();
        const result = await insurance.searchInsuranceProducts(data).then((res) => {
            const resultData = res.items.map((item) => {
                return {
                    ...item,
                    key: item.insuranceCode,
                    insuranceName: code[item.insuranceCode]
                }
            })
            setOnlyList(resultData);
            return res;
        });
        setList(result);
    }

    const deleteData = async (list) => {
        await insurance.deleteInsuranceProducts(list).then((res)=>{
            if(res.commonRespCode === "1"){
                setPageNum(1);
                searchList(page);
                Modal.success({
                    title: '알림',
                    content: '삭제되었습니다.',
                });
            }else{
                Modal.error({
                    title: '알림',
                    content: res.message,
                });
            }
        });
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };
    
    const onDeleteInsurance = () => {
        if (selectedRowKeys.length === 0) {
            Modal.warning({
                title: '알림',
                content: '선택된 항목이 없습니다.',
            });
            return;
        }
        Modal.confirm({
            title: '확인',
            content: '삭제하시겠습니까?',
            onOk: () => {
                deleteData(selectedRowKeys);
                setSelectedRowKeys([]);
                searchList(1);
            }
        });
    }

    const onClickSearch = () => {
        if (data.searchValue !== "" && selectType === "") {
            Modal.warning({
                title: '알림',
                content: '검색하실 키워드를 선택해주세요.',
            });
            return;
        }
        searchList(1)
    }

    const onChangeSelect = (e) => {
        let value = e;
        if (!e) {
            value = "";
        }
        setSelectType(value);
    };

    const onChangeDate = (date, dateString) => {
        setData({
            ...data,
            searchValue: dateString,
        })
    };

    const columns = header.map((key) => {
        if (key === "useYn") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                width: '90px',
                render: (value) => {
                    return value === "Y" ? "사용" : "미사용"
                }
            }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
            }
        }

    });

    return (
        <AdminLayout title="기업 보험 상품 관리">
            <div className="filter-wrap">
                <div className="filter-item">
                    <Select
                        allowClear
                        style={{ width: 120 }}
                        defaultValue={selectType}
                        onChange={onChangeSelect}
                        options={selectOption}
                    />
                    <div className="filter-content">
                        {selectType !== "update_dt" &&
                            <input
                                type="text"
                                className="input-text input-small"
                                onChange={(e) => setData({
                                    ...data,
                                    searchValue: e.target.value
                                })}
                                onKeyPress={e => {
                                    if (e.key.toLowerCase() === 'enter') {
                                        onClickSearch();
                                    }
                                }}
                            />
                        }
                        { selectType === "update_dt" ?
                            <DatePicker
                                onChange={onChangeDate}
                                placeholder=""
                                style={{ width: "196px"}}
                            /> : <></>
                        }
                        <button className="btn btn-small btn-orange btn-filter" onClick={onClickSearch}>검색</button>
                    </div>
                </div>
                <div className="filter-btn-item">
                    <button className="btn btn-small btn-line-orange" onClick={() => navigate(`/admin/insurance/add`)}>추가</button>
                    <button className="btn btn-small btn-orange" onClick={onDeleteInsurance}>삭제</button>
                </div>
            </div>
            <Table
                dataSource={onlyList}
                columns={columns}
                style={{ cursor: "pointer" }}
                pagination={{
                    current: page,
                    onChange: (page) => setPageNum(page),
                    pageSize: 10,
                    total: list.totalCount,
                    position: ["bottomCenter"],
                    showSizeChanger: false,
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            navigate(`/admin/insurance/detail/${record.insuranceCode}`);
                        },
                    };
                }}
                rowSelection={rowSelection}
            />
        </AdminLayout>
    )
}

export default InsuranceProducts;
import axios from "axios";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";


export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        User_id: Cookies.get('token') ? jwtDecode(Cookies.get('token')).userId : "",
    },
    timeout: 10000,
});
axiosInstance.interceptors.request.use(
    function (response) {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use((response)=>{
    return response;
}, (error)=>{
    if(error.code === "ECONNABORTED"){
        //TODO 404페이지 연결
        window.location.href = "/err?code="+error.code;
    }
    if(error.code === "ERR_NETWORK" && window.location.pathname !== "/err"){
        window.location.href = "/err?code="+error.code;
    }
    return Promise.reject(error);
});
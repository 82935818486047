import {makeAutoObservable} from "mobx";

// edit 부분이나 상세 페이지로 이동할 때 사용
class AdminEstimate {
    userData = {};
    constructor() {
        makeAutoObservable(this);
    }
    setAdminEstimate = (data) => {
        this.userData = data;
    }
    getAdminEstimate = () => {
        return this.userData;
    }
    deleteAdminEstimate = () => {
        this.userData = {};
    }
}

const AdminEstimateStore = new AdminEstimate();
export default AdminEstimateStore;
import {Modal, Select, Table} from "antd";
import AdminLayout from "../../../components/layouts/AdminLayout";
import {useNavigate} from "react-router-dom";
import translator from "../../../utils/translator";
import {companyFetch, stringSorter} from "../../../utils";
import React, { useState, useEffect } from "react";
import {auth} from "../../../services/api";
import {store} from "../../../stores";
import {observer} from "mobx-react";
import CustomerEditModal from "../customer/editModal";

const AdminUser = observer(() => {
    const initialData = {
        userId: "",
        pageNo: 1,
        limit: 9999,
        start: 0,
        searchKey: "",
        searchValue: "",
        fromDate: "",
        toDate: ""
    }
    const initialList = {
        items: [],
        limit: 10,
        pageNo: 1,
        totalCount: 0,
    }
    const selectOption = [
        { label: '이메일', value: 'user_id' },
        { label: '보험사명', value: 'company_name' },
        { label: '고객명', value: 'user_name' },
        { label: '연락처', value: 'user_phone' }
    ];
    const [iniData, setIniData] = useState(initialData);
    const [list, setList] = useState(initialList);
    const [onlyList,setOnlyList] = useState([])
    const [page, setPageNum] = useState(1);
    const [selectType, setSelectType] = useState("");
    const [selected, setSelected] = useState(null);
    const [companyList, setCompanyList] = useState();
    const navigate = useNavigate();

    const fetchData = async (data) => {
        const result = await auth.searchInsurance(data).then((res) => {
            const resultData = res.items.map((item) => {
                return {
                    ...item,
                    bidCompany: item.companyName,
                    userNm: item.userName,
                    key: item.userId
                }
            });
            setOnlyList(resultData);
            return res;
        });
        setList(result);
    }

    const fetchCompany = async () => {
        const comList = await companyFetch();
        setCompanyList(comList);
    }

    useEffect(() => {
        searchList(page);
    }, [])

    const searchList = (pageNo) => {
        const data = {
            ...iniData,
            pageNo,
            searchKey: selectType && selectType !== "" ? selectType : "",
        }
        setIniData(data);
        setPageNum(pageNo);
        fetchData(data);
        fetchCompany();
    }

    const onClickSearch = () => {
        if (iniData.searchValue !== "" && selectType === "") {
            Modal.warning({
                title: '알림',
                content: '검색하실 키워드를 선택해주세요.',
            });
            return;
        }
        searchList(1);
    }

    const onChangeSelect = (e) => {
        let value = e
        if (!e) {
            value = "";
        }
        setSelectType(value);
    };

    const onAddInsurance = () => {
        store.adminCustomerStore.deleteAdminCustomer();
        navigate("/admin/add/edit");
    }

    const onPageChange = (data) => {
        localStorage.setItem('selectMenu', 'estimate_bidding');
        store.menuStore.setSelectMenu(localStorage.getItem('selectMenu'));
        navigate("/admin/bidding/list", { state : {email: data.userId} });
    }

    const header = [
        "userId",
        "companyName",
        "userName",
        "userPhone",
        "biddingCount",
    ];

    const columns = header.map((key) => {
        if(key === "companyName") {
            return {
                title: "보험사명",
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                width: 200,
                render: (v, d) => {
                    return (
                        <div onClick={() => setSelected(d)}>{v}</div>
                    );
                }
            }
        } else if(key === "biddingCount") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
                width: 100,
                render: (v, d) => {
                    return (
                        <div onClick={() => onPageChange(d)}>{v}</div>
                    );
                }
            }
        } else if(key === "userName" || key=== "userPhone"){
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                width: 200,
                render: (v, d) => {
                    return (
                        <div onClick={() => setSelected(d)}>{v}</div>
                    );
                }
            }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                render: (v, d) => {
                    return (
                        <div onClick={() => setSelected(d)}>{v}</div>
                    );
                }
            }
        }
    });

    return(
        <AdminLayout title="보험사 회원 관리">
            <div className="filter-wrap">
                <div className="filter-btn-item">
                    <button className="btn btn-small btn-line-orange" onClick={onAddInsurance}>보험사 회원 추가</button>
                </div>
                <div className="filter-item">
                    <Select
                        allowClear
                        style={{ width: 120 }}
                        defaultValue={selectType}
                        onChange={onChangeSelect}
                        options={selectOption}
                    />
                    <div className="filter-content">
                        <input
                            type="text"
                            className="input-text input-small"
                            onChange={(e) => setIniData({
                                ...iniData,
                                searchValue: e.target.value
                            })}
                            onKeyPress={e => {
                                if(e.key.toLowerCase() === 'enter') {
                                   onClickSearch();
                                }
                            }}
                        />
                        <button className="btn btn-small btn-orange btn-filter" onClick={onClickSearch}>검색</button>
                    </div>
                </div>
            </div>
            <Table
                dataSource={onlyList}
                columns={columns}
                style={{ cursor: "pointer" }}
                onRow={(record) => {
                    return {
                        onClick: () => setSelected(record)
                    };
                }}
                pagination={{
                    current: page,
                    onChange: (page) => setPageNum(page),
                    pageSize: 10,
                    total: list.totalCount,
                    position: ["bottomCenter"],
                    showSizeChanger: false,
                }}
                className={"adminTable"}
            />
            {selected &&
                <CustomerEditModal
                    selectData={selected}
                    statusId="user"
                    company={companyList}
                    onClose={() => {
                        setSelected(null);
                        searchList(1);
                    }}
                />
            }
        </AdminLayout>
    )
})

export default AdminUser;

import Input from "../../../components/input/Input";
import {useEffect, useState} from "react";
import {auth, user} from "../../../services/api";
import {observer} from "mobx-react";
import ModalComponent from "../../../components/modal/Modal";
import {Modal, Radio, Select} from "antd";
import ReadOnlyInput from "../../../components/input/ReadOnlyInput";
import {autoHypen, checkEmail} from "../../../utils";

const CustomerEditModal = observer(({selectData, statusId, onClose, company}) => {
    const initialData = {
        userId: "",
        beforeId: "",
        companyName: "",
        userName: "",
        userPhone: "",
        estimateCount: 0,
        useYn: ""
    }
    const [list, setList] = useState(initialData);
    const [title, setTitle] = useState("");
    const [btnName, setBtnName] = useState("수정하기");
    const [notEnter, setNotEnter] = useState("");
    const [checkValue, setCheckValue] = useState("");
    const [beforeUserId, setBeforeUserId] = useState("");
    const [emailCheck, setEmailCheck] = useState(false);

    const onChangeCheckBox = (e) => {
        setCheckValue(e.target.value);
        list.useYn = e.target.value;
    }
    useEffect(() => {
        if (selectData !== {}) {
            setList({
                userId: selectData.userId,
                companyName: selectData.companyName,
                userName: selectData.userName,
                userPhone: selectData.userPhone,
                useYn: selectData.useYn
            });
            setCheckValue(selectData.useYn);
            setBeforeUserId(selectData.userId);
        }
        if (statusId === "customer") {
            if (selectData.userStatusCode === "US01") {
                setTitle("정회원 전환");
                setBtnName("전환하기");
            } else {
                setTitle("일반 회원 정보 수정");
                setBtnName("수정하기");
            }
        } else if (statusId === "user") {
            setTitle("보험사 회원 정보 수정");
            setBtnName("수정하기");
        } else if (statusId === "info") {
            setTitle("회원 정보");
        }
    }, [])

    const mappingField = {
        "companyName": "회사명",
        "userName": "이름",
        "userPhone": "연락처"
    }

    const onEditCustomer = () => {
        let emptyValue = [];
        if (emailCheck) {
            setNotEnter("이메일");
            Modal.warning({
                title: '알림',
                content: '이미 사용중인 이메일입니다.',
                zIndex: 10000,
            });
            return;
        }
        if (list.userId !== "" && !checkEmail(list.userId)) {
            setNotEnter("이메일");
            Modal.warning({
                title: '알림',
                content: '올바른 이메일 주소가 아닙니다.',
                zIndex: 10000,
            });
            return;
        }
        Object.keys(mappingField).forEach((key) => {
            if (list[key] === "" || list[key] === null || list[key] === undefined ) {
                emptyValue.push(mappingField[key]);
            }
        })
        if (emptyValue.length > 0) {
            setNotEnter(emptyValue[0]);
            Modal.warning({
                title: '알림',
                content: `${emptyValue[0]} 값을 입력해주세요.`,
                zIndex: 10000,
            });
            return;
        }
        const data = {
            ...list,
            userPhone: list.userPhone.replace(/-/g, ''),
            beforeId: beforeUserId,
        }
        if (selectData.userStatusCode === "US01") {
            Modal.confirm({
                title: '확인',
                content: '전환하시겠습니까?',
                zIndex: 10000,
                onOk: () => {
                    onChangeUser(data);
                }
            });
        } else {
            Modal.confirm({
                title: '확인',
                content: '수정하시겠습니까?',
                zIndex: 10000,
                onOk: () => {
                    onUpdateUser(data);
                }
            });
        }
    }

    const onChangeUser = async (data) => {
        delete data?.key
        delete data?.estimateCount
        await auth.changeUser(data)
            .then((res) => {
                if (res) {
                    if(res.commonRespCode === "1") {
                        Modal.success({
                            title: '알림',
                            content: '정회원으로 전환되었습니다.',
                            zIndex: 10000,
                        });
                        onClose();
                    }
                } else {
                    Modal.error({
                        title: '알림',
                        content: '오류가 발생하였습니다.',
                        zIndex: 10000,
                    });
                }
            })
    }

    const onUpdateUser = async (data) => {
        await auth.updateUser(data)
            .then((res) => {
                if (res) {
                    if(res.commonRespCode === "1") {
                        Modal.success({
                            title: '알림',
                            content: res.message,
                            zIndex: 10000,
                        });
                        onClose();
                    } else {
                        Modal.error({
                            title: '알림',
                            content: res.message,
                            zIndex: 10000,
                        });
                    }
                } else {
                    Modal.error({
                        title: '알림',
                        content: '오류가 발생하였습니다.',
                        zIndex: 10000,
                    });
                }
            })
    }

    const onDeleteUser = () => {
        Modal.confirm({
            title: '확인',
            content: '삭제하시겠습니까?',
            zIndex: 10000,
            onOk: async () => {
                await auth.deleteUser({
                    userId: beforeUserId,
                }).then((res) => {
                    if (res) {
                        Modal.success({
                            title: '알림',
                            content: '삭제되었습니다.',
                            zIndex: 10000,
                        });
                        onClose();
                    } else {
                        Modal.error({
                            title: '알림',
                            content: '오류가 발생하였습니다.',
                            zIndex: 10000,
                        });
                    }
                })
            }
        });
    }

    const onResetPassword = async () => {
        Modal.confirm({
            title: '확인',
            content: '비밀번호를 초기화 하시겠습니까?',
            zIndex: 10000,
            onOk: async () => {
                await user.resetPassword(beforeUserId)
                    .then((res) => {
                        if (res) {
                            Modal.success({
                                title: '알림',
                                content: res.message,
                                zIndex: 10000,
                            });
                        } else {
                            Modal.error({
                                title: '알림',
                                content: '오류가 발생하였습니다.',
                                zIndex: 10000,
                            });
                        }
                    })
            }
        });
    }

    const onCheckEmail = async () => {
        if (list.userId === beforeUserId) return;
        await user.searchUserIdCheck(list.userId).then((res) => {
            if (res) {
                if(res.commonRespCode === "0") {
                    setEmailCheck(true);
                    return
                }
                setEmailCheck(false);
            } else {
                Modal.error({
                    title: '알림',
                    content: '오류가 발생하였습니다.',
                    zIndex: 10000,
                });
            }
        })
    }

    return (
        <div>
            <ModalComponent
                isShow={true}
                title={title}
                width={480}
                className="estimate-modal-wrap"
                onClose={onClose}
                contentComponent={
                    <div className="admin-common-form-wrap">
                        { statusId === "info" ?
                            <>
                                <ReadOnlyInput title="이메일" value={list.userId} />
                                <ReadOnlyInput title="회사명" value={list.companyName} />
                                <ReadOnlyInput title="이름" value={list.userName} />
                                <ReadOnlyInput title="전화번호" value={autoHypen(list.userPhone)} />
                            </> :
                            <>
                                { statusId !== "user" ?
                                    <ReadOnlyInput title="이메일" value={list.userId} /> :
                                    <Input
                                        pageType="R"
                                        title="이메일"
                                        className={notEnter === "이메일" && "input-full input-red"}
                                        alert={emailCheck && "* 이미 사용중인 이메일입니다."}
                                        placeholder="이메일"
                                        value={list.userId}
                                        onBlur={onCheckEmail}
                                        onChange={e => {
                                            if(e.target.value.length > 80) {
                                                setNotEnter("이메일");
                                                Modal.warning({
                                                    title: '알림',
                                                    content: '이메일은 80자 이하만 가능합니다.',
                                                    zIndex: 10000,
                                                });
                                            } else {
                                                setList((prev) => {
                                                    return ({
                                                        ...prev,
                                                        userId: e.target.value,
                                                    })
                                                })
                                            }
                                        }}
                                />}
                                <div className="form-block">
                                    <span className="block-title">
                                        비밀번호
                                    </span>
                                    <div className="block-content">
                                        <button className="btn btn-small" onClick={onResetPassword}>비밀번호 초기화</button>
                                    </div>
                                </div>
                                { statusId !== "user" && <Input
                                    pageType="R"
                                    title="회사명"
                                    className={notEnter === "회사명" && "input-full input-red"}
                                    placeholder="회사명"
                                    value={list.companyName}
                                    onChange={e => setList((prev) => {
                                        return ({
                                            ...prev,
                                            companyName: e.target.value,
                                        })
                                    })}
                                />}
                                { statusId === "user" && <div className="form-block">
                                    <span className="block-title">
                                        회사명
                                    </span>
                                    <div className="block-content">
                                        <Select
                                            className="mw10"
                                            placeholder="회사 선택"
                                            value={list.companyName}
                                            onChange={value => setList((prev) => {
                                                return ({
                                                    ...prev,
                                                    companyName: value,
                                                })
                                            })}
                                            options={company}
                                            status={notEnter === "회사명" && "error"}
                                            style={{ marginTop: "0px" }}
                                            dropdownStyle={{zIndex: "10001"}}
                                        />
                                    </div>
                                </div>}
                                <Input
                                    pageType="R"
                                    title="이름"
                                    className={notEnter === "이름" && "input-full input-red"}
                                    placeholder="이름"
                                    value={list.userName}
                                    onChange={e => setList((prev) => {
                                        return ({
                                            ...prev,
                                            userName: e.target.value,
                                        })
                                    })}
                                />
                                <Input
                                    pageType="R"
                                    type="tel"
                                    title="연락처"
                                    className={notEnter === "연락처" && "input-full input-red"}
                                    value={autoHypen(list.userPhone)}
                                    placeholder="휴대폰 번호를 입력하세요."
                                    onChange={e => {
                                        if(e.target.value.length <= 13) {
                                            setList((prev) => {
                                                return ({
                                                    ...prev,
                                                    userPhone: e.target.value,
                                                })
                                            })
                                        } else {
                                            setNotEnter("연락처");
                                            Modal.warning({
                                                title: '알림',
                                                content: '11자리가 아닙니다.',
                                                zIndex: 10000,
                                            });
                                        }
                                    }}
                                />
                                <div className="form-block">
                                    <span className="block-title">
                                        접속권한
                                    </span>
                                    <div className="block-content">
                                        <Radio.Group onChange={onChangeCheckBox} value={checkValue}>
                                            <Radio value={"Y"}>사용</Radio>
                                            <Radio value={"N"}>중지</Radio>
                                        </Radio.Group>
                                        <p className={"description-text"}>*권한을 중지할 경우 해당 사용자의 모든 요청이 종료됩니다.</p>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                }
                footerComponent={
                    <div className="align-center" style={{margin: 20}}>
                        {statusId !== "info" &&
                            <div style={{ display: "flex"}}>
                                <button className="btn btn-orange" onClick={onEditCustomer}>{btnName}</button>
                                <button className="btn btn-orange" onClick={onDeleteUser}>삭제하기</button>
                            </div>}
                    </div>
                }
            />
        </div>
    )
})

export default CustomerEditModal;
import { useState, useEffect } from 'react';

export default function useScreen() {
    const isBrowser = typeof window !== 'undefined';
    const getScreenSize = () => {
        return {
            width: isBrowser ? window.innerWidth : null,
            height: isBrowser ? window.innerHeight : null
        };
    }
    const [screenSize, setScreenSize] = useState(getScreenSize);
    const onResize = () => {
        setScreenSize(getScreenSize());
    }
    useEffect(() => {
        if (isBrowser) {
            window.addEventListener('resize', onResize);
            return () => window.removeEventListener('resize', onResize);
        }
    }, []);
    return {
        screenSize
    };
}
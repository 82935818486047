import {useEffect, useState} from "react";

const DashBoard = ({ data, onClickBoard, selectData, notNum }) => {
    const sampleData = [
        {
            "name": "입찰요청",
            "key": "ES01"
        },
        {
            "name": "입찰완료",
            "key": "ES03"
        },
        {
            "name": "계약요청",
            "key": "ES04"
        },
        {
            "name": "계약완료",
            "key": "ES06"
        },
        {
            "name": "입찰종료",
            "key": "ES07"
        }
    ]
    const [newData, setNew] = useState([]);
    const [clickColor, setClickColor] = useState("");

    const onClickItem = (item) => {
        onClickBoard(item);
        setClickColor(item.color)
    }

    useEffect(() => {
        makeData();
        setClickColor("");
    }, [data, selectData])

    const makeData = () => {
        let tempData = [];
        if (data) {
            data.forEach((item) => {
                if (item.name === "견적요청") {
                    tempData.push({
                        ...item,
                        color: "#d2adf6"
                    })
                } else if (item.name === "계약요청") {
                    tempData.push({
                        ...item,
                        color: "#d2e683"
                    })
                } else if (item.name === "입찰완료") {
                    tempData.push({
                        ...item,
                        color: "#faafd2"
                    })
                }  else if (item.name === "미참여" || item.name === "입찰요청") {
                    tempData.push({
                        ...item,
                        color: "#9ac9fe"
                    })
                } else if (item.name === "계약완료") {
                    tempData.push({
                        ...item,
                        color: "#f9bd98"
                    })
                } else if (item.name === "입찰종료") {
                    tempData.push({
                        ...item,
                        color: "#d9d9d9"
                    })
                }
            })
        } else {
            sampleData.forEach((item) => {
                if (selectData === "ES04" && item.key === selectData) {
                    tempData.push({
                        ...item,
                        color: "#d2e683"
                    })
                } else if (selectData === "ES03" && item.key === selectData) {
                    tempData.push({
                        ...item,
                        color: "#faafd2"
                    })
                }  else if (selectData === "ES01" && item.key === selectData) {
                    tempData.push({
                        ...item,
                        color: "#9ac9fe"
                    })
                } else if (selectData === "ES06" && item.key === selectData) {
                    tempData.push({
                        ...item,
                        color: "#f9bd98"
                    })
                } else {
                    tempData.push({
                        ...item,
                        color: "#d9d9d9"
                    })
                }
            })
        }
        setNew(tempData);
    }

    return(
        <div className="dashboard" style={notNum && { marginBottom: "40px"}}>
        {newData && newData.map((item) => {
            return (
                <div className="dashboard-main" key={item.code} style={{ boxShadow: (notNum && item.color !== "#d9d9d9") || clickColor === item.color ? `0px 0px 7px 7px ${item.color}` : `0px 0px 8px 1px ${item.color}` }}>
                    <div className="dashboard-name">{item.name}</div>
                    { !notNum &&
                        <div className="dashboard-value">
                            <span onClick={() => onClickItem(item)}>{item.count}</span>
                        </div>
                    }
                </div>
            )
        })}
        </div>
    )
}

export default DashBoard;
import AdminLayout from "../../../components/layouts/AdminLayout";
import {observer} from "mobx-react";
import {DatePicker, Modal, Radio, Select, Table} from "antd";
import {useEffect, useState} from "react";
import {store} from "../../../stores";
import translator from "../../../utils/translator";
import {addComma, dateRange, dateSorter, stringSorter} from "../../../utils";
import {auth} from "../../../services/api";
import {isLogin} from "../../../utils/auth";
import {useLocation, useNavigate} from "react-router-dom";

const AdminContract = observer(() => {
    const user = store.userStore.getUser();
    const initialData = {
        userId: "",
        pageNo: 1,
        limit: 9999,
        searchKey: "",
        searchValue: "",
        dateType: "",
        fromDate: "",
        toDate: "",
    }
    const initialList = {
        items: [],
        limit: 10,
        pageNo: 1,
        totalCount: 0,
    }
    const selectOption = [
        { label: '계약일시', value: 'bid_update_dt' },
        { label: '견적일시', value: 'es_insert_dt' },
        { label: '회사명', value: 'es_company_name' },
        { label: '이름', value: 'es_user_name' },
        { label: '보험사명', value: 'bid_company_name' },
        { label: '담당자명', value: 'bid_user_name' },
        { label: '입찰보험료', value: 'bid_amount' },
    ];
    const options = [
        {
            label: '전체',
            value: '',
        },
        {
            label: '처리',
            value: 'contracted',
        },
        {
            label: '미처리',
            value: 'uncontracted',
        },
    ];
    const [data, setData] = useState(initialData);
    const [list, setList] = useState(initialList);
    const [onlyList,setOnlyList] = useState([]);
    const [stateData, setState] = useState("");
    const [page, setPageNum] = useState(1);
    const [selectType, setSelectType] = useState("");
    const { state } = useLocation();
    const navigate = useNavigate();

    const fetchData = async (data) => {
        const result = await auth.searchAdminContract(data).then((res) => {
            const resultData = res?.items?.map((item) => {
                return {
                    ...item,
                    key: item.esCode,
                    bidAmount: addComma(item.bidAmount)
                }
            });
            setOnlyList(resultData);
            return res;
        });
        setList(result);
    }

    useEffect(() => {
        checkPageAuth();
        if (state) {
            setState(state.select);
        }
    }, []);

    useEffect(() => {
        searchList(1, user.userId);
    }, [stateData])

    const checkPageAuth = async () => {
        const auth = await isLogin();
        if (auth) {
            const user = store.userStore.getUser();
            if (user?.groupId === "3") {
                searchList(page, user.userId);
                return;
            }
            Modal.error({
                title: '알림',
                content: '권한이 없습니다. 다시 로그인 해주세요.',
            });
            navigate("/login")
        }
    }

    const searchList = (pageNo, userId) => {
        let searchType;
        if (selectType === "es_insert_dt" || selectType === "bid_update_dt") {
            searchType = "dateType";
        } else {
            searchType = "searchKey";
        }
        const tempData = {
            ...data,
            userId,
            pageNo,
            fromDate: searchType === "searchKey" ? "" : data.fromDate,
            toDate: searchType === "searchKey" ? "" : data.toDate,
            dateType: searchType === "dateType" ? selectType : "",
            searchKey: searchType === "searchKey" ? selectType : "",
            searchValue: searchType === "searchKey" ? data.searchValue : "",
            esStatus: stateData,
        }
        setData(tempData);
        setPageNum(pageNo);
        fetchData(tempData);
    }

    const onChangeOption = ({ target: { value } }) => {
        if(!value){
            setState("");
            return;
        }
        setState(value);
    }

    const onPageChange = (data) => {
        navigate(`/admin/contract/detail/${data.esCode}`);
    }

    const onClickSearch = () => {
        if (data.searchValue !== "" && selectType === "") {
            Modal.warning({
                title: '알림',
                content: '검색하실 키워드를 선택해주세요.',
            });
            return;
        }
        if (selectType === "bid_update_dt" || selectType === "es_insert_dt") {
            if (data.fromDate === "" || data.toDate === "") {
                Modal.warning({
                    title: '알림',
                    content: '시작 날짜과 끝 날짜를 입력해주세요.',
                });
                return;
            }
            if (!dateRange(data.fromDate, data.toDate)) {
                Modal.warning({
                    title: '알림',
                    content: '시작 날짜가 끝 날짜보다 클 수 없습니다.',
                });
                return;
            }
        }
        searchList(1, user.userId)
    }

    const onChangeSelect = (e) => {
        let value = e;
        if (!e) {
            value = "";
        }
        setSelectType(value);
    };

    const onChangeFromDate = (date, dateString) => {
        setData({
            ...data,
            fromDate: dateString !== "" ? `${dateString} 00:00:00` : "",
        })
    };

    const onChangeToDate = (date, dateString) => {
        setData({
            ...data,
            toDate: dateString !== "" ? `${dateString} 23:59:59` : "",
        })
    };

    const header = [
        "bidUpdateDt",
        "esInsertDt",
        "esCompanyName",
        "esUserName",
        "bidCompanyName",
        "bidUserName",
        "bidAmount",
    ]

    const columns = header.map((key) => {
        if(key === "bidUpdateDt" || key === "esInsertDt") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => dateSorter(a, b, key),
                align: 'center',
                width: "116px"
            }
        }
        else if(key === "bidAmount") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
                width: "126px"
            }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
            }
        }
    })

    return(
        <AdminLayout title="계약 관리">
            <div className="filter-wrap">
                <Radio.Group
                    options={options}
                    onChange={onChangeOption}
                    value={stateData}
                    optionType="button"
                    buttonStyle="solid"
                />
                <div className="filter-item">
                    <Select
                        allowClear
                        style={{ width: 120 }}
                        defaultValue={selectType}
                        onChange={onChangeSelect}
                        options={selectOption}
                    />
                    <div className="filter-content">
                        {selectType !== "bid_update_dt" && selectType !== "es_insert_dt" &&
                            <input
                                type="text"
                                className="input-text input-small"
                                onChange={(e) => setData({
                                    ...data,
                                    searchValue: e.target.value
                                })}
                                onKeyPress={e => {
                                    if (e.key.toLowerCase() === 'enter') {
                                        onClickSearch();
                                    }
                                }}
                            />
                        }
                        { selectType === "bid_update_dt" || selectType === "es_insert_dt" ?
                            <>
                                <DatePicker
                                    onChange={onChangeFromDate}
                                    placeholder=""
                                    style={{width: "120px"}}
                                /> ~<DatePicker
                                onChange={onChangeToDate}
                                placeholder=""
                                style={{width: "120px"}}
                            />
                            </> : <></>
                        }
                        <button className="btn btn-small btn-orange btn-filter" onClick={onClickSearch}>검색</button>
                    </div>
                </div>
            </div>
            <Table
                dataSource={onlyList}
                columns={columns}
                style={{ cursor: "pointer" }}
                onRow={(record) => {
                    return {
                        onClick: () => onPageChange(record)
                    };
                }}
                pagination={{
                    current: page,
                    onChange: (page) => setPageNum(page),
                    pageSize: 10,
                    total: list.totalCount,
                    position: ["bottomCenter"],
                    showSizeChanger: false,
                }}
                className={"adminTable"}
            />
        </AdminLayout>
    )
})

export default AdminContract;

import translator from "../../../utils/translator";
import {DatePicker, Modal, Select, Table} from "antd";
import CustomerEditModal from "../customer/editModal";
import AdminLayout from "../../../components/layouts/AdminLayout";
import {useEffect, useState} from "react";
import {store} from "../../../stores";
import {useLocation, useNavigate} from "react-router-dom";
import {auth} from "../../../services/api";
import {isLogin} from "../../../utils/auth";
import {addComma, dateRange, dateSorter, stringSorter} from "../../../utils";
import {getCode} from "../../../utils/code";

const Bidding = () => {
    const user = store.userStore.getUser();
    const initialData = {
        userId: "",
        pageNo: 1,
        limit: 9999,
        searchKey: "",
        searchValue: "",
        dateType: "",
        fromDate: "",
        toDate: "",
    }
    const initialList = {
        items: [],
        limit: 10,
        pageNo: 1,
        totalCount: 0,
    }
    const selectOption = [
        { label: '견적번호', value: 'es_code' },
        { label: `${translator["bid"]}번호`, value: 'bid_code' },
        { label: '이메일', value: `user_id` },
        { label: '보험사명', value: `company_name` },
        { label: '담당자명', value: `user_name` },
        { label: `${translator["bid"]}제시일`, value: 'insert_dt' },
        { label: '입찰보험료', value: 'bid_amount' },
    ];
    const [data, setData] = useState(initialData);
    const [list, setList] = useState(initialList);
    const [onlyList,setOnlyList] = useState([]);
    const [page, setPageNum] = useState(1);
    const [selectType, setSelectType] = useState("");
    const [selected, setSelected] = useState(null);
    const [stateEmail, setEmail] = useState("");
    const { state } = useLocation();
    const navigate = useNavigate();

    const fetchData = async (data) => {
        const code = getCode();
        const result = await auth.searchBidding(data).then((res) => {
            const resultData = res.items.map((item) => {
                return {
                    ...item,
                    bidCompanyName: item.companyName,
                    bidUserName: item.userName,
                    bidStatusCode: code[item.bidStatusCode],
                    bidAmount: addComma(item.bidAmount),
                    estimateCode: item.esCode,
                    biddingCode: item.bidCode,
                    bidInsertDt: item.insertDt,
                    key: item.bidCode
                }
            });
            setOnlyList(resultData);
            return res;
        });
        setList(result);
    }

    useEffect(() => {
        checkPageAuth();
        if (state) {
            setEmail(state.email);
        }
    }, []);

    useEffect(() => {
        if (stateEmail === "") return;
        const tempData = {
            ...data,
            userId : user.userId,
            searchKey: "user_id",
            searchValue: state.email,
        }
        onChangeSelect("user_id");
        setData(tempData);
        fetchData(tempData);
    }, [stateEmail])

    const checkPageAuth = async () => {
        const auth = await isLogin();
        if (auth) {
            const user = store.userStore.getUser();
            if (user?.groupId === "3") {
                searchList(page, user.userId);
                return;
            }
            Modal.error({
                title: '알림',
                content: '권한이 없습니다. 다시 로그인 해주세요.',
            });
            navigate("/login")
        }
    }

    const searchList = (pageNo, userId) => {
        let searchType;
        if (selectType === "insert_dt") {
            searchType = "dateType";
        } else {
            searchType = "searchKey";
        }
        const tempData = {
            ...data,
            userId,
            pageNo,
            fromDate: searchType === "searchKey" ? "" : data.fromDate,
            toDate: searchType === "searchKey" ? "" : data.toDate,
            dateType: searchType === "dateType" ? selectType : "",
            searchKey: searchType === "searchKey" ? selectType : "",
            searchValue: searchType === "searchKey" ? data.searchValue : "",
        }
        setData(tempData);
        setPageNum(pageNo);
        fetchData(tempData);
    }

    const onClickSearch = () => {
        if (data.searchValue !== "" && selectType === "") {
            Modal.warning({
                title: '알림',
                content: '검색하실 키워드를 선택해주세요.',
            });
            return;
        }
        if (selectType === "insert_dt") {
            if (data.fromDate === "" || data.toDate === "") {
                Modal.warning({
                    title: '알림',
                    content: '시작 날짜과 끝 날짜를 입력해주세요.',
                });
                return;
            }
            if (!dateRange(data.fromDate, data.toDate)) {
                Modal.warning({
                    title: '알림',
                    content: '시작 날짜가 끝 날짜보다 클 수 없습니다.',
                });
                return;
            }
        }
        searchList(1, user.userId)
    }

    const onChangeSelect = (e) => {
        let value = e;
        if (!e) {
            value = "";
        }
        setSelectType(value);
    };

    const onPageChange = (data) => {
        navigate(`/admin/estimate/${data.bidCode}`);
    }

    const onChangeFromDate = (date, dateString) => {
        setData({
            ...data,
            fromDate: dateString !== "" ? `${dateString} 00:00:00` : "",
        })
    };

    const onChangeToDate = (date, dateString) => {
        setData({
            ...data,
            toDate: dateString !== "" ? `${dateString} 23:59:59` : "",
        })
    };

    const onOpenPopup = async (data) => {
        await auth.searchUser(data.userId).then((res) => {
            setSelected(res);
        });
    }

    const header = [
        "estimateCode",
        "biddingCode",
        "userId",
        "bidCompanyName",
        "bidUserName",
        "bidInsertDt",
        "bidAmount",
        "bidStatusCode"
    ]

    const columns = header.map((key) => {
        if(key === "bidInsertDt") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => dateSorter(a, b, key),
                align: 'center',
                render: (v, d) => {
                    return (
                        <div onClick={() => onPageChange(d)}>{v}</div>
                    );
                }
            }
        }
        else if(key === "bidAmount") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
                render: (v, d) => {
                    return (
                        <div onClick={() => onPageChange(d)}>{v}</div>
                    );
                }
            }
        } else if(key === "userId" || key === "bidCompanyName" || key === "bidUserName") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                render: (v, d) => {
                    return (
                        <div onClick={() => onOpenPopup(d)}>{v}</div>
                    );
                }
            }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                render: (v, d) => {
                    return (
                        <div onClick={() => onPageChange(d)}>{v}</div>
                    );
                }
            }
        }
    })

    return (
        <AdminLayout title={"응찰 관리"}>
            <div className="filter-wrap" style={{ justifyContent: "end" }}>
                <div className="filter-item">
                    <Select
                        allowClear
                        style={{ width: 120 }}
                        defaultValue={selectType}
                        onChange={onChangeSelect}
                        options={selectOption}
                    />
                    <div className="filter-content">
                        {selectType !== "insert_dt" &&
                            <input
                                type="text"
                                className="input-text input-small"
                                onChange={(e) => setData({
                                    ...data,
                                    searchValue: e.target.value
                                })}
                                onKeyPress={e => {
                                    if (e.key.toLowerCase() === 'enter') {
                                        onClickSearch();
                                    }
                                }}
                            />
                        }
                        { selectType === "insert_dt" ?
                            <>
                                <DatePicker
                                    onChange={onChangeFromDate}
                                    placeholder=""
                                    style={{width: "120px"}}
                                /> ~<DatePicker
                                onChange={onChangeToDate}
                                placeholder=""
                                style={{width: "120px"}}
                            />
                            </> : <></>
                        }
                        <button className="btn btn-small btn-orange btn-filter" onClick={onClickSearch}>검색</button>
                    </div>
                </div>
            </div>
            <Table
                dataSource={onlyList}
                columns={columns}
                style={{ cursor: "pointer" }}
                pagination={{
                    current: page,
                    onChange: (page) => setPageNum(page),
                    pageSize: 10,
                    total: list.totalCount,
                    position: ["bottomCenter"],
                    showSizeChanger: false,
                }}
                className={"adminTable"}
            />
            {selected &&
                <CustomerEditModal
                    selectData={selected}
                    statusId="info"
                    onClose={() => {
                        setSelected(null);
                        searchList(1, user.userId);
                    }}
                />
            }
        </AdminLayout>
    )
}

export default Bidding;
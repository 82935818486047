import {useState, useEffect, useCallback} from "react";
import PageTitle from "../../components/pageTitle/PageTitle";
import Layout from "../../components/layouts/Layout";
import Input from "../../components/input/Input";
import {user} from "../../services/api";
import {store} from "../../stores";
import {observer} from "mobx-react";
import {useLocation, useNavigate} from "react-router-dom";
import {isLogin} from "../../utils/auth";
import {Modal} from "antd";
import {autoHypen} from "../../utils";

const MyPage = observer(() => {
    const initialData = {
        userId: "",
        userPass: "",
        companyName: "",
        userName: "",
        userPhone: ""
    }
    const [data, setData] = useState(initialData);
    const [userPassCheck, setUserPassCheck] = useState("");
    const [passCheck, setPassCheck] = useState(false);
    const [userGroup, setUserGroup] = useState("");
    const navigate = useNavigate();
    const { state } = useLocation();

    const checkPageAuth = useCallback(async () => {
        const auth = await isLogin();
        if(!auth) return;
        const user = store.userStore.getUser();
        if (user?.groupId && !state) {
            setData({
                userId: user.userId,
                userPass: user.userPwd,
                companyName: user.companyName,
                userName: user.userName,
                userPhone: user.userPhone
            });
            setUserGroup(user.groupId);
        } else if (state) {
            setData((prev) => {
                return ({
                    ...prev,
                    userId: state.data.userId ? state.data.userId : "",
                    userName: state.data.userName ? state.data.userName : "",
                    userPhone: state.data.userPhone ? state.data.userPhone : "",
                    companyName: state.data.companyName ? state.data.companyName : "",
                })
            })
            setUserGroup(state.data.groupId);
        } else {
            Modal.error({
                title: '알림',
                content: '권한이 없습니다. 다시 로그인 해주세요.',
            });
            navigate("/login")
        }
    }, [navigate, state])

    useEffect(() => {
        checkPageAuth();
    }, [checkPageAuth])

    const mappingField = {
        "companyName": "회사명",
        "userName": "고객명",
        "userPhone": "연락처"
    }

    const editUser = () => {
        let emptyValue = [];
        Object.keys(mappingField).forEach((key) => {
            if (data[key] === "" || data[key] === null || data[key] === undefined ) {
                emptyValue.push(mappingField[key]);
            }
        })
        if (emptyValue.length > 0) {
            Modal.warning({
                title: '알림',
                content: `[${emptyValue[0]}] 값을 입력해주세요.`,
            });
            return;
        }
        if (data.userPass && data.userPass !== userPassCheck) {
            Modal.warning({
                title: '알림',
                content: '비밀번호를 확인해주세요.',
            });
            return;
        }
        // if (data.userPass && !/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/.test(data.userPass)) {
        //     Modal.warning({
        //         title: '알림',
        //         content: '비밀번호는 영문, 숫자, 특수문자 조합 8자 이상으로 입력해야 합니다.',
        //     });
        //     return;
        // }
        Modal.confirm({
            title: '확인',
            content: '수정하시겠습니까?',
            onOk: async () => {
                await user.updateUser({...data, userPhone: data.userPhone.replace(/-/g, '')})
                    .then((res) => {
                        if (res) {
                            if(res.commonRespCode === "success" && res.data.length > 0) {
                                store.userStore.setUser(res.data[0]);
                                navigate("/");
                                Modal.success({
                                    title: '알림',
                                    content: '수정되었습니다.',
                                });
                                return;
                            }
                            Modal.error({
                                title: '알림',
                                content: res.message,
                            });
                        } else {
                            Modal.error({
                                title: '알림',
                                content: '오류가 발생하였습니다.',
                            });
                        }
                    })
            }
        });
    }

    const onCheckPassword = async () => {
        if (data.userPass.length === 0 || userPassCheck.length === 0) return;
        if (data.userPass !== userPassCheck) {
            setPassCheck(true)
        } else {
            setPassCheck(false)
        }
    }

    return(
        <Layout>
            <div className="common-wrapper page-user-mypage">
                <PageTitle title="마이페이지" subTitle="회원정보 수정" />
                <div className="common-form-wrap">
                    <div className="form-block only-string">
						<span className="block-title">
							E-mail
						</span>
                        <div className="block-content">
                            <p className="content-string">{data.userId}</p>
                        </div>
                    </div>
                    <Input
                        pageType="R"
                        title="비밀번호"
                        type="password"
                        value={data.userPass}
                        placeholder="변경할 비밀번호 입력"
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                userPass: e.target.value,
                            })
                        })}
                    />
                    <Input
                        pageType="R"
                        title="비밀번호 확인"
                        alert={passCheck && "* 비밀번호가 일치하지 않습니다."}
                        type="password"
                        value={userPassCheck}
                        placeholder="변경할 비밀번호 확인"
                        onChange={e => setUserPassCheck(e.target.value)}
                        onBlur={onCheckPassword}
                    />
                    {userGroup === "2" &&
                        <>
                            <div className="form-block only-string">
                                <span className="block-title">
                                    회사명
                                </span>
                                <div className="block-content">
                                    <p className="content-string">{data.companyName}</p>
                                </div>
                            </div>
                            <div className="form-block only-string">
                                <span className="block-title">
                                    고객명
                                </span>
                                <div className="block-content">
                                    <p className="content-string">{data.userName}</p>
                                </div>
                            </div>
                            <div className="form-block only-string">
                                <span className="block-title">
                                    연락처
                                </span>
                                <div className="block-content">
                                    <p className="content-string">{autoHypen(data.userPhone)}</p>
                                </div>
                            </div>
                        </>}
                    {userGroup !== "2" &&
                        <>
                            <Input
                                pageType="R"
                                title="회사명"
                                value={data.companyName}
                                placeholder="회사명 입력"
                                onChange={e => setData((prev) => {
                                    return ({
                                        ...prev,
                                        companyName: e.target.value,
                                    })
                                })}
                            />
                            <Input
                                pageType="R"
                                title="고객명"
                                value={data.userName}
                                placeholder="고객명 입력"
                                onChange={e => setData((prev) => {
                                    return ({
                                    ...prev,
                                    userName: e.target.value,
                                    })
                                })}
                            />
                            <Input
                                pageType="R"
                                id="phone"
                                type="tel"
                                title="연락처"
                                value={autoHypen(data.userPhone)}
                                placeholder="휴대폰 번호를 입력하세요."
                                onChange={e => {
                                    if(e.target.value.length <= 13) {
                                        setData((prev) => {
                                            return ({
                                                ...prev,
                                                userPhone: e.target.value,
                                            })
                                        })
                                    } else {
                                        Modal.warning({
                                            title: '알림',
                                            content: '11자리가 아닙니다.',
                                        });
                                    }
                                }}
                            />
                        </>}
                </div>
                <div className="form-btn-wrap">
                    <button className="btn btn-orange btn-edit" onClick={editUser}>수정완료</button>
                </div>
            </div>
        </Layout>
    )
})

export default MyPage;
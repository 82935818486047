import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {auth, insurance} from "../../services/api";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import { SwapRightOutlined } from '@ant-design/icons';

const RollingInsurance = () => {
    const [list, setList] = useState();
    const navigate = useNavigate();

    const fetchList = async () => {
        await insurance.searchInsuranceNmAndUrl().then((res) => {
            setList(res);
        });
    }
    const selectSetting = async () =>{
        await auth.selectAdminSetting().then((res)=>{
            setSpeed(res.data[0].insuranceScrollSpeed);
        });
    }
    useEffect(() => {
        fetchList();
        selectSetting();
    }, [])
    const onClickList = (id) => {
        navigate(`/insurance/${id}`)
    }
    const [speed, setSpeed] = useState(4000);
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: false, // 현재 컨텐츠 가운데 정렬
        autoplay: true,
        autoplaySpeed: speed,
        initialSlide: 0,
    };
    return(
        <div className="insurance-slider-block">
            <div className="insurance-slider-header">
                <h1 className="content2">
                    신규 가입 가능한 기업 보험 상품
                </h1>
            </div>
            <Slider {...settings}>
                {list && list.map((item) => {
                    return (
                        <div className="insurance-slider-item" key={item?.insuranceCode}>
                            <div className="insurance-slider-text" onClick={() => onClickList(item.insuranceCode)} style={{
                                backgroundImage: `linear-gradient(90deg, #ffffff, rgba(255, 255, 255, 0.8),rgba(250, 240, 130, 0)), url("${item?.imgUrl}")`,
                                backgroundPosition: "center center",
                                backgroundSize: "cover",
                                boxShadow: "0px 0px 1px 1px #ddd",
                                border: "1px solid #ddd"
                            }}>
                                <h3 className={"insurance-title"}>
                                    {item.insuranceName}
                                </h3>
                                <p>
                                    {item.slideText}
                                </p>
                                <h6>자세히 보기 <SwapRightOutlined /></h6>
                            </div>
                        </div>
                    )
                })}
            </Slider>
        </div>
    )
}

export default RollingInsurance;
import Input from "../../components/input/Input";
import PageTitle from "../../components/pageTitle/PageTitle";
import {auth} from "../../services/api";
import {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ModalComponent from "../../components/modal/Modal";
import {privacyTermsData, serviceTermsData} from "../../components/modal/modalContentData";
import {autoHypen, checkEmail} from "../../utils";
import {Modal} from "antd";
import {store} from "../../stores";
import Layout from "../../components/layouts/Layout";
import {isLogin} from "../../utils/auth";

const NonMemberJoin = () => {
    const initialData = {
        userId: "",
        companyName: "",
        userName: "",
        userPhone: ""
    }
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(initialData);
    const [handleService, setService] = useState(false);
    const [handlePrivacy, setPrivacy] = useState(false);
    const [serviceTerms, setAgreeService] = useState(false);
    const [privacyTerms, setAgreePrivacy] = useState(false);
    const [notEnter, setNotEnter] = useState("");
    const navigate = useNavigate();

    const mappingField = {
        "userId": "이메일",
        "companyName": "회사명",
        "userName": "이름",
        "userPhone": "연락처"
    }

    const checkPageAuth = useCallback(async () => {
        const auth = await isLogin();
        const user = store.userStore.getUser();
        if (!auth || user?.userId === "") {
            Modal.error({
                title: '알림',
                content: '권한이 없습니다. 다시 로그인 해주세요.',
            });
            navigate("/login")
        } else {
            setData((prev) => {
                return ({
                    ...prev,
                    userId: user.userId,
                })
            });
        }
    },[navigate])

    useEffect(() => {
        checkPageAuth();
    }, [checkPageAuth]);

    const joinUser = () => {
        let emptyValue = [];
        Object.keys(mappingField).forEach((key) => {
            if (data[key] === "" || data[key] === null || data[key] === undefined ) {
                emptyValue.push(mappingField[key]);
            }
        })
        if (emptyValue.length > 0) {
            setNotEnter(emptyValue[0]);
            Modal.warning({
                title: '알림',
                content: `${emptyValue[0]} 값을 입력해주세요.`,
            });
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }
        if (data.userId !== "" && !checkEmail(data.userId)) {
            setNotEnter("이메일");
            window.scrollTo({ top: 0, behavior: 'smooth' });
            Modal.warning({
                title: '알림',
                content: '올바른 이메일 주소가 아닙니다.',
            });
            return;
        }
        if (!serviceTerms || !privacyTerms) {
            Modal.warning({
                title: '알림',
                content: '약관에 동의해주세요.',
            });
            return;
        }
        Modal.confirm({
            title: '확인',
            content: '가입하시겠습니까?',
            onOk: async () => {
                const tempData = {
                    ...data,
                    userPhone: data.userPhone.replace(/-/g, ''),
                }
                setLoading(true);
                await auth.changeUser(tempData)
                    .then((res) => {
                        setLoading(false);
                        if (res) {
                            if(res.commonRespCode === "1") {
                                Modal.success({
                                    title: '알림',
                                    content: "회원가입이 완료되었습니다.",
                                });
                                navigate("/login");
                            } else {
                                Modal.error({
                                    title: '알림',
                                    content: res.message,
                                });
                            }
                        } else {
                            Modal.error({
                                title: '알림',
                                content: '오류가 발생하였습니다.',
                            });
                        }
                    });
            }
        });
    }

    const onCheckService = () => {
        if (serviceTerms) {
            setAgreeService(false)
        } else {
            setService(true);
        }
    }

    const onCheckPrivacy = () => {
        if (privacyTerms) {
            setAgreePrivacy(false)
        } else {
            setPrivacy(true);
        }
    }

    return(
        <Layout loading={loading}>
            <div className="common-wrapper page-join">
                <PageTitle title="회원가입"/>
                <div className="label-form-wrap">
                    <Input
                        pageType="J"
                        id="email"
                        type="email"
                        title="E-mail 입력"
                        value={data.userId}
                        readOnly
                    />
                    <Input
                        pageType="J"
                        id="companyName"
                        title="회사명"
                        className={notEnter === "회사명" && "input-full input-red"}
                        placeholder="회사명을 입력하세요."
                        value={data.companyName}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                companyName: e.target.value,
                            })
                        })}
                    />
                    <Input
                        pageType="J"
                        id="customerName"
                        title="이름"
                        className={notEnter === "이름" && "input-full input-red"}
                        placeholder="이름을 입력하세요."
                        value={data.userName}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                userName: e.target.value,
                            })
                        })}
                    />
                    <Input
                        pageType="J"
                        id="phone"
                        type="tel"
                        title="연락처"
                        className={notEnter === "연락처" && "input-full input-red"}
                        value={autoHypen(data.userPhone)}
                        placeholder="휴대폰 번호를 입력하세요."
                        onChange={e => {
                            if(e.target.value.length <= 13) {
                                setData((prev) => {
                                    return ({
                                        ...prev,
                                        userPhone: e.target.value,
                                    })
                                })
                            } else {
                                setNotEnter("연락처");
                                Modal.warning({
                                    title: '알림',
                                    content: '11자리가 아닙니다.',
                                });
                            }
                        }}
                    />
                    <div className="form-block">
                        <label className="block-label">약관동의 <span style={{color: "red"}}>(필수)</span></label>
                        <div className="block-content">
                            <div>
                                <div className="input-checkbox-wrap">
                                    <input id="agree1" type="checkbox" className="input-checkbox only-sr" checked={serviceTerms} onClick={onCheckService} />
                                    <label htmlFor="agree1"/>
                                </div>
                                <label className="c-label" onClick={() => setService(true)} style={{cursor: 'pointer'}}>서비스 이용약관</label>
                            </div>
                            <div style={{marginTop: 16}}>
                                <div className="input-checkbox-wrap">
                                    <input id="agree2" type="checkbox" className="input-checkbox only-sr" checked={privacyTerms} onClick={onCheckPrivacy} />
                                    <label htmlFor="agree2"/>
                                </div>
                                <label className="c-label" onClick={() => setPrivacy(true)} style={{cursor: 'pointer'}}>개인정보 활용</label>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="btn btn-orange btn-join" onClick={joinUser}>가입하기</button>
            </div>
            <ModalComponent
                isShow={handleService}
                title="서비스 이용약관"
                onClose={() => setService(false)}
                contentComponent={serviceTermsData()}
                setChecked={setAgreeService}
            />
            <ModalComponent
                isShow={handlePrivacy}
                title="개인정보 활용 약관"
                onClose={() => setPrivacy(false)}
                contentComponent={privacyTermsData()}
                setChecked={setAgreePrivacy}
            />
        </Layout>
    )
}

export default NonMemberJoin;

import Layout from "../../components/layouts/Layout";
import Input from "../../components/input/Input";
import PageTitle from "../../components/pageTitle/PageTitle";
import React, {useState, useRef, useEffect} from "react";
import FileSelector from "../../components/fileSelector/FileSelector";
import DatePicker, { registerLocale }  from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';
import {useNavigate} from "react-router-dom";
import {user} from "../../services/api";
import {store} from "../../stores";
import {observer} from "mobx-react";
import {checkEmail, commonDateFormat, timeFormat} from "../../utils";
import {Modal} from "antd";
import {CheckCircleFilled} from "@ant-design/icons";

registerLocale('ko', ko)
const today = new Date();
const nextDay = new Date(today);
nextDay.setDate(today.getDate() + 1);

const EstimateRequest = observer(() => {
    const userStoreData = store.userStore.getUser();
    const allowFileList = store.allowFileListStore.getAllowFileList();
    const initialData = {
        title: "",
        userId: "",
        userPwd: "",
        biz: "",
        policy: "",
        accident: "",
        deadlineDt: "",
        comment: ""
    }
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(initialData);
    const [userData, setUserData] = useState({})
    const [title, setTitle] = useState("")
    const [memYn, setMem] = useState(false);
    const [emailCheck, setEmailCheck] = useState(false);
    const [notEnter, setNotEnter] = useState("");
    const [authCode, setAuthCode] = useState("");
    const [trueAuth, setTrueAuth] = useState(false);
    const [userPassCheck, setUserPassCheck] = useState("");
    const [passCheck, setPassCheck] = useState(false);
    const [authSetting, setAuthSetting] = useState(false);
    const [checkSetting, setCheckSetting] = useState(false);
    const [authBtnSetting, setAuthBtnSetting] = useState(false);
    const [btnSetting, setBtnSetting] = useState("이메일 인증");
    const [timer, setTimer] = useState(299);
    const expireAt = store.timeStore.getTime(); // 만료 시간
    const [timerMessage, setMessage] = useState("");
    const [start, setStart] = useState(false);
    const _calendar = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!start) return;
        if (timer > 0) {
            const counter = setInterval(() => {
                const gap = Math.floor((new Date(expireAt).getTime() - new Date().getTime()) / 1000)
                setTimer(gap);
            }, 1000)
            return () => clearInterval(counter)
        } else {
            setStart(false);
            setMessage("인증번호가 만료되었습니다.")
        }
    }, [expireAt, timer, start])

    useEffect(() => {
        if(userStoreData.groupId === "2") {
            Modal.error({
                title: '알림',
                content: '접근 권한이 없습니다.',
            });
            navigate("/");
            return;
        }
        if (userStoreData !== {}) {
            setUserData(userStoreData)
        }
        if (!userStoreData?.groupId) {
            setTitle("비회원")
            setMem(false);
        } else if (userStoreData?.groupId === "1") {
            setTitle("회원")
            setMem(true);
        }
    } , [userStoreData])

    const visibleDatePicker = (bool) => {
        if (_calendar.current) {
            _calendar.current.setOpen(bool);
        }
    }

    const uploadFile = async (e, target) => {
        if(!e.target.files[0]) return;

        //이미지 파일만 받기
        let _lastDot = e.target.files[0].name.lastIndexOf('.');
        let _fileExt = e.target.files[0].name.substring(_lastDot+1, e.target.files[0].name.length);
        if(!allowFileList.includes(_fileExt)){
            Modal.warning({
                title: '알림',
                content: '이미지, PDF, ZIP 파일만 업로드 가능합니다.',
            });
            return;
        }
        //이미지 사이즈 확인
        if((e.target.files[0].size/1024/1024).toFixed(2) > 10){
            Modal.warning({
                title: '알림',
                content: (
                    <div>
                        <p>해당 파일은 {(e.target.files[0].size / 1024 / 1024).toFixed(2)}MB 입니다.</p>
                        <p>10MB 미만의 파일만 업로드 가능합니다.</p>
                    </div>
                )
            });
            return;
        }
        const previewUrl = window.URL.createObjectURL(e.target.files[0])
        switch (target) {
            case 'biz':
                setData((prev) => {
                    return ({
                        ...prev,
                        biz: e.target.files[0],
                        bizPreviewUrl : previewUrl
                    })
                })
                break;
            case 'policy':
                setData((prev) => {
                    return ({
                        ...prev,
                        policy: e.target.files[0],
                        policyPreviewUrl : previewUrl
                    })
                })
                break;
            default:
                break;
        }
    }

    const mappingField = {
        "title": "제목",
        "userId": "이메일",
        "userPwd": "비밀번호",
        "userPassCheck": "비밀번호",
        "biz": "사업자등록증",
        "policy": "갱신증권",
        "deadlineDt": "입찰 마감일",
    }

    const AuthButton = () => {
        if(data.userId === "") {
            setNotEnter("이메일");
            setAuthSetting(false);
            setCheckSetting(false);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            Modal.warning({
                title: '알림',
                content: '이메일 주소를 입력해주세요.',
            });
            return;
        }
        if (!checkEmail(data.userId)) {
            setNotEnter("이메일");
            setAuthSetting(false);
            setCheckSetting(false);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            Modal.warning({
                title: '알림',
                content: '올바른 이메일 주소가 아닙니다.',
            });
            return;
        }
        onSendAuthCode();
    }
    const CheckAuthButton = async () => {
        setLoading(true);
        const tempData = {
            ...data,
            authCode: authCode
        }
        await user.checkAuthCode(tempData)
            .then((res)=>{
                setLoading(false);
                if(res && res.commonRespCode === "1") {
                    setTrueAuth(true);
                    setStart(false);
                    setCheckSetting(true);
                    setAuthSetting(false);
                    Modal.info({
                        title: '알림',
                        content: res.message,
                    });
                }else{
                    Modal.error({
                        title: '알림',
                        content: res.message,
                    });
                }
            })
    }
    const onSendAuthCode = async () => {
        const check = await onCheckEmail();
        if (check) return;
        setAuthSetting(true);
        setAuthBtnSetting(false);
        setBtnSetting("재전송");
        setMessage("");
        setTimer(299);
        setStart(false);
        await user.sendAuthCodeJoin(data)
            .then((res) => {
                if (res && res.commonRespCode === "1") {
                    store.timeStore.setTime(new Date(new Date().setMinutes(new Date().getMinutes() + 5)))
                    setAuthBtnSetting(true);
                    setStart(true);
                } else {
                    Modal.error({
                        title: '알림',
                        content: res.message,
                    });
                }
            })
    }
    const requestEstimate = async () => {
        let emptyValue = [];
        Object.keys(mappingField).forEach((key) => {
            if (data[key] === "" || data[key] === null || data[key] === undefined ) {
                if (key === "userId" || key === "userPwd") {
                    if (!memYn) {
                        emptyValue.push(mappingField[key]);
                    }
                }  else if (key === "userPassCheck") {
                    if (!memYn && userPassCheck === "") {
                        emptyValue.push(mappingField[key]);
                    }
                } else {
                    emptyValue.push(mappingField[key]);
                }
            }
        })
        if (emptyValue.length > 0) {
            if (emptyValue[0] !== "입찰 마감일") {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            setNotEnter(emptyValue[0]);
            Modal.warning({
                title: '알림',
                content: `${emptyValue[0]} 값을 입력해주세요.`,
            });
            return;
        }
        if (!memYn && data.userId !== "" && !checkEmail(data.userId)) {
            setNotEnter("이메일");
            window.scrollTo({ top: 0, behavior: 'smooth' });
            Modal.warning({
                title: '알림',
                content: '올바른 이메일 주소가 아닙니다.',
            });
            return;
        }
        if (!memYn && emailCheck) {
            setNotEnter("이메일");
            window.scrollTo({ top: 0, behavior: 'smooth' });
            Modal.warning({
                title: '알림',
                content: '이미 회원 가입된 이메일입니다. 로그인 후 입력해주세요.',
            });
            return;
        }
        if (!trueAuth && !memYn){
            setNotEnter("인증번호");
            window.scrollTo({ top: 0, behavior: 'smooth' });
            Modal.warning({
                title: '알림',
                content: '이메일 인증을 완료해주세요.',
            });
            return;
        }
        if (!memYn && data.userPwd !== userPassCheck) {
            setNotEnter("비밀번호");
            window.scrollTo({ top: 0, behavior: 'smooth' });
            Modal.warning({
                title: '알림',
                content: '비밀번호를 확인해주세요.',
            });
            return;
        }
        Modal.confirm({
            title: '확인',
            content: '견적요청 하시겠습니까?',
            onOk: () => {
                if (memYn) {
                    const tempData = {
                        ...data,
                        ...userData
                    }
                    delete tempData.bizPreviewUrl
                    delete tempData.policyPreviewUrl
                    delete tempData.email
                    delete tempData.password
                    requestApi(tempData);
                } else {
                    const tempData = {
                        ...data,
                        ...userData,
                        userStatusCode: 'US01'
                    }
                    delete tempData.bizPreviewUrl
                    delete tempData.policyPreviewUrl
                    requestApi(tempData);
                }
            }
        });
    }

    const requestApi = async (data) => {
        setLoading(true);
        await user.requestEstimate(data)
            .then((res) => {
                setLoading(false);
                if (res) {
                    if(res.commonRespCode === "SUCCESS") {
                        Modal.success({
                            title: '알림',
                            content: res.message,
                        });
                        if(userStoreData.userId === "1") {
                            navigate("/estimate/list");
                        } else {
                            navigate("/");
                        }
                    } else {
                        Modal.error({
                            title: '알림',
                            content: res.message,
                        });
                    }
                } else {
                    Modal.error({
                        title: '알림',
                        content: '오류가 발생하였습니다.',
                    });
                }
            })
    }

    const onCheckEmail = async () => {
        setCheckSetting(false);
        setAuthSetting(false);
        if (data.userId === "") return;
        let check = false;
        await user.searchUserIdCheck(data.userId).then((res) => {
            if (res) {
                if(res.commonRespCode === "0") {
                    setEmailCheck(true);
                    check = true;
                } else {
                    setEmailCheck(false);
                    check = false;
                }
            } else {
                Modal.error({
                    title: '알림',
                    content: '오류가 발생하였습니다.',
                });
            }
        })
        return check;
    }

    const onCheckPassword = async () => {
        if (data.userPwd.length === 0 || userPassCheck.length === 0) return;
        if (data.userPwd !== userPassCheck) {
            setPassCheck(true)
        } else {
            setPassCheck(false)
        }
    }

    const onClickImg = (type) => {
        let _width = '700';
        let _height = '1000';
        let _left = Math.ceil(( window.screen.width - _width )/2);
        let _top = Math.ceil(( window.screen.height - _height )/2);
        let path = ""
        switch (type) {
            case 'biz':
                path = data.bizPreviewUrl
                break;
            case 'policy':
                path = data.policyPreviewUrl
                break;
            default:
                break;
        }
        window.open(path, '_blank', `width = ${_width}, height = ${_height}, left = ${_left}, top = ${_top}`)
    }

    return(
        <Layout loading={loading}>
            <div className="common-wrapper page-estimate-request">
                <PageTitle title={`${title} 견적 요청하기`} subTitle={!memYn && "보험사 입찰 결과는 회원가입 후 확인 가능합니다"}/>
                <div className="common-form-wrap">
                    <Input
                        pageType="R"
                        title="제목"
                        className={notEnter === "제목" && "input-full input-red"}
                        placeholder="제목 입력"
                        value={data.title}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                title: e.target.value,
                            })
                        })}
                    />
                    {!memYn &&
                    <>
                        <Input
                            pageType="R"
                            title="이메일"
                            className={notEnter === "이메일" && "input-full input-red"}
                            placeholder="답변 받을 이메일 입력"
                            alert={emailCheck && "* 이미 회원 가입된 이메일입니다. 로그인 후 입력해주세요."}
                            value={data.userId}
                            onChange={e => {
                                if(e.target.value.length > 80) {
                                    Modal.warning({
                                        title: '알림',
                                        content: '이메일은 80자 이하만 가능합니다.',
                                    });
                                }
                                setData((prev) => {
                                    return ({
                                        ...prev,
                                        userId:  e.target.value.length > 80 ? "" : e.target.value,
                                    })
                                })
                            }}
                            authClick={AuthButton}
                            authName={btnSetting}
                        > {checkSetting && <div className="auth-text" style={{ marginBottom: "8px" }}><CheckCircleFilled style={{ fontSize: "20px" }}/> 인증이 완료되었습니다.</div>}</Input>
                        {authSetting && <Input
                            pageType="R"
                            id="authCode"
                            title="인증번호"
                            type="text"
                            className={notEnter === "인증번호" && "input-full input-red"}
                            value={authCode}
                            placeholder="이메일로 전송된 인증번호를 입력해주세요."
                            onChange={e => setAuthCode(e.target.value)}
                            authCheck={CheckAuthButton}
                            authBtnToggle={authBtnSetting}
                            alert={start && timer > 0 ? timeFormat(timer) : timerMessage}
                        />}
                        <Input
                            pageType="R"
                            title="임시 비밀번호"
                            className={notEnter === "비밀번호" && "input-full input-red"}
                            type="password"
                            placeholder="영문/숫자/특수문자 조합 8자 이상"
                            value={data.userPwd}
                            onChange={e => {
                                if(e.target.value.length > 25) {
                                    Modal.warning({
                                        title: '알림',
                                        content: '비밀번호는 25자 이하만 가능합니다.',
                                    });
                                }
                                setData((prev) => {
                                    return ({
                                        ...prev,
                                        userPwd: e.target.value.length > 25 ? "" : e.target.value,
                                    })
                                })
                            }}
                        />
                        <Input
                            pageType="R"
                            title="임시 비밀번호 확인"
                            className={notEnter === "비밀번호" && "input-full input-red"}
                            alert={passCheck && "* 비밀번호가 일치하지 않습니다."}
                            type="password"
                            placeholder="비밀번호를 한번 더 입력하세요."
                            value={userPassCheck}
                            onChange={e => setUserPassCheck(e.target.value)}
                            onBlur={onCheckPassword}
                        />
                    </>
                    }
                    <div className="form-block">
						<span className="block-title">
							사업자등록증 업로드
						</span>
                        <div className="block-content">
                            <div className="content-other">
                                {data.biz
                                    ? <p className="file-content" onClick={() =>onClickImg('biz')}>{data.biz.name}</p>
                                    : <p className="normal-content">* 등록된 사업자 등록증이 없습니다.</p>
                                }
                                <div className="file-upload-wrap">
                                    <span>* 이미지, PDF, ZIP 파일만 업로드 가능합니다.</span>
                                    <FileSelector id="license" onChange={e => uploadFile(e, 'biz')} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-block">
						<span className="block-title">
							갱신증권 업로드
						</span>
                        <div className="block-content">
                            <div className="content-other">
                                {data.policy
                                    ? <p className="file-content" onClick={() =>onClickImg('policy')}>{data.policy.name}</p>
                                    : <p className="normal-content">* 등록된 갱신증권이 없습니다.</p>
                                }
                                <div className="file-upload-wrap">
                                    <span>* 이미지, PDF, ZIP 파일만 업로드 가능합니다.</span>
                                    <FileSelector id="renewal" onChange={e => uploadFile(e, 'policy')} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-block">
						<span className="block-title">
							3개년 사고사항
						</span>
                        <div className="block-content">
                            <textarea
                                className="textarea"
                                style={{height: 130}}
                                placeholder="3개년 사고사항을 입력해 주세요."
                                value={data.accident}
                                onChange={e => setData((prev) => {
                                    return ({
                                        ...prev,
                                        accident: e.target.value,
                                    })
                                })} />
                        </div>
                    </div>
                    <div className="form-block">
						<span className="block-title">
							입찰 마감일 입력
						</span>
                        <div className="block-content">
                            <div className="content-other">
                                {data.deadlineDt
                                    ? <p className="date-content">{data.deadlineDt}</p>
                                    : <p>* 입력된 날짜가 없습니다.</p>
                                }
                                <label style={{position: 'relative'}} onClick={e => e.preventDefault()}>
                                    <button className="btn btn-gray" onClick={() => visibleDatePicker(true)}>날짜보기</button>
                                    <DatePicker
                                        locale="ko"
                                        ref={_calendar}
                                        minDate={nextDay}
                                        value={data.deadlineDt}
                                        onChange={e => setData((prev) => {
                                            return ({
                                                ...prev,
                                                deadlineDt: commonDateFormat(e, '-'),
                                            })
                                        })}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="form-block">
						<span className="block-title">
							요청사항
						</span>
                        <div className="block-content">
                            <textarea
                                className="textarea"
                                style={{height: 130}}
                                placeholder="요청사항을 입력해 주세요."
                                value={data.comment}
                                onChange={e => setData((prev) => {
                                    return ({
                                        ...prev,
                                        comment: e.target.value,
                                    })
                                })} />
                        </div>
                    </div>
                </div>
                <div className="form-btn-wrap">
                    <button className="btn btn-orange" onClick={requestEstimate}>무료 견적 요청하기</button>
                </div>
            </div>
        </Layout>
    )
})

export default EstimateRequest;
import {makeAutoObservable} from "mobx";

// 로그인시 기본 정보 가지고 페이지 이동할 때 사용
class User {
    userData = {};
    constructor() {
        makeAutoObservable(this);
    }
    setUser = (data) => {
        this.userData = data;
    }
    getUser = () => {
        return this.userData;
    }
}

const userStore = new User();
export default userStore;
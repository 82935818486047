import {Navigate, Outlet} from "react-router-dom";
import {Modal} from "antd";
import Cookies from "js-cookie";

const ProtectRoute = ({ authId }) => {
    const groupId = Cookies.get('groupId');
    const auth = authId.indexOf(Number(groupId));
    if (auth < 0) {
        Modal.error({
            title: '알림',
            content: '권한이 없습니다. 다시 로그인 해주세요.',
        });
        return <Navigate to="/login" />
    }
    return <Outlet />
}

export default ProtectRoute;
import {MinusOutlined} from '@ant-design/icons';
import React from "react";
import {useEffect} from "react";
import {Modal} from "antd";
import {ReactComponent as Facebook} from "../../assets/facebook.svg";
import {ReactComponent as KakaoIcon} from "../../assets/kakao.svg";
import {ReactComponent as NaverBlog} from "../../assets/naverblog.svg";
import {ReactComponent as Twitter} from "../../assets/x.svg";
import {ReactComponent as Message} from "../../assets/message.svg";

const Footer = () => {
    const {Kakao} = window;
    const shareLink = process.env.REACT_APP_EVA_URL;

    useEffect(() => {
        Kakao.cleanup();
        Kakao.init(process.env.REACT_APP_KAKAO_SHARE_INIT);
    }, []);

    const shareKakao = () => {
        Kakao.Share.sendCustom({
            templateId: 100364,
            templateArgs: {
                title: '기업보험 최저가로 견적 받으세요',
                description: '안녕하세요, 최저가 기업보험상품 비교 견적 플랫폼 "이봐 코리아" 입니다.',
            },
        });
    }
    const shareFacebook = () => {
        window.open(process.env.REACT_APP_FACEBOOK_SHARE_URL + shareLink);
    }
    const shareNaverBlog = () => {
        window.open(process.env.REACT_APP_NAVER_SHARE_URL + `${encodeURIComponent("기업보험 최저가로 견적 받으세요")}&url=${encodeURIComponent(shareLink)}`);
    }
    const shareTwitter = () => {
        window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent("기업보험 최저가로 견적 받으세요")}&url=${encodeURIComponent(shareLink)}`);
    }
    const shareSMS = () => {
        const userAgent = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
        if (userAgent.indexOf('android') > -1) {
            //안드로이드
            window.location.href = `sms:?body=기업보험 최저가로 견적 받으세요, 이봐코리아 ${shareLink}`
        } else if (userAgent.indexOf("iphone") > -1 || userAgent.indexOf("ipad") > -1 || userAgent.indexOf("ipod") > -1 ) {
            //IOS
            window.location.href = `sms:&body=기업보험 최저가로 견적 받으세요, 이봐코리아 ${shareLink}`
        } else {
            //아이폰, 안드로이드 외 모바일
            Modal.error({
                title: "알림",
                content: "웹에서는 메세지를 보낼 수 없습니다."
            })
        }
    }

    return (
        <>
            <footer className="footer-wrap">
                <div className="common-wrapper">
                    <div>
                        <div className="main-bot">
                            <div className="call">
                                <img src="/images/login/call.svg" className="call-img" alt=""/>
                                <b className="cera out-line-none" style={{textDecoration: "none"}}>
                                    02<MinusOutlined style={{
                                    fontSize: "10px",
                                    color: "white",
                                }}/>797<MinusOutlined style={{
                                    fontSize: "10px",
                                    color: "white",
                                }}/>1248
                                </b>
                            </div>
                            <div className="call-more">
                                <dl>
                                    <dt className="cera" style={{marginRight: "5px"}}>E-mail</dt>
                                    <dd className="cera" style={{textDecoration: "none"}}>
                                        <span>info</span>@<span>evakorea.co.kr</span></dd>
                                </dl>
                                <dl>
                                    <dd className="cera"><span>(Mon~Fri. am09:00~pm06:00)</span></dd>
                                </dl>
                            </div>
                        </div>
                        <address>
                            <ul>
                                <li>
                                    <b>회사명</b>
                                    <span>이봐코리아</span>
                                </li>
                                <li>
                                    <b>대표자</b>
                                    <span>류영식</span>
                                </li>
                                <li>
                                    <b>사업자 등록안내</b>
                                    <span>839-94-01537</span>
                                </li>
                                <li>
                                    <b>주소</b>
                                    <span>경기도 용인시 기흥구 동백4로 26, 3105-1602</span>
                                </li>
                            </ul>
                            {/*<span className="copyright">Copyrightⓒ2021 EVA Korea All rights reserved.</span>*/}
                        </address>
                    </div>
                    <ul className="share-post">
                        <li>
                            <NaverBlog width="16px" height="16px" fill="#fff" onClick={shareNaverBlog} />
                        </li>
                        <li style={{ marginLeft: "2px" }}>
                            <KakaoIcon width="16px" height="16px" fill="#fff" onClick={shareKakao} />
                        </li>
                        <li>
                            <Facebook width="16px" height="16px" fill="#fff" onClick={shareFacebook} />
                        </li>
                        <li>
                            <Twitter width="16px" height="16px" fill="#fff" onClick={shareTwitter} />
                        </li>
                        <li>
                            <Message width="16px" height="16px" fill="#fff" onClick={shareSMS} />
                        </li>
                    </ul>
                </div>
            </footer>
        </>
    )
}

export default Footer;
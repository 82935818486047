export const FileInput = (props) => {
    const { title, value, path } = props;
    const onClickImg = () => {
        let _width = '700';
        let _height = '1000';
        let _left = Math.ceil(( window.screen.width - _width )/2);
        let _top = Math.ceil(( window.screen.height - _height )/2);

        window.open(path, '_blank', `width = ${_width}, height = ${_height}, left = ${_left}, top = ${_top}`)
    }

    return (
        <div className="form-block only-string">
            <span className="block-title">
                {title}
            </span>
            <div className="block-content">
                <div className="content-other" style={{ margin: "0px" }}>
                    {path
                        ? <p className="file-content" onClick={onClickImg}>{value}</p>
                        : <p className="file-content">* 등록된 문서가 없습니다.</p>
                    }
                </div>
            </div>
        </div>
    )
}
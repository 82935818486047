import {useNavigate} from "react-router-dom";

const ToggleMenu = ({ setInsuranceMenu, menu }) => {
    const navigate = useNavigate();

    const onPageChange = (key) => {
        navigate(`/insurance/${key}`);
    }

    return (
        <>
            <div className="toggle-menu-behind" onClick={() => setInsuranceMenu(false)}/>
            <div className="toggle-menu-wrap">
                <div className="toggle-menu-front">
                    <ul>
                        {menu?.map((item) => {
                            return(
                                <div className="toggle-list-wrap" key={item.key}>
                                    <li className="toggle-menu-first">{item.insuranceTypeName}</li>
                                    <ul className="toggle-menu-list">
                                        {item.itemList.map((i) => {
                                            return(<li onClick={() => {onPageChange(i.key)}} key={i.key}>{i.codeName}</li>)
                                        })}
                                    </ul>
                                </div>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default ToggleMenu;
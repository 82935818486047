import AdminLayout from "../../../components/layouts/AdminLayout";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Editor} from "react-draft-wysiwyg";
import {EditorState, ContentState, convertToRaw} from "draft-js";
import draftjsToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Input from "../../../components/input/Input";
import {auth, insurance} from "../../../services/api";
import {store} from "../../../stores";
import {useLocation} from 'react-router-dom';
import FileSelector from "../../../components/fileSelector/FileSelector";
import {Modal} from "antd";
import {getCode, setCode} from "../../../utils/code";

const InsuranceEdit = () => {
    const {state} = useLocation();
    const {insuranceCode, preInsuranceName} = state;
    const user = store.userStore.getUser();
    const code = getCode();
    const allowFileList = store.allowFileListStore.getAllowFileList();
    const categorySelectData = []
    const editSelectData = [
        //TODO 백에서 스키마 필드값이랑 코멘트 조회해서 map 형식으로 반환???
        {
            value: "introduction",
            title: "상품소개"
        }, {
            value: "signTarget",
            title: "가입이 필요한 곳"
        }, {
            value: "compensation",
            title: "보상하는 손해"
        }, {
            value: "effect",
            title: "개요/필요성/효과"
        }, {
            value: "notCompensation",
            title: "주요 보상하지 아니하는 손해"
        }, {
            value: "basicDoc",
            title: "기초서류"
        }, {
            value: "etcDocAtt",
            title: "설문서, 제안서 및 약관"
        }, {
            value: "subTitle",
            title: "보험설명(부제목)"
        }, {
            value: "backgroundAtt",
            title: "배경 이미지 변경"
        }, {
            value: "slideText",
            title: "슬라이드 텍스트 변경"
        }
    ]
    const [insuranceInfo, setInsuranceInfo] = useState({});
    // 대분류
    const [catData, setCatData] = useState([]);
    const [selectCat, setSelectCat] = useState("");
    // 수정할 카테고리
    const [insuranceEdit, setEdit] = useState([]);
    const [selectEdit, setSelectEdit] = useState("");
    const [initData, setInit] = useState([]);
    const [insuranceName, setInsuranceName] = useState(preInsuranceName);
    const [editState, setEditState] = useState(EditorState.createEmpty());
    const navigate = useNavigate();

    useEffect(() => {
        for(const target in code){
            if(target.slice(1) % 100 === 0){
                categorySelectData.push({
                    value : target,
                    catName : code[target]
                });
            }
        }
        setCatData(categorySelectData);
        setEdit(editSelectData);

        //default value가 안들어가서 useEffect에서 선택 초기값 설정
        setSelectCat(categorySelectData[0].value);
        setSelectEdit(editSelectData[0].value);

        loadInsuranceInfo();
    }, [])

    const loadInsuranceInfo = async ()=>{
        await insurance.searchInsuranceDetail(insuranceCode).then((res)=>{
            setSelectCat(res.insuranceTypeCode);
            setInsuranceInfo(res);
            setInit(res);
            setEditState(convertHtmlToEditor(res[editSelectData[0].value]));
        });
    }

    const convertHtmlToEditor = (value) => {
        const blocksFromHtml = htmlToDraft(value);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

        return EditorState.createWithContent(contentState);
    }

    const uploadFile = async (e) => {
        if(!e.target.files[0]) return;
        //이미지 파일만 받기
        let _lastDot = e.target.files[0].name.lastIndexOf('.');
        let _fileExt = e.target.files[0].name.substring(_lastDot+1, e.target.files[0].name.length);
        if(!allowFileList.includes(_fileExt)){
            Modal.warning({
                title: '알림',
                content: '이미지, PDF, ZIP 파일만 업로드 가능합니다.',
            });
            return;
        }
        //이미지 사이즈 확인
        if((e.target.files[0].size/1024/1024).toFixed(2) > 10){
            Modal.warning({
                title: '알림',
                content: (
                    <div>
                        <p>해당 파일은 {(e.target.files[0].size / 1024 / 1024).toFixed(2)}MB 입니다.</p>
                        <p>10MB 미만의 파일만 업로드 가능합니다.</p>
                    </div>
                )
            });
            return;
        }
        const previewUrl = window.URL.createObjectURL(e.target.files[0])
        setInsuranceInfo((prev) => {
            return ({
                ...prev,
                newAtt: e.target.files[0],
                previewUrl,
            })
        });
    }

    const onEditInsurance = async () => {
        if (selectEdit === "etcDocAtt" || selectEdit === "backgroundAtt") {
            if (!insuranceInfo.newAtt) {
                Modal.warning({
                    title: '알림',
                    content: '첨부파일이 없습니다. 다시 확인해주세요.',
                });
                return;
            }
        }
        const data = {
            userId : user.userId,
            insuranceTypeCode : selectCat,
            insuranceCode : insuranceCode,
            columnName : selectEdit,
            insuranceName : insuranceName,
            content : draftjsToHtml(convertToRaw(editState.getCurrentContent())).replace(/\n?\r*/g, '').replace("<p></p>", "<br>"),
            preInsuranceName : preInsuranceName
        }
        if(selectEdit === "slideText"){
            data.content = convertToRaw(editState.getCurrentContent()).blocks[0].text;
        }
        if(insuranceInfo.newAtt != null){
            data.newAtt = insuranceInfo.newAtt;
        }
        await insurance.updateInsurance(data).then(async (res)=>{
            if(res.commonRespCode === "1"){
                Modal.success({
                    title: '알림',
                    content: '수정되었습니다.',
                });
                await auth.fetchCode().then((code)=>{
                    const reCodeMap = code.reduce((acc, value) => {
                        if (acc) {
                            acc[value.codeId] = value.codeName;
                        } else {
                            acc = {};
                            acc[value.codeId] = value.codeName;
                        }
                        return acc;
                    }, {});
                    setCode(reCodeMap);
                    navigate(`/admin/insurance/detail/${insuranceCode}`);
                });
            }else{
                Modal.error({
                    title: '알림',
                    content: '오류가 발생하였습니다.',
                });
            }
        });
    }

    const onClickImg = (path) => {
        let _width = '700';
        let _height = '1000';
        let _left = Math.ceil(( window.screen.width - _width )/2);
        let _top = Math.ceil(( window.screen.height - _height )/2);
        if(!insuranceInfo.previewUrl) {
            window.open(path, '_blank', `width = ${_width}, height = ${_height}, left = ${_left}, top = ${_top}`)
        } else {
            window.open(insuranceInfo.previewUrl, '_blank', `width = ${_width}, height = ${_height}, left = ${_left}, top = ${_top}`)
        }
    }

    return (
        <AdminLayout title="기업 보험 상품 수정">
            <div className="page-insurance-edit">
                <div className="page-insurance-select">
                    <select className="insurance-select" value={selectCat} onChange={(e) => setSelectCat(e.target.value)} style={{ backgroundSize: "20px 20px" }}>
                        { catData.length > 0 && catData.map((item) => {
                            return (
                                <option value={`${item.value}`}>{item.catName}</option>
                            )
                        })}
                    </select>
                    <select className="insurance-select" value={selectEdit} onChange={(e) => {
                        setSelectEdit(e.target.value);
                        if(e.target.value === "etcDocAtt" || e.target.value === "backgroundAtt") {
                            setInsuranceInfo((prev) => {
                                return ({
                                    ...prev,
                                    newAtt: null,
                                    previewUrl: null,
                                })
                            });
                            return;
                        }
                        if(insuranceInfo[e.target.value] === null) {
                            setEditState(convertHtmlToEditor("<p></p>"));
                            return;
                        }
                        setEditState(convertHtmlToEditor(insuranceInfo[e.target.value]));
                    }} style={{ backgroundSize: "20px 20px"}}>
                        { insuranceEdit.length > 0 && insuranceEdit.map((item) => {
                            return (
                                <option value={`${item.value}`}>{item.title}</option>
                            );
                        })}
                    </select>
                </div>
                <div className="btn-insurance-edit">
                    <button className="btn btn-small btn-line-orange" onClick={onEditInsurance}>수정</button>
                    <button className="btn btn-small btn-orange" onClick={() => navigate(`/admin/insurance/products`)}>취소</button>
                </div>
            </div>
            <Input
                pageType="J"
                placeholder="보험상품 이름"
                value={insuranceName}
                onChange={e => setInsuranceName(e.target.value)}
            />
            <div style={selectEdit.includes("Att")?{ padding: "10px 0", display:"none"}:{padding: "10px 0", display: "block"}}>
                <Editor
                    editorState={editState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={setEditState}
                    toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign','colorPicker', 'link', 'history'],
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                    }}
                />
            </div>
            <div className="block-content" style={selectEdit.includes("etcDocAtt") ? { display:"block" } : { display: "none" }}>
                <div className="content-other" style={{ marginTop: "10px" }}>
                    <div className="file-controll-wrap">
                        <FileSelector id="etcDoc" onChange={e => uploadFile(e)} />
                    </div>
                    {insuranceInfo.previewUrl || initData?.etcDocAttPath
                        ? <p className="file-content" onClick={() => onClickImg(initData.etcDocAttPath)}>{insuranceInfo?.newAtt ? insuranceInfo?.newAtt?.name : initData?.etcDocAttName}</p>
                        : <p className="file-content">* 등록된 문서가 없습니다.</p>
                    }
                </div>
            </div>
            <div className="block-content" style={selectEdit.includes("backgroundAtt") ? { display:"block" } : { display: "none" }}>
                <div className="content-other" style={{ marginTop: "10px" }}>
                    <div className="file-controll-wrap">
                        <FileSelector id="etcDoc" onChange={e => uploadFile(e)} />
                    </div>
                    {insuranceInfo.previewUrl || initData?.backgroundAttPath
                        ? <p className="file-content" onClick={() => onClickImg(initData.backgroundAttPath)}>{insuranceInfo?.newAtt ? insuranceInfo?.newAtt?.name : initData?.backgroundAttName}</p>
                        : <p className="file-content">* 등록된 문서가 없습니다.</p>
                    }
                </div>
            </div>
        </AdminLayout>
    )
}

export default InsuranceEdit;
import Layout from "../../components/layouts/Layout";
import React, { useState, useEffect } from "react";
import PageTitle from "../../components/pageTitle/PageTitle";
import {Modal, Table, Tag} from "antd";
import {estimate} from "../../services/api";
import translator from "../../utils/translator";
import {dateSorter, makeDday, stringSorter} from "../../utils";
import {store} from "../../stores";
import {useNavigate} from "react-router-dom";
import {isLogin} from "../../utils/auth";
import DashBoard from "../../components/dashboard/DashBoard";
import {getCode} from "../../utils/code";

const EstimateList = () => {
    const initialData = {
        userId: "",
        pageNo: 1,
        limit: 10,
        start: 0,
        searchKey: "",
        searchValue: ""
    }
    const initialList = {
        items: [],
        limit: 10,
        pageNo: 1,
        totalCount: 0,
    }
    const [width, setWidth] = useState(window.innerWidth);
    const [header, setHeader] = useState([]);
    const [data, setData] = useState(initialData);
    const [list, setList] = useState(initialList);
    const [onlyList,setOnlyList] = useState([]);
    const [boardList, setBoardList] = useState([]);
    const [page, setPageNum] = useState(1);
    const [pop, setPop] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        let timeoutId;
        const handleResize = () => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                setWidth(window.innerWidth);
            }, 300)
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.clearTimeout(timeoutId);
        };
    }, []);

    const fetchData = async (data) => {
        const code = getCode();
        if(data.searchValue !== "") {
            setPop(true);
        } else {
            setPop(false);
        }
        const result = await estimate.searchEstimate(data).then((res) => {
            const resultData = res.items.map((item) => {
                return {
                    ...item,
                    key: item.esCode,
                    esStatusCode: code[item.esStatusCode],
                    dday: makeDday(item.deadlineDt)
                }
            })
            setOnlyList(resultData);
            return res;
        });
        setList(result);
    }

    const fetchBoard = async (userId) => {
         await estimate.searchEstimateStep(userId).then((res) => {
             const resultData = res.data[0].map((item) => {
                 return {
                     ...item,
                     key: item.code,
                 }
             })
             setBoardList(resultData);
             return res;
        });
    }

    useEffect(()=>{
        if(width < 1024){
            setHeader(["esTitle", "deadlineDt", "esStatusCode", "biddingCount"]);
        }else{
            setHeader([
                "esCode",
                "insertDt",
                "esTitle",
                "deadlineDt",
                "dday",
                "esStatusCode",
                "biddingCount"
            ]);
        }
    }, [width]);

    useEffect(() => {
        checkPageAuth();
    }, [page])

    const checkPageAuth = async () => {
        const auth = await isLogin();
        const user = store.userStore.getUser();
        if (!auth || !user?.groupId) {
            Modal.error({
                title: '알림',
                content: '권한이 없습니다. 다시 로그인 해주세요.',
            });
            navigate("/login")
        } else {
            searchList(page, user.userId);
        }
    }

    const searchList = (pageNo, id) => {
        const tempData = {
            ...data,
            userId: id,
            pageNo,
        }
        setData(tempData);
        setPageNum(pageNo);
        fetchData(tempData);
        fetchBoard(id);
    }

    const onPageChange = async (data) => {
        navigate("/estimate/list/detail/"+data.esCode, { state : {estimateData: data} });
    }
    
    const onClickBoard = (item) => {
        const tempData = {
            ...data,
            pageNo: 1,
            searchKey : `es_status_code`,
            searchValue : item.code,
        }
        setData(tempData);
        setPageNum(1);
        fetchData(tempData);
    }

    const columns = header.map((key) => {
        if(key === "insertDt" || key === "deadlineDt") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: "140px",
                sorter: (a, b) => dateSorter(a, b, key),
                align: 'center',
            }
        } else if (key === "esStatusCode") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                width: "140px",
                render: (value) => {
                    let color;
                    let bordered = true;
                    if (value === "계약요청") {
                        color = 'green';
                    } else if (value === "계약확인") {
                        color = 'gold';
                    } else if (value === "계약완료") {
                        color = 'volcano';
                    } else if (value === "입찰요청") {
                        color = 'blue';
                    }else if (value === "입찰확인") {
                        color = 'purple';
                    }else if (value === "입찰완료") {
                        color = 'magenta';
                    }else if (value === "입찰종료") {
                        color = 'default';
                        bordered = false;
                    }
                    return (
                        <Tag color={color} key={value} bordered={bordered}>
                            {value}
                        </Tag>
                    );
                }
            }
        } else if (key === "esCode") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: "180px",
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
            }
        } else if (key === "dday") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: "112px",
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
                render: (value) => {
                    return (
                        <div key={value}>
                            {value <= 0 ? "입찰마감" : value}
                        </div>
                    );
                }
            }
         }else if (key === "biddingCount") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: "100px",
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
            }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: width > 1024 ? "auto" : "200px",
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
            }
        }
    })

    const onPopClick = () => {
        const user = store.userStore.getUser();
        const tempData = {
            ...initialData,
            userId: user.userId,
        }
        setData(tempData);
        setPageNum(1);
        fetchData(tempData);
        fetchBoard(user.userId);
    }

    return(
        <Layout>
            <div className="common-wrapper page-request-list">
                <PageTitle title="내 견적요청 리스트" pop={pop} onClick={onPopClick} />
                {boardList.length > 0 &&
                    <DashBoard
                        data={boardList}
                        onClickBoard={onClickBoard}
                    />}
                <div className="table-wrapper estimate-bidding-table" style={{ margin : "40px 0"}}>
                    <Table
                        className="inner-wrapper"
                        dataSource={onlyList}
                        columns={columns}
                        style={{ cursor: "pointer" }}
                        rowClassName={record => record.esStatusCode === "입찰종료" && "disabled-row"}
                        pagination={{
                            current: page,
                            onChange: (page) => setPageNum(page),
                            pageSize: list.limit,
                            total: list.totalCount,
                            position: ["bottomCenter"],
                            showSizeChanger: false,
                        }}
                        onRow={(record) => {
                            if(record.esStatusCode !== "입찰종료") {
                                return {
                                    onClick: () => onPageChange(record)
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </Layout>
    )
}

export default EstimateList;

import {makeAutoObservable} from "mobx";

// edit 부분이나 상세 페이지로 이동할 때 사용
class Estimate {
    userData = {};
    constructor() {
        makeAutoObservable(this);
    }
    setEstimate = (data) => {
        this.userData = data;
    }
    getEstimate = () => {
        return this.userData;
    }
    deleteEstimate = () => {
        this.userData = {};
    }
}

const EstimateStore = new Estimate();
export default EstimateStore;
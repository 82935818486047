import Slider from "react-slick";
import React from "react";
const RollingTrade = React.memo(({ settings, list }) => {
    if (settings === {}) return;
    return (
        <>
            <div className={"rollingTradeArea"}>
                <Slider {...settings}>
                    {list.map((item, idx) => {
                        return (
                            <div className={"tradeCard"} key={idx}>
                                <div className={"cardTop"}>
                                    <div className={"newTag"}>NEW</div>
                                    <h3 className={"name"}>{item.userName} 님,</h3>
                                    <span className={"dateText"}>{item.insertDt?.slice(0, 11)}</span>
                                </div>
                                <div className={"cardContent"}>
                                    <span className={"insu"}>{item.insuranceTypeCode}</span>
                                    <span className={"status"}>{item.statusSmall}</span>
                                    <span className={"endText"}>되었습니다.</span>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </>
    )
});

export default RollingTrade;


import Footer from "../footers/Footer";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import useScreen from "../hooks/useScreen";
import ToggleMenu from "../toggleMenu/ToggleMenu";
import {store} from "../../stores";
import {observer} from "mobx-react";
import {cookieLogout, isLogin} from "../../utils/auth";
import Loading from "../loading/loading";
import {codeFetch} from "../../utils";
import translator from "../../utils/translator";
import {ConfigProvider, FloatButton, Menu, Modal, Switch} from 'antd';
import {
    AuditOutlined,
    InboxOutlined,
    LogoutOutlined,
    MailOutlined,
    LoginOutlined,
    UserOutlined,
    FileTextOutlined,
    UserAddOutlined
} from "@ant-design/icons";
import {auth, user as userApi} from "../../services/api";


const Layout = observer((props) => {
    const user = store.userStore.getUser();
    const [busiYn, setBusiYn] = useState(false);
    const [logoSize, setLogoSize] = useState(null);
    const { screenSize } = useScreen();
    const [toggleHiddenMenu, setToggleHiddenMenu] = useState(false);
    const navigate = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);
    const [insuranceToggle, setInsuranceToggle] = useState(false);
    const [insuranceList, setInsuranceList] = useState([]);
    const [items, setItems] = useState([]);
    const [floatingText, setText] = useState("견적요청");
    const [naverToggle, setNaverToggle] = useState(false);
    const [kakaoToggle, setKakaoToggle] = useState(false);
    const [selectMenu, setSelectMenu] = useState((localStorage.getItem('selectMenu') !== null && localStorage.getItem('selectMenu').length>0)?localStorage.getItem('selectMenu'):localStorage.setItem('selectMenu', ''));

    useEffect(() => {
        let timeoutId;
        const handleResize = () => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                setWidth(window.innerWidth);
            }, 300)
        };

        const handleClick = (e)=>{
            if(document.querySelector(".side-wrap") !== null && !e.target.classList.contains("side-wrap") && !e.target.classList.contains("menuBtn") && !e.target.classList.contains("ant-menu-title-content") && !e.target.classList.contains("ant-menu-submenu-title") && !e.target.classList.contains("ant-menu-submenu-arrow")){
                hideMenu();
            }
        }

        window.addEventListener("resize", handleResize);
        window.addEventListener("click", handleClick);

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("click", handleClick);
            window.clearTimeout(timeoutId);
        };
    }, []);

    useEffect(()=>{
        localStorage.setItem('selectMenu', selectMenu);
        store.menuStore.setSelectMenu(localStorage.getItem('selectMenu'));
    }, [selectMenu]);

    useEffect(()=>{
        if(width < 1024){
            hideMenu();
            setInsuranceToggle(false);
        }
    }, [width]);

    const hideMenu = ()=>{
        const html = document.getElementsByTagName('html')[0];
        html.classList.remove('show-menu');
        document.querySelector(".side-wrap").style.left = "-200px";
    }

    const openMenu = () => {
        const sideMenu = document.querySelector('.side-wrap');
        const html = document.getElementsByTagName('html')[0];
        html.classList.toggle('show-menu');
        sideMenu.style.left = "0px";
    }


    useEffect(() => {
        if (screenSize.width === null) return;
        if (screenSize.width < 1024) {
            setLogoSize({width: 55, height: 22});
        } else {
            setLogoSize({width: 88, height: 44});
        }
    }, [screenSize]);

    useEffect(() => {
        setInsuranceToggle(false);
        checkPageAuth();
    }, [window.location.href]);

    useEffect(() => {
        if(user && user.groupId === "2") {
            setBusiYn(true);
        }
        codeFetch();
        fetchMenu();
        getMenuItems();
        getUserLogin();
    }, [user])

    const fetchMenu = async () => {
        await auth.fetchInsuranceMenu().then((res) => {
            const renewData = res.map((item) => {
                return {
                    ...item,
                    key: item.insuranceTypeCode,
                    itemList: item.itemList.map((item2) => {
                        return {
                            ...item2,
                            key: item2.codeId,
                        }
                    })
                }
            })
            setInsuranceList(renewData);
            getInsuranceItem(renewData);
        })
    }

    const checkPageAuth = async () => {
        const auth = await isLogin();
        if (auth) {
            const user = store.userStore.getUser();
            if (user.groupId !== "2") {
                setBusiYn(false);
            }
        }
    }

    const getUserLogin = async () => {
        await userApi.searchUserSnsLogin(user.userId).then((res) => {
            if (res && !res.data[0]) return;
            setNaverToggle(res.data[0].naverUseYn === "Y" ? true : false);
            setKakaoToggle(res.data[0].kakaoUseYn === "Y" ? true : false);
        })
    }

    const onChangeLoginToggle = (checked, platform) => {
        if (checked) {
            switch (platform) {
                case "naver" :
                    setNaverToggle(checked);
                    const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID
                    const REDIRECT_URI = process.env.REACT_APP_NAVER_CALLBACK_URL
                    let naver_id_login = new window.naver_id_login(process.env.REACT_APP_NAVER_CLIENT_ID, process.env.REACT_APP_NAVER_CALLBACK_URL);
                    const STATE = naver_id_login.getUniqState();
                    const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&state=${STATE}&redirect_uri=${REDIRECT_URI}`;
                    window.location.href = NAVER_AUTH_URL;
                    break;
                case "kakao" :
                    setKakaoToggle(checked);
                    const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;
                    const KAKAO_REDIRECT_URI = process.env.REACT_APP_KAKAO_REDIRECT_URI;
                    const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_CLIENT_ID}&redirect_uri=${KAKAO_REDIRECT_URI}&response_type=code`
                    window.location.href = KAKAO_AUTH_URL;
                    break;
                default:
                    break;
            }
        } else {
            Modal.confirm({
                title: '확인',
                content: '연동 해제하시겠습니까?',
                onOk: async () => {
                    const newData = {
                        userId: user.userId,
                        platform,
                    }
                    await userApi.deleteUserSnsLogin(newData).then((res) => {
                        if (res) {
                            if(res.commonRespCode === "1") {
                                switch (platform) {
                                    case "naver" :
                                        setNaverToggle(checked);
                                        break;
                                    case "kakao" :
                                        setKakaoToggle(checked);
                                        break;
                                    default:
                                        break;
                                }
                                Modal.success({
                                    title: '알림',
                                    content: '연동이 해제되었습니다.',
                                });
                            } else {
                                Modal.error({
                                    title: '알림',
                                    content: res.message,
                                });
                            }
                        } else {
                            Modal.error({
                                title: '알림',
                                content: '오류가 발생하였습니다.',
                            });
                        }
                    })
                }
            });
        }
    }

    const onToggleMenu = () => {
        setInsuranceToggle((prev) => {return !prev})
    }

    const onClickIcon = () => {
        setToggleHiddenMenu(!toggleHiddenMenu)
        if (insuranceToggle) {
            setInsuranceToggle(false);
        }
    }

    const logout = () => {
        setBusiYn(false);
        cookieLogout();
        navigate("/")
    }
    
    const getInsuranceItem = (data) => {
        let addMenu = [];
        data.forEach((item) => {
            let child = [];
            item.itemList.forEach((data) => {
                child.push(getItem(data.codeName, data.codeId))
            })
            addMenu.push(getItem(item.insuranceTypeName, item.insuranceTypeCode, "", child))
        })
        getMenuItems(addMenu);
    }

    function getItem(label, key, icon, children, type) {
        return {
            key,
            icon,
            children,
            label,
            type,
        };
    }

    const getMenuItems = (data) => {
        switch (user?.groupId) {
            case "0" :
                setItems([
                    getItem('견적요청', 'request', <AuditOutlined />),
                    getItem('문의하기', 'ask', <MailOutlined />),
                    getItem('기업보험상품', 'insurance', <InboxOutlined />, data),
                    getItem('내 견적요청 현황', 'estimate_list', <FileTextOutlined />),
                    getItem('로그아웃', 'logout', <LogoutOutlined />),
                ]);
                break;
            case "1" :
                setItems([
                    getItem('견적요청', 'request', <AuditOutlined />),
                    getItem('문의하기', 'ask', <MailOutlined />),
                    getItem('기업보험상품', 'insurance', <InboxOutlined />, data),
                    getItem('내 견적요청 현황', 'estimate_list', <FileTextOutlined />),
                    getItem('마이페이지', 'my_page', <UserOutlined />),
                    getItem('로그아웃', 'logout', <LogoutOutlined />),
                ]);
                break;
            case "2" : 
                setItems([
                    getItem('기업보험상품', 'insurance', <InboxOutlined />, data),
                    getItem('견적리스트', 'business_list', <FileTextOutlined />),
                    getItem('입찰현황', 'business_status', <FileTextOutlined />),
                    getItem('마이페이지', 'my_page', <UserOutlined />),
                    getItem('로그아웃', 'logout', <LogoutOutlined />),
                ]);
                break;
            case "3" :
                setItems([
                    getItem('견적요청', 'request', <AuditOutlined />),
                    getItem('문의하기', 'ask', <MailOutlined />),
                    getItem('기업보험상품', 'insurance', <InboxOutlined />, data),
                    getItem('내 견적요청 현황', 'estimate_list', <FileTextOutlined />),
                    getItem('관리자페이지', 'dash', <UserOutlined />),
                    getItem('마이페이지', 'my_page', <UserOutlined />),
                    getItem('로그아웃', 'logout', <LogoutOutlined />),
                ])
                break;
            default :
                setItems([
                    getItem('견적요청', 'request', <AuditOutlined />),
                    getItem('문의하기', 'ask', <MailOutlined />),
                    getItem('기업보험상품', 'insurance', <InboxOutlined />, data),
                    getItem('로그인', 'login', <LoginOutlined />),
                    getItem('회원가입', 'join', <UserAddOutlined />),
                ])
                break;
        }
    }

    const [openKeys, setOpenKeys] = useState(['']);

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        setOpenKeys([latestOpenKey]);
    };

    const onMenuSelect = (selectObj) => {
        switch (selectObj.key){
            case 'request':
                navigate("/estimate/request");
                break;
            case 'ask':
                navigate("/ask/list");
                break;
            case 'estimate_list':
                navigate("/estimate/list");
                break;
            case 'business_list':
                navigate("/business/estimate/list");
                break;
            case 'business_status':
                navigate("/business/estimate/status");
                break;
            case 'my_page':
                navigate("/user/my-page");
                break;
            case 'dash':
                navigate("/admin/main");
                break;
            case 'join':
                navigate("/join");
                break;
            case 'login':
                navigate("/login");
                break;
            case 'logout':
                logout();
                break;
            default :
                navigate(`/insurance/${selectObj.key}`);
                break;
        }
        if(selectObj.key !== 'logout') {
            setSelectMenu(selectObj.key);
        }else{
            setSelectMenu('');
        }
    }
    const floatEstimate = ()=>{
        window.scrollTo(0, 0);
        navigate("/estimate/request");
    }
    const floatEmail = ()=>{
        window.scrollTo(0, 0);
        navigate("/ask/email");
    }
    const kakaoLink = ()=>{
        // User-Agent 문자열 가져오기
        var userAgent = navigator.userAgent;
        // 모바일 여부 확인
        var isMobile = userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i);
        if(isMobile){
            window.location.href = "http://pf.kakao.com/_xbdwHxj/chat";
        }else{
            window.open("http://pf.kakao.com/_xbdwHxj/chat", "_blank", "noopener, noreferrer");
        }
    }
    //--------
    return (
        <div className="common-layout">
            <Loading loading={props.loading? props.loading : false} />
            { width < 1024 &&
                <div className="side-wrap">
                    <div>
                        <div style={{height: "80px"}}/>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Menu: {
                                        itemSelectedBg: "#fff2e7",
                                        itemSelectedColor: "#ff6000"
                                    },
                                },
                            }}
                        >
                            <Menu
                                className={"side-nav-wrap"}
                                mode="inline"
                                defaultOpenKeys={openKeys}
                                onOpenChange={onOpenChange}
                                onClick={onMenuSelect}
                                style={{
                                    width: 200,
                                    borderRight: "0px"
                                }}
                                items={items}
                                defaultSelectedKeys={selectMenu}
                            />
                        </ConfigProvider>
                    </div>
                    {user?.groupId !== "0" && user?.userId &&
                        <div>
                            <li className="sns-login-toggle">
                                <img src={"/images/login/naverlogo.png"} alt={"네이버 로그인"} width={"15px"} height={"15px"}/>
                                <div>네이버 로그인</div>
                                <Switch size="small" checked={naverToggle} onChange={(checked) => onChangeLoginToggle(checked, "naver")}/>
                            </li>
                            <li className="sns-login-toggle">
                                <img src={"/images/login/kakaoLogin.png"} alt={"카카오 로그인"} width={"15px"} height={"15px"}/>
                                <div>카카오 로그인</div>
                                <Switch size="small" checked={kakaoToggle} onChange={(checked) => onChangeLoginToggle(checked, "kakao")}/>
                            </li>
                        </div>
                    }
                </div>
            }
            <header className="header-wrap">
                <div className="header-inner menuArea">
                    <div className="header-menu-controller">
                        <span className="open">
                            <img src="/images/header/menu@3x.png" className={"menuBtn"} alt="메뉴 열기" width={24} height={24} onClick={openMenu}/>
                        </span>
                        <span className="close">
                            <img src="/images/close@3x.png" className={"menuBtn"} alt="메뉴 닫기" width={24} height={24}  onClick={hideMenu} />
                        </span>
                    </div>
                    {logoSize !== null &&
                        <Link to="/">
                            <img
                                className="header-logo"
                                src={"/images/logo@3x.png"}
                                alt="이봐"
                                width={logoSize.width}
                                height={logoSize.height}
                            />
                        </Link>
                    }
                    <nav className="header-nav-pc">
                        <ul className = "common-nav-wrap clearfix">

                            {!busiYn &&
                            <>
                                <li>
                                    <Link to="/estimate/request">견적요청</Link>
                                </li>
                                <li>
                                    <Link to="/ask/list">문의하기</Link>
                                </li>
                            </>
                            }
                                <li onClick={onToggleMenu}>
                                    <Link>기업보험상품</Link>
                                </li>

                            {user?.userId && !busiYn &&
                                <li>
                                    <Link to="/estimate/list">내 견적요청 현황</Link>
                                </li>
                            }
                            {user?.userId && busiYn &&
                                <>
                                    <li>
                                        <Link to="/business/estimate/list">견적리스트</Link>
                                    </li>
                                    <li>
                                        <Link to="/business/estimate/status">{translator["bid"]}현황</Link>
                                    </li>
                                </>
                            }
                        </ul>
                        {user?.userId
                            ? <div style={{position: 'relative'}}>
                                <div className={`user-info-wrap ${toggleHiddenMenu ? 'open' : ''}`} onClick={onClickIcon}>
                                    <span className="user-mail">안녕하세요, {user?.userName || "비회원"} 님</span>
                                    <i className="icon icon-combo icon-combo-down" />
                                    <i className="icon icon-combo icon-combo-up" />
                                </div>
                                <nav className="user-hidden-nav-wrap">
                                    <ul>
                                            <li>
                                                <Link to="/user/my-page">마이페이지</Link>
                                            </li>
                                        {!busiYn && user.groupId === "3" &&
                                            <li>
                                                <Link to="/admin/main">관리자 페이지</Link>
                                            </li>}
                                        {user?.groupId !== "0" &&
                                            <>
                                                <li className="sns-login-toggle">
                                                    <img src={"/images/login/naverlogo.png"} alt={"네이버 로그인"} width={"15px"} height={"15px"}/>
                                                    <div>네이버 로그인</div>
                                                    <Switch size="small" checked={naverToggle} onChange={(checked) => onChangeLoginToggle(checked, "naver")}/>
                                                </li>
                                                <li className="sns-login-toggle">
                                                    <img src={"/images/login/kakaoLogin.png"} alt={"카카오 로그인"} width={"15px"} height={"15px"}/>
                                                    <div>카카오 로그인</div>
                                                    <Switch size="small" checked={kakaoToggle} onChange={(checked) => onChangeLoginToggle(checked, "kakao")}/>
                                                </li>
                                            </>
                                        }
                                        <li>
                                            <button className="btn btn-tight btn-auto-height btn-transparent btn-logout" onClick={logout}>로그아웃</button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            : <ul className="non-user-nav-wrap">
                                <li>
                                    <Link to="/login">Login</Link>
                                </li>
                                <li>
                                    <Link to="/join">회원가입</Link>
                                </li>
                            </ul>
                        }
                    </nav>
                    <nav className="header-nav-mobile">
                    </nav>
                </div>
            </header>

            <main style={{ flex: "1" }}>
                {props.children}
            </main>
            <Footer />
            {insuranceToggle && <ToggleMenu setInsuranceMenu = {setInsuranceToggle} menu={insuranceList} />}

            <FloatButton.Group
                trigger="click"
                style={{
                    right: 20,
                    bottom: 30,
                }}
                onOpenChange={(e)=> {
                    if (e) {
                        setText("닫기")
                    } else {
                        setText("견적요청")
                    }
                }}
                description={floatingText}
                icon={<img src="/images/float-logo.png" alt="버튼그룹" />}
            >
                { !busiYn &&
                    <FloatButton description={"견적요청"} icon={<img src="/images/float-logo.png" alt="견적요청으로 이동" />} onClick={() => {floatEstimate()}} />
                }
                <FloatButton description={"카톡문의"} icon={<img src="/images/kakao.png" alt="카카오 문의하기" />} onClick={()=>{kakaoLink()}}/>
                <FloatButton description={"메일문의"} icon={<img src="/images/ask-email.png" alt="이메일 문의하기" />} onClick={() => {floatEmail()}} />
                <FloatButton description={"전화문의"} icon={<a href={"tel:02-797-1248"}><img src="/images/call.png" alt={"전화 문의하기"} /></a> }  />
            </FloatButton.Group>

        </div>
    )
})

export default Layout


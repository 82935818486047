import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Layout from "../components/layouts/Layout";
import {auth, insurance} from "../services/api";
import {getCode} from "../utils/code";
import {Select} from "antd";

const Insurance = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [menuList, setMenuList] = useState([]);
    const [menuOrigin, setMenuOrigin] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchData(id);
    }, [id])

    const fetchData = async (id)=>{
        const code = getCode();
        await insurance.searchInsuranceDetail(id).then((res)=>{
            setData({
                ...res,
                insuranceTypeName: code[res.insuranceTypeCode],
                insuranceName: code[res.insuranceCode],
            });
        });
        await auth.fetchInsuranceMenu().then((res) => {
            let newItemList = []
            res.map((item) => newItemList.push(...item.itemList))
            setMenuOrigin(newItemList);
            setMenuList(newItemList.map((item) => {
                return {
                    value: item.codeName,
                    label: item.codeName
                }
            }));
        })
    }

    const onSearchInsurance = () => {
        if (searchKeyword === "") return;
        const findData = menuOrigin.find((item) => item.codeName === searchKeyword )
        navigate(`/insurance/${findData.codeId}`)
    }

    const onChangeSelectBox = (value) => {
        setSearchKeyword(value);
    }

    const onDownload = (e) => {
        e.preventDefault();
        const newData = {
            etcDocAttId : data.etcDocAttId,
            etcDocAttPath : data.etcDocAttPath,
        }
        const blob = new Blob([newData]);
        const fileUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = fileUrl;
        a.style.display = 'none';

        a.download = data.etcDocAttName;

        a.click();
        a.remove();
        window.URL.revokeObjectURL(data.etcDocAttPath);
    }

    return (
        <Layout>
            <div className="common-wrapper">
                <section className="page-insurance-main">
                    <h2>{data?.insuranceTypeName}</h2>
                </section>
                <section className="page-insurance-image" style={{ backgroundImage: `url("${data?.backgroundAttPath}")`, backgroundPosition: "center center", backgroundSize: "cover" }}/>
                <section className="page-insurance-search">
                    <Select
                        allowClear
                        showSearch
                        placeholder="기업보험상품 검색"
                        defaultValue={searchKeyword}
                        onChange={onChangeSelectBox}
                        options={menuList}
                        style={{ width: "200px" }}
                    />
                    <button className="btn btn-small btn-orange btn-filter mobile-mr-4" onClick={onSearchInsurance}>검색</button>
                </section>
                <section className="page-insurance-summary">
                    <h2>{data?.insuranceName}</h2>
                    <p dangerouslySetInnerHTML={{__html: data?.subTitle}}></p>
                </section>
                <section className="page-insurance-intro">
                    {data?.introduction &&
                        <>
                            <div>상품소개</div>
                            <p dangerouslySetInnerHTML={{__html: data?.introduction}}></p>
                        </>
                    }
                    {data?.signTarget &&
                        <>
                            <div>가입이 필요한 곳</div>
                            <p dangerouslySetInnerHTML={{__html: data?.signTarget}}></p>
                        </>
                    }
                    {data?.compensation &&
                        <>
                            <div>보상하는 손해</div>
                            <p dangerouslySetInnerHTML={{__html: data?.compensation}}></p>
                        </>
                    }
                    {data?.effect &&
                        <>
                            <div>개요/필요성/효과</div>
                            <p dangerouslySetInnerHTML={{__html: data?.effect}}></p>
                        </>
                    }
                    {data?.notCompensation &&
                        <>
                            <div>주요 보상하지 아니하는 손해</div>
                            <p dangerouslySetInnerHTML={{__html: data?.notCompensation}}></p>
                        </>
                    }
                    {data?.basicDoc &&
                        <>
                            <div>기초서류</div>
                            <p dangerouslySetInnerHTML={{__html: data?.basicDoc}}></p>
                        </>
                    }
                    <div>설문서, 제안서 및 약관</div>
                    <a href="#" className="btn btn-small btn-line-orange" target="_blank" rel="noopener noreferrer" onClick={(e) => onDownload(e)}>다운로드</a>
                </section>
            </div>
        </Layout>
    );
}

export default Insurance;
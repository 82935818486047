import Layout from "../layouts/Layout";

const NotFound = () => {
    return (
        <Layout>
            <div className="common-wrapper page-not-found">
                This page could not be found.
            </div>
        </Layout>
    )
}

export default NotFound;
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import AdminLayout from "../../../components/layouts/AdminLayout";
import {auth, insurance} from "../../../services/api";
import {store} from "../../../stores";
import Input from "../../../components/input/Input";
import FileSelector from "../../../components/fileSelector/FileSelector";
import {Modal} from "antd";
import {getCode, setCode} from "../../../utils/code";

const InsuranceAdd = () => {
    const user = store.userStore.getUser();
    const code = getCode();
    const allowFileList = store.allowFileListStore.getAllowFileList();
    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const [insuranceInfo, setInsuranceInfo] = useState({
        userId: user?.userId || "",
        insuranceCode:"",
        insuranceTypeCode:"I100",
        insuranceName:"",
        subTitle:"",
        backgroundAtt:"",
        introduction:"",
        signTarget:"",
        compensation:"",
        effect:"",
        notCompensation:"",
        basicDoc:"",
        etcDocAtt:"",
    });
    const [insuranceTypeCode, setInsuranceTypeCode] = useState([]);
    useEffect(() => {
        setTitle("기업 보험 상품 추가");
        searchItem();
    }, [])

    const uploadFile = async (e, target) => {
        if(!e.target.files[0]) return;
        //이미지 파일만 받기
        let _lastDot = e.target.files[0].name.lastIndexOf('.');
        let _fileExt = e.target.files[0].name.substring(_lastDot+1, e.target.files[0].name.length);
        if(!allowFileList.includes(_fileExt)){
            Modal.warning({
                title: '알림',
                content: '이미지, PDF, ZIP 파일만 업로드 가능합니다.',
            });
            return;
        }
        //이미지 사이즈 확인
        if((e.target.files[0].size/1024/1024).toFixed(2) > 10){
            Modal.warning({
                title: '알림',
                content: (
                    <div>
                        <p>해당 파일은 {(e.target.files[0].size / 1024 / 1024).toFixed(2)}MB 입니다.</p>
                        <p>10MB 미만의 파일만 업로드 가능합니다.</p>
                    </div>
                )
            });
            return;
        }
        switch (target) {
            case 'etcDocAtt':
                setInsuranceInfo((prev) => {
                    return ({
                        ...prev,
                        etcDocAtt: e.target.files[0],
                    })
                });
                break;
            case 'backgroundAtt':
                setInsuranceInfo((prev) => {
                    return ({
                        ...prev,
                        backgroundAtt: e.target.files[0],
                    })
                })
                break;
            default:
                break;
        }
    }
    const searchItem = async ()=>{
        await insurance.searchInsuranceTypeCode().then((res)=>{
            setInsuranceTypeCode(res.data);
        });
    }
    const onEditInsurance = async () => {
        //input 항목들 전부 넣었는지 체크
        let inputCheck = true;
        Object.keys(insuranceInfo)
            .filter(key => key !== "insuranceCode")
            .forEach((key) => {
            if(insuranceInfo[key] === null || insuranceInfo[key].length <= 0){
                inputCheck = false;
            }
        });
        if(!inputCheck){
            Modal.warning({
                title: '알림',
                content: '모든 값을 입력해주세요.',
            });
            return false;
        }
        await insurance.addInsuranceProduct(insuranceInfo).then(async (res) => {
            if(res.commonRespCode === 1){
                //여기서 code store 한번 refresh 필요함
                await auth.fetchCode().then((code)=>{
                    const reCodeMap = code.reduce((acc, value) => {
                        if (acc) {
                            acc[value.codeId] = value.codeName;
                        } else {
                            acc = {};
                            acc[value.codeId] = value.codeName;
                        }
                        return acc;
                    }, {});
                    setCode(reCodeMap);
                    navigate(`/admin/insurance/products`);
                    Modal.success({
                        title: '알림',
                        content: '추가되었습니다.',
                    });
                });
            }else{
                Modal.error({
                    title: '알림',
                    content: '오류가 발생했습니다.',
                });
            }
        });
    }

    return (
        <AdminLayout title={title}>
            <div className="align-right">
                <button className="btn btn-small btn-orange" onClick={onEditInsurance}>저장</button>
                <button className="btn btn-small btn-line-orange" onClick={() => navigate(`/admin/insurance/products`)}>취소</button>
            </div>
            <div className="common-wrapper">
                <section className="page-insurance-summary pl-40" style={{ paddingBottom: "20px" }}>
                    <p>기업보험 >
                        <select className="insurance-select" defaultValue={insuranceTypeCode[0]} onChange={e => setInsuranceInfo((prev) => {
                            return ({
                                ...prev,
                                insuranceTypeCode: e.target.value,
                            })
                        })} style={{ marginLeft: "4px" }}>
                            {insuranceTypeCode.map(v =>(
                                <option key={v} value={v}>{code[v]}</option>
                            ))}
                        </select>
                    </p>
                    <h2>보험명</h2>
                    <Input
                        pageType="R"
                        placeholder="보험명"
                        value={insuranceInfo.insuranceName}
                        onChange={e => setInsuranceInfo((prev) => {
                            return ({
                                ...prev,
                                insuranceName: e.target.value,
                            })
                        })}
                    />
                    <p style={{
                        paddingTop:"50px",
                        paddingBottom:"10px"
                    }}>보험설명(부제목)</p>
                    <Input
                        pageType="R"
                        placeholder="보험설명(부제목)"
                        value={insuranceInfo.subTitle}
                        onChange={e => setInsuranceInfo((prev) => {
                            return ({
                                ...prev,
                                subTitle: e.target.value,
                            })
                        })}
                    />
                </section>
                <section className="page-insurance-intro pl-40" >
                    <div>배경이미지</div>
                    <div className="common-form-wrap" style={{ marginTop: "20px" }}>
                        <div className="form-block">
                            <span className="block-title">
							배경이미지 업로드
						    </span>
                            <div className="block-content">
                                <div className="content-other">
                                    {insuranceInfo.backgroundAtt
                                        ? <a className="file-content" href={insuranceInfo.backgroundAtt.url} target="_blank" rel="noopener noreferrer">{insuranceInfo.backgroundAtt.name}</a>
                                        : <p className="normal-content">* 등록된 배경화면이 없습니다.</p>
                                    }
                                    <div className="file-upload-wrap">
                                        <FileSelector id="renewal" onChange={e => uploadFile(e, 'backgroundAtt')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>상품소개</div>
                            <textarea
                                className="textarea"
                                style={{height: 180}}
                                placeholder="상품소개"
                                value={insuranceInfo.introduction}
                                onChange={e => setInsuranceInfo((prev) => {
                                    return ({
                                        ...prev,
                                        introduction: e.target.value,
                                    })
                                })} />
                    <div>가입이 필요한 곳</div>
                    <textarea
                        className="textarea"
                        style={{height: 180}}
                        placeholder="가입이 필요한 곳"
                        value={insuranceInfo.signTarget}
                        onChange={e => setInsuranceInfo((prev) => {
                            return ({
                                ...prev,
                                signTarget: e.target.value,
                            })
                        })} />
                    <div>보상하는 손해</div>
                    <textarea
                        className="textarea"
                        style={{height: 180}}
                        placeholder="보상하는 손해"
                        value={insuranceInfo.compensation}
                        onChange={e => setInsuranceInfo((prev) => {
                            return ({
                                ...prev,
                                compensation: e.target.value,
                            })
                        })} />
                    <div>개요/필요성/효과</div>
                    <textarea
                        className="textarea"
                        style={{height: 180}}
                        placeholder="개요/필요성/효과"
                        value={insuranceInfo.effect}
                        onChange={e => setInsuranceInfo((prev) => {
                            return ({
                                ...prev,
                                effect: e.target.value,
                            })
                        })} />
                    <div>주요 보상하지 아니하는 손해</div>
                    <textarea
                        className="textarea"
                        style={{height: 180}}
                        placeholder="주요 보상하지 아니하는 손해"
                        value={insuranceInfo.notCompensation}
                        onChange={e => setInsuranceInfo((prev) => {
                            return ({
                                ...prev,
                                notCompensation: e.target.value,
                            })
                        })} />
                    <div>기초서류</div>
                    <textarea
                        className="textarea"
                        style={{height: 180}}
                        placeholder="기초서류"
                        value={insuranceInfo.basicDoc}
                        onChange={e => setInsuranceInfo((prev) => {
                            return ({
                                ...prev,
                                basicDoc: e.target.value,
                            })
                        })} />
                    <div>설문서, 제안서 및 약관</div>
                    <div className="common-form-wrap" style={{ marginTop: "20px" }}>
                        <div className="form-block">
                            <span className="block-title">
							기타 서류 업로드
						    </span>
                            <div className="block-content">
                                <div className="content-other">
                                    {insuranceInfo.etcDocAtt
                                        ? <a className="file-content" href={insuranceInfo.etcDocAtt.url} target="_blank" rel="noopener noreferrer">{insuranceInfo.etcDocAtt.name}</a>
                                        : <p className="normal-content">* 등록된 문서가 없습니다.</p>
                                    }
                                    <div className="file-upload-wrap">
                                        <FileSelector id="etcDoc" onChange={e => uploadFile(e, 'etcDocAtt')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </AdminLayout>
    )
}

export default InsuranceAdd;
import Layout from "../components/layouts/Layout";
import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import {Autoplay, Navigation, Pagination, EffectFade} from "swiper/modules";
import RollingInsurance from "../components/rolling/RollingInsurance";
import RollingTrade from "../components/rolling/RollingTrade";
import {store} from "../stores";
import {auth} from "../services/api";
import {useEffect, useRef, useState} from "react";

const Main = () => {
    const [list, setList] = useState([]);
    const [settings, setSetting] = useState({});
    const [idx, setIdx] = useState(0);
    const idxRef = useRef(0);

    localStorage.setItem('selectMenu', '');
    store.menuStore.setSelectMenu(localStorage.getItem('selectMenu'));

    useEffect(()=>{
        fetchData();
    }, []);

    useEffect(() => {
        setInterval(() => {
            idxRef.current += 1;
            setIdx(idxRef.current);
        }, 3000);
    }, []);

    const fetchData = async () => {
        await auth.fetchTrade().then((res) => {
            setList(res.data[0].tradeInfoList);
            selectSetting();
        });
    }

    const selectSetting = async () =>{
        let newSettings = {};
        await auth.selectAdminSetting().then((res)=>{
            newSettings = {
                dots: false,
                infinite: true,
                speed: 2000,
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
                vertical: true,
                centerMode: true, // 현재 컨텐츠 가운데 정렬
                autoplay: true,
                autoplaySpeed: res.data[0].tradeScrollSpeed,
                initialSlide: 0,
            }
        });
        setSetting(newSettings);
    }

    return(
        <Layout>
            {settings !== {} && list.length > 0 &&
                <>
                    <Swiper
                        modules={[Navigation, Pagination, Autoplay, EffectFade]}
                        spaceBetween={0} // 여백 50
                        slidesPerView={1} // 1페이지씩 보여주기
                        navigation
                        pagination={{clickable: true}}
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: false
                        }}
                        loop={true}
                        className="swiper-wrapper"
                        effect="fade"
                    >
                        <SwiperSlide className="swiper-slide">
                            <div className="slide-title-area">
                                <p className="slide-title-sup">화재보험, 재산종합보험 등 갱신보험료가 궁금하시다구요?</p>
                                <h1 className="slide-title">최저가 견적 제공 플랫폼!<br/><span
                                    className="slide-title-strong">이봐</span>와 함께하세요.</h1>
                            </div>
                            <img className="ms1" src="/images/home/mainSlice.png" alt="최저가 견적 제공 플랫폼 이봐"/>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                            <div className="slide-title-area">
                                <p className="slide-title-sup">복잡한 보험 때문에 견적 요청 받기가 꺼려진다구요?</p>
                                <h1 className="slide-title">간단한 증권 업로드만으로<br/><span className="slide-title-strong">이봐</span>에서
                                    견적 요청하세요!</h1>
                                {/*<p className="slide-title-sub">일반화재, 공공화재, 배상책임 등 복잡한 보험을 증권 업로드만으로 간편하게 요청가능합니다.</p>*/}
                            </div>
                            <img className="ms2" src="/images/home/slice1.png" alt="스마트하고 심플하게 업로드만으로 견적요청"/>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                            <div className="slide-title-area">
                                <p className="slide-title-sup">신규 보험가입을 원하신다구요?</p>
                                <h1 className="slide-title">카카오톡, 이메일로 문의주신다면<br/><span
                                    className="slide-title-strong">이봐</span>CS 담당자가 빠르게 연락드립니다.</h1>
                                {/*<p className="slide-title-sub">회원가입 이후 상단이나 우측 하단의 메뉴를 통하여<br/>카카오톡, 이메일, 전화 문의시 저희 CS담당자가 최대한 빠르게 연락드립니다.</p>*/}
                            </div>
                            <img className="ms3" src="/images/home/slice2.png"
                                 alt="무료입찰, 실시간 보험료 확인, 최저가 가입, 투명한 입찰 프로세스 제공"/>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                            <div className="slide-title-area">
                                <h1 className="slide-title"><span className="slide-title-strong">이봐</span>에서 드리는 다양한 혜택!
                                </h1>
                                <ul className="slide-list">
                                    <li className="slide-checked"><span className="list-orange">무료 입찰</span> 신청 및 가입</li>
                                    <li className="slide-checked">실시간 투찰 <span className="list-orange">보험료 확인</span></li>
                                    <li className="slide-checked">국내 <span className="list-orange">최저가 보험료</span> 가입</li>
                                    <li className="slide-checked">투명한 <span className="list-orange">입찰 프로세스</span> 제공</li>
                                    <li className="slide-checked">입찰 결과 <span className="list-orange">리포트 출력</span></li>
                                    <li className="slide-checked"><span className="list-orange">계약 유지관리</span> 서비스 제공</li>
                                </ul>
                            </div>
                            <img className="ms4" src="/images/home/slice3.png" alt="로그인 하신 후 카카오톡으로 문의주세요"/>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                            <div className="slide-title-area" style={{marginTop: "-30px"}}>
                                <p className="slide-title-sup">고객분들에게 신뢰를 줄 수 있는</p>
                                <h1 className="slide-title">여러 메이저 보험사에서<br/><span
                                    className="slide-title-strong-white">이봐</span>와 함께합니다.</h1>
                                {/*<p className="slide-title-sub">삼성화재 / 현대해상 / DB손해보험 / KB손해보험 / 메리츠화재 / 롯데손해보험 / MG손해보험 등</p>*/}
                            </div>
                            <img className="ms5" src="/images/home/slice4.png" alt="8개의 보험사가 참여하고 있습니다"/>
                        </SwiperSlide>
                    </Swiper>
                    <div className={"mainContent"}>
                        <div className={"content1"}>
                            <RollingInsurance />
                        </div>
                        <div className={"content2"}>
                            <h1 className={"tradeTitle"}>실시간 견적현황</h1>
                            <RollingTrade settings={settings} list={list} idx={idx} />
                        </div>
                    </div>
                </>
            }
        </Layout>
    )
}

export default Main




import DatePicker from "react-datepicker";
import {useRef} from "react";

const ReadOnlyInput = (props) => {
    const today = new Date();
    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + 1);
    const { title, value } = props;
    const _calendar = useRef(null);
    const visibleDatePicker = (bool) => {
        if (_calendar.current) {
            _calendar.current.setOpen(bool);
        }
    }
    return (
        <div className={`form-block ${!props.readOnly && "only-string"}`}>
            <span className="block-title">
                {title}
            </span>
            <div className="block-content">
                { !props.readOnly ? <p className="content-string">{value}</p> :
                    props.onChangeDate ?
                        <div className="content-other">
                            <p className="date-content" style={{ width: "180px" }}>{value}</p>
                            <label style={{ position: 'relative' }} onClick={e => e.preventDefault()}>
                                <button className="btn btn-gray" style={{ width: "120px" }} onClick={() => visibleDatePicker(true)}>날짜보기</button>
                                <DatePicker
                                    locale="ko"
                                    ref={_calendar}
                                    minDate={nextDay}
                                    value={value}
                                    onChange={props.onChangeDate && props.onChangeDate}
                                />
                            </label>
                        </div> :
                    <input
                        className={`input-text ${props.className && props.className}`}
                        value={props.value ? props.value : ""}
                        onChange={props.onChange && props.onChange}
                    />
                }
            </div>
        </div>
    )
}

export default ReadOnlyInput;


import {makeAutoObservable} from "mobx";

// 로그인시 기본 정보 가지고 페이지 이동할 때 사용
class Time {
    timeData = "";
    constructor() {
        makeAutoObservable(this);
    }
    setTime = (data) => {
        this.timeData = data;
    }
    getTime = () => {
        return this.timeData;
    }
}

const timeStore = new Time();
export default timeStore;
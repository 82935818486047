import Layout from "../components/layouts/Layout";
import {auth} from "../services/api";
import {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {checkEmail} from "../utils";
import {Input as inputPass, Modal} from "antd";
import {store} from "../stores";
import {setGroupId, setToken} from "../utils/auth";

const SnsLink = ()=>{
    const initialData = {
        userId: "",
        userPwd: "",
        id: "",
        platform: ""
    }
    const [data, setData] = useState(initialData);
    const { state } = useLocation();
    const [title, setTitle] = useState("소셜로그인 연동");
    const [notEnter, setNotEnter] = useState("");
    const navigate = useNavigate();

    useEffect(()=>{
        window.scrollTo({ top: 0 });
        if (!state) return;
        switch (state.platform) {
            case "naver" :
                setTitle("네이버 로그인 연동");
                break;
            case "kakao" :
                setTitle("카카오 로그인 연동");
                break;
            default:
                break;
        }
        setData(() => {
            return {
                ...data,
                id: state.id,
                platform: state.platform,
                userId: state.userId
            }
        })
    }, []);

    const login = async () => {
        if(data.userId === "") {
            setNotEnter("이메일");
            Modal.warning({
                title: '알림',
                content: '이메일 주소를 입력해주세요.',
            });
            return;
        }
        if (!checkEmail(data.userId)) {
            setNotEnter("이메일");
            Modal.warning({
                title: '알림',
                content: '올바른 이메일 주소가 아닙니다.',
            });
            return;
        }
        await auth.snsLink(data)
            .then(async (res) => {
                if (res) {
                    if(res.commonRespCode === "1") {
                        const userData = {
                            userId: data.userId,
                            userPass: data.userPwd,
                        }
                        await auth.login(userData)
                            .then(async (res) => {
                                if (res) {
                                    if(res.commonRespCode === "success") {
                                        store.userStore.setUser(res.data[0]);
                                        setToken(res.data[0].token);
                                        setGroupId(res.data[0].groupId);
                                        Modal.success({
                                            title: '알림',
                                            content: "SNS 연동이 완료되었습니다.",
                                        });
                                        navigate("/");
                                    } else {
                                        Modal.error({
                                            title: '알림',
                                            content: '다시 시도해주세요.',
                                        });
                                    }
                                } else {
                                    Modal.error({
                                        title: '알림',
                                        content: '정보를 다시 입력해주세요.',
                                    });
                                }
                            });
                    } else {
                        Modal.error({
                            title: '알림',
                            content: res.message,
                        });
                    }
                } else {
                    Modal.error({
                        title: '알림',
                        content: '오류가 발생하였습니다.',
                    });
                }
            });
    }

    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            login();
        }
    }

    return (
        <Layout>
            <div className="common-wrapper page-login">
                <div className="page-login-inner">
                    <div className="mobile-wrap">
                        <div className="login-form-wrap">
                            <h2>{title}</h2>
                            <p>가입하신 회원 정보를 입력해주세요.</p>
                            <div className="login-information-wrap">
                                <div className="info-field">
                                    <span>E-mail 입력</span>
                                    <input
                                        type="text"
                                        className={`input-text input-full ${notEnter === "이메일" && "input-red"}`}
                                        placeholder="이메일주소를 입력하세요."
                                        onChange={e => setData((prev) => {
                                            return ({
                                                ...prev,
                                                userId: e.target.value,
                                            })
                                        })}
                                        onKeyPress={e => onEnter(e)}
                                    />
                                </div>
                                <div className="info-field">
                                    <span>비밀번호</span>
                                    <inputPass.Password
                                        type="password"
                                        className="input-text input-full"
                                        placeholder="비밀번호를 입력하세요."
                                        onChange={e => setData((prev) => {
                                            return ({
                                                ...prev,
                                                userPwd: e.target.value,
                                            })
                                        })}
                                        onKeyPress={e => onEnter(e)}
                                    />
                                </div>
                            </div>
                            <div className="login-helper-wrap">
                                <Link to="/find/password">e-mail / 비밀번호 찾기</Link>
                            </div>
                            <button className="btn btn-full btn-orange" onClick={login}>로그인</button>
                        </div>
                        <div className="login-bg-wrap">
                            <Link to="/">
                                <span className="only-sr">이봐 홈으로</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default SnsLink;
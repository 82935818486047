import {makeAutoObservable} from "mobx";

// edit 부분이나 상세 페이지로 이동할 때 사용
class AdminCustomer {
    userData = {};
    constructor() {
        makeAutoObservable(this);
    }
    setAdminCustomer = (data) => {
        this.userData = data;
    }
    getAdminCustomer = () => {
        return this.userData;
    }
    deleteAdminCustomer = () => {
        this.userData = {};
    }
}

const AdminCustomerStore = new AdminCustomer();
export default AdminCustomerStore;
import React, {useState, useEffect} from "react";
import {Line, Pie} from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, ArcElement, Tooltip, Legend } from 'chart.js';
import {useNavigate} from "react-router-dom";
import AdminLayout from "../../components/layouts/AdminLayout";
import AdminDashboard from "../../components/adminDashboard/AdminDashboard";
import {auth} from "../../services/api";
import translator from "../../utils/translator";
import {store} from "../../stores";

const MainDashboard = () => {
    const [reqData, setLineReqData] = useState([]);
    const [contractData, setLineContractData] = useState([]);
    const [estPieData, setEstimatePieData] = useState([]);
    const [insPieLabel, setInsurancePieLabel] = useState([]);
    const [insPieData, setInsurancePieData] = useState([]);
    const [estStatusData, setEstimateStatus] = useState([]);
    const [userCount, setUserCount] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    useEffect(() => {
        let timeoutId;
        const handleResize = () => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                setWidth(window.innerWidth);
            }, 300)
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.clearTimeout(timeoutId);
        };
    }, []);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        await auth.searchDashboard().then((res) => {
            makeLineGraph(res.data[0]);
            makePieGraph(res.data[0]);
            setEstimateStatus(res.data[0].estimateStatusCount);
            setUserCount(res.data[0].userCountList);
        });
    }

    const makeLineGraph = (data) => {
        let reqData = [];
        let contractData = [];
        Object.keys(data?.estimateReqCountList).forEach((key) => {
            reqData.push(data?.estimateReqCountList[key]);
        })
        Object.keys(data?.estimateContractCountList).forEach((key) => {
            contractData.push(data?.estimateContractCountList[key]);
        })
        setLineReqData(reqData);
        setLineContractData(contractData);
    }

    const makePieGraph = (data) => {
        let insLabel = [];
        let insData = [];
        let estData = [];
        data.recentInsuranceItemList.forEach((item) => {
            insLabel.push(item.insurnaceName);
            insData.push(item.count);
        });
        setInsurancePieLabel(insLabel);
        setInsurancePieData(insData);
        estData.push(data.estimateMonthly.estimateCount);
        estData.push(data.estimateMonthly.contractCount);
        estData.push(data.estimateMonthly.cancelCount);
        setEstimatePieData(estData);
    }

    const onChangePage = (page, type) => {
        if (page === "contract") {
            localStorage.setItem('selectMenu', 'estimate_contract');
            store.menuStore.setSelectMenu(localStorage.getItem('selectMenu'));
            navigate("/admin/contract", { state : {select: type} }) // 계약관리
        } else {
            localStorage.setItem('selectMenu', 'estimate_estimate');
            store.menuStore.setSelectMenu(localStorage.getItem('selectMenu'));
            navigate("/admin/bid/manage", { state : {select: type} }) // 입찰관리
        }
    }

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        ArcElement,
        Tooltip,
        Legend);

    const pieOption = {
        plugins: {
            tooltip: {
                enabled: true,
                usePointStyle: true,
                callbacks: {
                    label: (data) => {
                        return `${data.label} : ${data.parsed}`
                    }
                },
            },
        },
    }

    const estimatePieData = {
        labels: ['견적요청', '계약완료', '견적종료'],
        datasets: [
            {
                data: estPieData,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }

    const insuranceItemPieData = {
        labels: insPieLabel,
        datasets: [
            {
                data: insPieData,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }

    const options = {
        responsive: true,
        plugins: {
            title: {
                display: false,
            },
        },
    };

    const labels = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];

    const lineGraphData = {
        labels,
        datasets: [
            {
                label: '견적 요청 건수',
                data: reqData,
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 1,
                backgroundColor: 'rgba(255, 99, 132, .5)',
            },
            {
                label: '계약 완료 건수',
                data: contractData,
                borderColor: 'rgb(53, 162, 235)',
                borderWidth: 1,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    return(
        <AdminLayout title="대시보드">
            <div className="st-block-main">
                <div className="st-block">
                    <AdminDashboard title={'일반회원수'}>
                        <table style={{ marginBottom: "16px" }}>
                            <tbody>
                                <tr>
                                    <th>전체</th>
                                    <th>정회원</th>
                                    <th>비회원</th>
                                    <th>이번달 신규회원</th>

                                </tr>
                                <tr>
                                    <td className="text-red">{userCount?.allUserCount}</td>
                                    <td className="text-blue">{userCount?.regularMemberCount}</td>
                                    <td className="text-blue">{userCount?.nonMemberCount}</td>
                                    <td className="text-red">{userCount?.thisMonthJoinCount}</td>
                                </tr>
                            </tbody>
                        </table>
                    </AdminDashboard>
                    <AdminDashboard title={`${translator["bid"]} 관리`}>
                        <table>
                            <tbody>
                            <tr>
                                <th>처리</th>
                                <td className="text-blue td-pointer" onClick={() => onChangePage("estimate", "confirmed")}>{estStatusData?.confirmedCount}</td>
                            </tr>
                            <tr>
                                <th>미처리</th>
                                <td className="text-red td-pointer" onClick={() => onChangePage("estimate", "unconfirmed")}>{estStatusData?.unconfirmedCount}</td>
                            </tr>
                            </tbody>
                        </table>
                    </AdminDashboard>
                    <AdminDashboard title="계약 관리">
                        <table>
                            <tr>
                                <th>처리</th>
                                <td className="text-blue td-pointer" onClick={() => onChangePage("contract", "contracted")}>{estStatusData?.contractedCount}</td>
                            </tr>
                            <tr>
                                <th>미처리</th>
                                <td className="text-red td-pointer" onClick={() => onChangePage("contract", "uncontracted")}>{estStatusData?.uncontractedCount}</td>
                            </tr>
                        </table>
                    </AdminDashboard>
                    <AdminDashboard title="견적 현황">
                        <Pie options={pieOption} data={estimatePieData} />
                    </AdminDashboard>
                    <AdminDashboard title="보험 종목 추세">
                        <Pie options={pieOption} data={insuranceItemPieData} />
                    </AdminDashboard>
                    <AdminDashboard title="견적요청 및 계약완료">
                        <Line options={options} data={lineGraphData} redraw />
                    </AdminDashboard>
                </div>
            </div>
        </AdminLayout>
    )
}

export default MainDashboard;
import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {checkEmail, codeFetch} from "../utils";
import {Input as inputPass, Modal} from "antd";
import {auth} from "../services/api";
import {store} from "../stores";
import {setGroupId, setToken} from "../utils/auth";
import Layout from "../components/layouts/Layout";

const MailLogin = observer(() => {
    const [email, setEmail] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [password, setPassword] = useState("");
    const [notEnter, setNotEnter] = useState("");
    const navigate = useNavigate();
    const {inflowType} = useParams();
    const [target, setTarget] = useState("");

    useEffect(() => {
        codeFetch();
        window.scrollTo({ top: 0 });
        targetSetting();
    }, [])

    const targetSetting = ()=>{
        switch(inflowType){
            case "newBidding":
                const esCode = searchParams.get("esCode");
                setTarget(`/estimate/list/detail/${esCode}`);
                break;
            case "newEstimate":
                setTarget("/business/estimate/list");
                break;
            case "adminEstimate":
                const adminEsCode = searchParams.get("esCode");
                setTarget(`/admin/bid/manage/detail/${adminEsCode}`);
                break;
            case "adminContract":
                const adminContractEsCode = searchParams.get("esCode");
                setTarget(`/admin/contract/detail/${adminContractEsCode}`);
                break;
            default:
                setTarget("/");
                break;
        }
    }

    const login = async () => {
        if(email === "") {
            setNotEnter("이메일");
            Modal.warning({
                title: '알림',
                content: '이메일 주소를 입력해주세요.',
            });
            return;
        }
        if (!checkEmail(email)) {
            setNotEnter("이메일");
            Modal.warning({
                title: '알림',
                content: '올바른 이메일 주소가 아닙니다.',
            });
            return;
        }
        // if (password && !/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/.test(password)) {
        //     Modal.warning({
        //         title: '알림',
        //         content: '비밀번호는 영문, 숫자, 특수문자 조합 8자 이상으로 입력해야 합니다.',
        //     });
        //     return;
        // }
        const data = {
            userId: email,
            userPass: password
        }
        await auth.login(data)
            .then(async (res) => {
                if (res) {
                    if(res.commonRespCode === "success") {
                        store.userStore.setUser(res.data[0]);
                        setToken(res.data[0].token);
                        setGroupId(res.data[0].groupId);
                        navigate(target);
                    } else {
                        Modal.error({
                            title: '알림',
                            content: res.message,
                        });
                    }
                } else {
                    Modal.error({
                        title: '알림',
                        content: '오류가 발생하였습니다.',
                    });
                }
            });
    }

    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            login();
        }
    }

    return(
        <Layout>
            <div className="common-wrapper page-login">
                <div className="page-login-inner">
                    <div className="mobile-wrap">
                        <div className="login-form-wrap">
                            {/*<div className="login-logo only-mobile">*/}
                            {/*    <img src="/images/logo@3x.png" width={88} height= {44} onClick={() => navigate("/")}/>*/}
                            {/*</div>*/}
                            <h2>로그인</h2>
                            <div className="login-information-wrap">
                                <div className="info-field">
                                    <span>E-mail 입력</span>
                                    <input
                                        type="text"
                                        className={`input-text input-full ${notEnter === "이메일" && "input-red"}`}
                                        placeholder="메일주소를 입력하세요."
                                        onChange={e => setEmail(e.target.value)}
                                        onKeyPress={e => onEnter(e)}
                                    />
                                </div>
                                <div className="info-field">
                                    <span>비밀번호</span>
                                    <inputPass.Password
                                        type="password"
                                        className="input-text input-full"
                                        placeholder="영문/숫자/특수문자 조합 8자 이상"
                                        onChange={e => setPassword(e.target.value)}
                                        onKeyPress={e => onEnter(e)}
                                    />
                                </div>
                            </div>
                            <div className="login-helper-wrap">
                                <Link to="/find/password">e-mail / 비밀번호 찾기</Link>
                                <i className="helper-split"></i>
                                <Link to="/join">가입하기</Link>
                            </div>
                            <button className="btn btn-full btn-orange" onClick={login}>로그인</button>
                        </div>
                        <div className="login-bg-wrap">
                            <Link to="/">
                                <span className="only-sr">이봐 홈으로</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
})

export default MailLogin;
import Layout from "../../components/layouts/Layout";
import PageTitle from "../../components/pageTitle/PageTitle";
import {Table, Tag} from "antd";
import translator from "../../utils/translator";
import {business} from "../../services/api";
import {useState, useEffect} from "react";
import {store} from "../../stores";
import EstimateBidModal from "../../components/modal/estimateBidModal";
import {isLogin} from "../../utils/auth";
import {useNavigate} from "react-router-dom";
import DashBoard from "../../components/dashboard/DashBoard";
import {getCode} from "../../utils/code";

const BusinessStatus = () => {
    const initialData = {
        userId: "",
        pageNo: 1,
        limit: 10,
        start: 0,
        searchKey: "",
        searchValue: "",
        dateType: "",
        fromDate: "",
        toDate: "",
    }
    const [width, setWidth] = useState(window.innerWidth);
    const [header, setHeader] = useState([]);
    const [data, setData] = useState(initialData);
    const [onlyList,setOnlyList] = useState([])
    const [page, setPageNum] = useState(1);
    const [selected, setSelected] = useState(null);
    const [boardList, setBoardList] = useState([]);
    const [user, setUser] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        let timeoutId;
        const handleResize = () => {
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(() => {
                setWidth(window.innerWidth);
            }, 300)
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.clearTimeout(timeoutId);
        };
    }, []);

    const fetchData = async (data) => {
        const code = getCode();
        await business.searchBusiness(data).then((res) => {
            if (res?.items.length === 0) return;
            let listLeng;
            const needList = []
            if (res.items.length < 5) {
                listLeng = res.items.length;
            } else {
                listLeng = 5;
            }
            for(let i = 0; i < listLeng; i++) {
                needList.push({
                    ...res.items[i],
                    key: res.items[i].esCode,
                    esInsuranceTypeCode: code[res.items[i].esInsuranceTypeCode] || res.items[i].esInsuranceTypeCode,
                    bidStatusCode: code[res.items[i].bidStatusCode]
                })
            }
            setOnlyList(needList);
            return res;
        });
    }

    const fetchBoard = async (userId) => {
        await business.searchBusinessStep(userId).then((res) => {
            const resultData = res.data[0].map((item) => {
                return {
                    ...item,
                    key: item.code,
                }
            })
            setBoardList(resultData);
            return res;
        });
    }

    useEffect(()=>{
        if(width < 1024){
            setHeader(["esDeadlineDt", "esInsuranceTypeCode", "bidStatusCode"]);
        }else{
            setHeader([
                "esDeadlineDt",
                "esCompanyName",
                "esInsuranceTypeCode",
                "esTitle",
                "bidStatusCode",
            ]);
        }
    }, [width]);

    useEffect(() => {
        checkPageAuth();
    }, [page])

    const checkPageAuth = async () => {
        const auth = await isLogin();
        if (!auth) return;
        const userData = store.userStore.getUser();
        setUser(userData)
        searchList(page, userData.userId);
    }

    const searchList = (pageNo, id) => {
        const tempData = {
            ...data,
            userId: id,
            pageNo,
        }
        setData(tempData);
        setPageNum(pageNo);
        fetchData(tempData);
        fetchBoard(id);
    }

    const onClickBoard = (item) => {
        navigate(`/business/estimate/${item.code}`)
    }

    const onClickPlus = () => {
        localStorage.setItem('selectMenu', 'business_list');
        store.menuStore.setSelectMenu(localStorage.getItem('selectMenu'));
        navigate("/business/estimate/list")
    }

    const columns = header.map((key) => {
        if(key === "esDeadlineDt") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                align: 'center',
                width: 120,
            }
        } else if (key === "esTitle"){
            return {
                title: translator[key],
                dataIndex: key,
                key,
                align: 'center',
                width: 160,
            }
        } else if (key === "bidStatusCode"){
            return {
                title: translator[key],
                dataIndex: key,
                key,
                align: 'center',
                width: 110,
                render: (value) => {
                    let color;
                    let bordered = true;
                    if (value === "계약요청") {
                        color = 'green';
                    } else if (value === "계약확인") {
                        color = 'gold';
                    } else if (value === "계약완료") {
                        color = 'volcano';
                    } else if (value === "입찰요청") {
                        color = 'blue';
                    } else if (value === "입찰확인") {
                        color = 'purple';
                    } else if (value === "입찰완료") {
                        color = 'magenta';
                    } else if (value === "견적요청") {
                        color = 'purple';
                    } else if (value === "견적확인") {
                        color = 'red';
                    } else if (value === "미참여") {
                        color = 'geekblue';
                    } else if (value === "입찰종료") {
                        color = 'default';
                        bordered = false;
                    }
                    return (
                        <Tag color={color} key={value} bordered={bordered}>
                            {value}
                        </Tag>
                    );
                }
            }
        } else {
            return {
                title: translator[key],
                width: 110,
                dataIndex: key,
                key,
                align: 'center',
            }
        }
    })

    return(
        <Layout>
            <div className="common-wrapper page-request-list">
                <PageTitle title={`${translator["bid"]} 요청 현황`} />
                {boardList.length > 0 &&
                    <DashBoard
                        data={boardList}
                        onClickBoard={onClickBoard}
                    />}
                <div className="status-subtitle-plus">
                    <p className="request-status-subtitle">견적 요청 리스트</p>
                    <p className="request-status-plus" onClick={onClickPlus}>more ▶</p>
                </div>
                <div className="table-wrapper estimate-bidding-table"  style={{ margin : "20px 0"}}>
                    <Table
                        className="inner-wrapper"
                        dataSource={onlyList}
                        columns={columns}
                        style={{ cursor: "pointer" }}
                        onRow={(record) => {
                            if (record.bidStatusCode === "입찰종료") return;
                            return {
                                onClick: () => {
                                    setSelected(record);
                                },
                            };
                        }}
                        rowClassName={record => record.bidStatusCode === "입찰종료" && "disabled-row"}
                        pagination={false}
                    />
                </div>
            </div>
            {selected &&
                <EstimateBidModal
                    selectData={selected}
                    bidId={selected.bidCode}
                    onClose={() => {
                        setSelected(null);
                        searchList(1, user.userId)
                    }}
                />
            }
        </Layout>
    )
}

export default BusinessStatus;
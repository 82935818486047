import {useNavigate} from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
const PageTitle = (props) => {
    const navigate = useNavigate();
    const onPopClick = () => {
        navigate(-1);
    }
    return (
        <div className="common-wrapper page-title-wrap" style={props.style && props.style}>
            <h2>
                {props.pop && 
                        <ArrowLeftOutlined className={"pop-arrow"} style={{
                            width : "30px",
                            height : "30px"
                        }} onClick={props.onClick ? props.onClick : onPopClick}/>
                }{props.title}
            </h2>
            {props.subTitle &&
                <p>
                    {props.subTitle}
                </p>
            }
        </div>
    );
}

export default PageTitle;
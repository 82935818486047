import PageTitle from "../../components/pageTitle/PageTitle";
import Layout from "../../components/layouts/Layout";
import Input from "../../components/input/Input";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {user} from "../../services/api";
import {Modal} from "antd";

const UpdatePassword = () => {
    const { state } = useLocation();
    const [userPass, setUserPass] = useState("");
    const [userPassCheck, setUserPassCheck] = useState("");
    const [passCheck, setPassCheck] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!state) {
            Modal.error({
                title: '알림',
                content: '권한이 없습니다.',
            })
            navigate("/");
        }
    }, [state])

    const onCheckPassword = async () => {
        if (userPass.length === 0 || userPassCheck.length === 0) return;
        if (userPass !== userPassCheck) {
            setPassCheck(true)
        } else {
            setPassCheck(false)
        }
    }

    const onUpdatePassword = () => {
        // if (userPass && !/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/.test(userPass)) {
        //     Modal.warning({
        //         title: '알림',
        //         content: '비밀번호는 영문, 숫자, 특수문자 조합 8자 이상으로 입력해야 합니다.',
        //     });
        //     return;
        // }
        Modal.confirm({
            title: '확인',
            content: '비밀번호를 변경하시겠습니까?',
            onOk: async () => {
                const data = {
                    userId: state.userId,
                    userPass
                }
                await user.updatePassword(data)
                    .then((res) => {
                        if (res && res.commonRespCode === "1") {
                            Modal.success({
                                title: '알림',
                                content: '비밀번호가 변경되었습니다.',
                            });
                            navigate("/login");
                        } else {
                            Modal.error({
                                title: '알림',
                                content: res.message,
                            });
                        }
                    })
            }
        });
    }

    return (
        <Layout>
            <div className="common-wrapper page-user-mypage">
                <PageTitle title="비밀번호 변경하기"/>
                <div className="common-form-wrap">
                    <div className="form-block only-string">
						<span className="block-title">
							E-mail
						</span>
                        <div className="block-content">
                            <p className="content-string">{state?.userId}</p>
                        </div>
                    </div>
                    <Input
                        pageType="R"
                        title="새 비밀번호"
                        type="password"
                        value={userPass}
                        placeholder="영문/숫자/특수문자 조합 8자 이상"
                        onChange={e => setUserPass(e.target.value)}
                    />
                    <Input
                        pageType="R"
                        title="새 비밀번호 확인"
                        alert={passCheck && "* 비밀번호가 일치하지 않습니다."}
                        type="password"
                        value={userPassCheck}
                        placeholder="변경할 비밀번호 확인"
                        onChange={e => setUserPassCheck(e.target.value)}
                        onBlur={onCheckPassword}
                    />
                </div>
                <div className="form-btn-wrap">
                    <button className="btn btn-orange btn-edit" onClick={onUpdatePassword}>비밀번호 변경하기</button>
                </div>
            </div>
        </Layout>
    )
}

export default UpdatePassword;
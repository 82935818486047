import AdminLayout from "../../../components/layouts/AdminLayout";
import {observer} from "mobx-react";
import {useParams} from "react-router-dom";
import {auth} from "../../../services/api";
import {useEffect, useState} from "react";
import {store} from "../../../stores";
import {addComma, autoHypen} from "../../../utils";
import ReadOnlyInput from "../../../components/input/ReadOnlyInput";
import {FileInput} from "../../../components/input/FileInput";
import translator from "../../../utils/translator";

const Detail = observer (() => {
    const user = store.userStore.getUser();
    const { id } = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        await auth.searchInsuranceDetail(id).then((res) => {
            setData(res);
        })
    }
    return (
        <AdminLayout title={`${translator["bid"]} 상세`} pop>
            <div className="estimate-detail-wrap">
                <div className="admin-common-form-wrap small">
                    <ReadOnlyInput title="문서 ID" value={data.esCode} />
                    <ReadOnlyInput title="견적 신청인 E-mail" value={user?.userId} />
                    <ReadOnlyInput title="견적 신청인 회사명" value={user?.companyName} />
                    <ReadOnlyInput title="견적 신청인 고객명" value={user?.userName} />
                    <ReadOnlyInput title="견적 신청인 휴대전화" value={autoHypen(String(user?.userPhone))} />
                    <ReadOnlyInput title="제목" value={data.esTitle} />
                    <FileInput
                        title = "사업자등록증"
                        value = {data.businessLicenseAttName}
                        path = {data.businessLicenseAttPath}
                    />
                    <FileInput
                        title = "갱신증권"
                        value = {data.policyAttName}
                        path = {data.policyAttPath}
                    />
                    <ReadOnlyInput title="3개년 사고사항" value={data.esAccident} />
                    <ReadOnlyInput title={`${translator["bid"]} 마감일`} value={data.deadlineDt} />
                    <ReadOnlyInput title="요청사항" value={data.esComments} />
                </div>
                <div className="admin-common-form-wrap small">
                    <ReadOnlyInput title="견적 ID" value={data.bidCode} />
                    <ReadOnlyInput title={`${translator["bid"]}일시`} value={data.bidInsertDt} />
                    <ReadOnlyInput title="보험사" value={data.bidCompany} />
                    <ReadOnlyInput title="보험료" value={`${addComma(Number(data.bidAmount))}원`} />
                    <ReadOnlyInput title="보험 담당자 휴대전화" value={autoHypen(String(data.bidPhone))} />
                    <ReadOnlyInput title="보험사 코멘트" value={data.bidComments} />
                    <FileInput
                        title = "청약서"
                        value = {data.applicationAttName}
                        path = {data.applicationAttPath}
                    />
                    <FileInput
                        title = "상품설명서"
                        value = {data.productAttName}
                        path = {data.productAttPath}
                    />
                    <FileInput
                        title = "약관"
                        value = {data.insuranceAttName}
                        path = {data.insuranceAttPath}
                    />
                </div>
            </div>
        </AdminLayout>
    )
})

export default Detail
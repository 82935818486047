import Layout from "../components/layouts/Layout";

const ErrorPage = () => {
    let code = new URL(window.location.href).searchParams.get('code');

    return(
        <Layout>
            <div className="common-wrapper">
                <div className={"errorPage"}>
                    <img src="/images/error.png" width={"200px"} alt={"서비스 이용에 불편을 드려 죄송합니다. 문제가 발생하여 페이지를 표시할 수 없습니다."}/>
                    <h3>죄송합니다.</h3>
                    <h4>Server Error Code : <span>{code}</span></h4>
                    <p>
                        서비스 이용에 불편을 드려 죄송합니다.<br />
                        시스템 에러가 발생하여 페이지를 표시할 수 없습니다.<br />
                        관리자에게 문의하시거나 나중에 다시 시도해주세요.
                    </p>
                </div>
            </div>
        </Layout>
    )
}

export default ErrorPage;
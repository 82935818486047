import {Route, Routes} from "react-router-dom";
import './styles/index.scss'
import Main from "./pages";
import EstimateRequest from "./pages/estimate/request";
import EstimateList from "./pages/estimate/list";
import AskList from "./pages/ask/list";
import AskEmail from "./pages/ask/email";
import Login from "./pages/login";
import Join from "./pages/join";
import BusinessList from "./pages/business/list";
import FindPassword from "./pages/find/password";
import MyPage from "./pages/user/myPage";
import NotFound from "./components/notFound/notFound";
import AdminCustomer from "./pages/admin/customer/list";
import AdminUser from "./pages/admin/user/list";
import BidManage from "./pages/admin/bid/manage";
import BidStatistics from "./pages/admin/bid/statistics";
import CustomerEdit from "./pages/admin/customer/edit";
import BidManageDetail from "./pages/admin/bid/manageDetail";
import EstimateListDetail from "./pages/estimate/detail";
import Report from "./components/report/Report";
import Detail from "./pages/admin/estimate/Detail";
import Insurance from "./pages/Insurance";
import InsuranceProducts from "./pages/admin/insurance/InsuranceProducts";
import InsuranceDetail from "./pages/admin/insurance/InsuranceDetail";
import InsuranceEdit from "./pages/admin/insurance/InsuranceEdit";
import InsuranceAdd from "./pages/admin/insurance/InsuranceAdd";
import BusinessStatus from "./pages/business/status";
import FindEmail from "./pages/find/email";
import UpdatePassword from "./pages/find/updatePassword";
import AdminContract from "./pages/admin/contract";
import ContractDetail from "./pages/admin/contract/detail";
import MainDashboard from "./pages/admin/mainDashboard";
import Bidding from "./pages/admin/bid/bidding";
import MailLogin from "./pages/MailLogin";
import NonMemberJoin from "./pages/user/nonMemberJoin";
import NaverLoginCheck from "./pages/NaverLoginCheck";
import KakaoTest from "./pages/KaKaoTest";
import SnsJoin from "./pages/SnsJoin";
import SnsLink from "./pages/SnsLink";
import ErrorPage from "./pages/ErrorPage";
import ProtectRoute from "./ProtectRoute";

function App() {
  return (
    <>
      <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/join" element={<Join />} />
          <Route path="/sns/join" element={<SnsJoin />} />
          <Route path="/find/password" element={<FindPassword />} />
          <Route path="/find/email" element={<FindEmail/>} />
          <Route path="/update/password" element={<UpdatePassword />} />
          <Route path="/user/my-page" element={<MyPage />} />
          <Route path="/estimate/request" element={<EstimateRequest />} />
          <Route path="/ask/list" element={<AskList />} />
          <Route path="/ask/email" element={<AskEmail />} />
          <Route path="/insurance/:id" element={<Insurance />} />
          <Route path="/mail/login/:inflowType" element={<MailLogin />} />
          <Route path="/test" element={<NaverLoginCheck />}/>
          <Route path="/user/auth/kakao" element={<KakaoTest />} />
          <Route path="/user/link" element={<SnsLink />} />

          <Route element={<ProtectRoute authId={[0]} />}>
              <Route path="/member/join" element={<NonMemberJoin />} />
          </Route>

          <Route element={<ProtectRoute authId={[1]} />}>
              <Route path="/estimate/:id" element={<Report />} />
          </Route>

          <Route element={<ProtectRoute authId={[2]} />}>
              <Route path="/business/estimate/status" element={<BusinessStatus />} />
              <Route path="/business/estimate/list" element={<BusinessList />} />
              <Route path="/business/estimate/:id" element={<BusinessList />} />
          </Route>

          <Route element={<ProtectRoute authId={[3]} />}>
              <Route path="/admin/main" element={<MainDashboard />} />
              <Route path="/admin/customer" element={<AdminCustomer />} />
              <Route path="/admin/:id/edit" element={<CustomerEdit />} />
              <Route path="/admin/user" element={<AdminUser />} />
              <Route path="/admin/bid/manage" element={<BidManage />} />
              <Route path="/admin/bid/manage/detail/:esCode" element={<BidManageDetail />} />
              <Route path="/admin/bid/statistics" element={<BidStatistics />} />
              <Route path="/admin/contract" element={<AdminContract />} />
              <Route path="/admin/contract/detail/:id" element={<ContractDetail />} />
              <Route path="/admin/estimate/:id" element={<Detail />} />
              <Route path="/admin/insurance/products" element={<InsuranceProducts />} />
              <Route path="/admin/insurance/detail/:id" element={<InsuranceDetail />} />
              <Route path="/admin/insurance/add" element={<InsuranceAdd />} />
              <Route path="/admin/insurance/edit" element={<InsuranceEdit />} />
              <Route path="/admin/bidding/list" element={<Bidding />} />
          </Route>

          <Route element={<ProtectRoute authId={[0, 1, 3]} />}>
              <Route path="/estimate/list" element={<EstimateList />} />
              <Route path="/estimate/list/detail/:esCode" element={<EstimateListDetail />} />
          </Route>

          <Route path="/err" element={<ErrorPage />} />
          <Route path="/*" element={<NotFound />} />
      </Routes>
    </>
  );
}


export default App;

import React, { useState, useEffect, useRef } from 'react';

const ModalComponent = ({isShow, title, className, width, onClose, contentComponent, footerComponent, footerAlign = 'center', setChecked}) => {
    const [contentHeight, setContentHeight] = useState(0);
    // const wrap = useRef<HTMLDivElement|null>(null);
    const header = useRef(null);
    const footer = useRef(null);

    useEffect(() => {
        if (header.current && footer.current) {
            const h = (window.innerHeight * 0.7) - (header.current?.clientHeight + footer.current?.clientHeight);
            setContentHeight(h);
        }
    }, [isShow]);

    return (
        <>
            <div className="modal-overlay" style={{display: isShow ? 'block' : 'none'}} />
            <div className={`modal-wrap ${className}`} style={{display: isShow ? 'block' : 'none', width: width ? width + 'px' : '800px'}}>
                <header className="modal-header" ref={header}>
                    <b className="modal-title">{title}</b>
                    <i className="icon icon-close" onClick={onClose}/>
                </header>
                <div className="modal-content" style={{maxHeight: contentHeight}}>
                    {contentComponent}
                </div>
                <footer className="modal-footer" style={{textAlign: footerAlign}} ref={footer}>
                    {footerComponent
                        ? footerComponent
                        : <button className="btn btn-gray" onClick={() => {
                            onClose();
                            setChecked(true);
                        }}>확인</button>
                    }
                </footer>
            </div>
        </>
    );
};

export default ModalComponent;